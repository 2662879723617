// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-table-view .status-indicator--active {
  color: green;
}
.users-table-view .status-indicator--inactive {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/ViewUsers/styles.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;AAFJ;AAIE;EACE,UAAA;AAFJ","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.users-table-view {\n  .status-indicator--active {\n    color: green;\n  }\n  .status-indicator--inactive {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
