import { ISupplierFormProps } from "./interfaces";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultFormValues } from "./constants";

import "bootstrap/dist/js/bootstrap.bundle";
import "./styles.scss";

const validationScheme = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name must be at least 3 characters long")
    .required("Name cannot be empty"),
  link: Yup.string()
    .transform((value, originalValue) => {
      if (
        value &&
        !value.startsWith("http://") &&
        !value.startsWith("https://")
      ) {
        return `https://${value}`;
      }
      return value;
    })
    .min(6, "Link must be at least 6 characters long")
    .required("Link cannot be empty")
    .url("Invalid URL format"),
  onlineSupplier: Yup.string(),
  city: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .min(3, "City must be at least 3 characters long")
        .required("City cannot be empty");
    } else return Yup.string();
  }),
  street: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .min(3, "Street must be at least 3 characters long")
        .required("Street cannot be empty");
    } else return Yup.string();
  }),
  state: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .min(2, "State must be at least 2 characters long")
        .required("State cannot be empty");
    } else return Yup.string();
  }),
  zipCode: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .min(5, "Zip Code must be at least 5 characters long")
        .max(6, "Zip Code must be max 6 characters long");
    } else return Yup.string();
  }),
  contactName: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .min(3, "Name must be at least 3 characters long")
        .required("Name cannot be empty");
    } else return Yup.string();
  }),
  contactEmail: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .required("Email cannot be empty")
        .matches(/.+@.+\..+/, "Invalid email format");
    } else return Yup.string();
  }),
  contactPhoneNumber: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .matches(
          /^[0-9-]*[0-9]+[0-9-]*$/,
          "Phone number must contain only numeric digits"
        )
        .test(
          "is-at-least-10-digits",
          "Phone number must be at least 10 characters long",
          (value) => {
            const numericValue = value?.replace(/-/g, "");
            return numericValue ? numericValue.length >= 10 : false;
          }
        )
        .required("Phone number cannot be empty");
    } else return Yup.string();
  }),
  contactExtensionCode: Yup.string().when("onlineSupplier", (val) => {
    if (val[0] === "No") {
      return Yup.string()
        .matches(/^[0-9]+$/, "Extension code must contain only numeric digits")
        .min(1, "Extension code must be at least 1 characters long")
        .max(3, "Extension code cannot be longer than 3 digits")
        .required("Extension code cannot be empty");
    } else return Yup.string();
  }),
});

const formOptions = {
  resolver: yupResolver(validationScheme),
  defaultValues: defaultFormValues,
};

const SuppliersForm = ({ submitCb, cancelCb }: ISupplierFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm(formOptions);

  const resetForm = () => {
    const { name, link } = getValues();
    reset({ ...defaultFormValues, name, link });
  };

  const onSubmit = ({
    name,
    link,
    onlineSupplier,
    city,
    street,
    state,
    zipCode,
    contactName,
    contactEmail,
    contactPhoneNumber,
    contactExtensionCode,
  }: any) => {
    if (Object.keys(errors).length === 0) {
      submitCb({
        name,
        link,
        onlineSupplier,
        city,
        street,
        state,
        zipCode,
        contactName,
        contactEmail,
        contactPhoneNumber,
        contactExtensionCode,
      });
    }
  };

  return (
    <div className="row">
      <h1 className="text-center font-weight-bold">Enter Supplier Details</h1>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope" noValidate>
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="p-5">
            <h4 className="mt-3">Supplier info</h4>
            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.name?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-name">Name</label>
              <input
                type="text"
                id="supplier-name"
                placeholder="Enter name"
                autoComplete="none"
                {...register("name")}
              />
              <span>{errors.name?.message}</span>
            </div>
            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.link?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-link">Link</label>
              <input
                type="text"
                id="supplier-link"
                placeholder="Enter link"
                autoComplete="none"
                {...register("link")}
              />
              <span>{errors.link?.message}</span>
            </div>

            <div className="mt-3">
              <h5>Online supplier?</h5>

              <div className="d-flex">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="Yes"
                    id="online-supplier-yes"
                    autoComplete="none"
                    {...register("onlineSupplier")}
                    onChange={resetForm}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="online-supplier-yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check radio-container">
                  <input
                    defaultChecked
                    autoComplete="none"
                    className="form-check-input"
                    type="radio"
                    value="No"
                    id="online-supplier-no"
                    {...register("onlineSupplier")}
                    onChange={resetForm}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="online-supplier-no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="p-5">
            <h4 className="mt-3">Address</h4>
            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.city?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-city">City</label>
              <input
                id="supplier-city"
                placeholder="Enter city"
                autoComplete="none"
                aria-describedby="city"
                {...register("city")}
              />
              <span>{errors.city?.message}</span>
            </div>

            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.state?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-state">State</label>
              <input
                id="supplier-state"
                placeholder="Enter state"
                autoComplete="none"
                aria-describedby="state"
                {...register("state")}
              />
              <span>{errors.state?.message}</span>
            </div>

            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.street?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-street">Street</label>
              <input
                id="supplier-street"
                placeholder="Enter street"
                autoComplete="none"
                aria-describedby="street"
                {...register("street")}
              />
              <span>{errors.street?.message}</span>
            </div>

            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.zipCode?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-zip-code">Zip Code</label>
              <input
                id="supplier-zip-code"
                placeholder="Enter zip"
                type="text"
                aria-describedby="zipCode"
                {...register("zipCode")}
              />
              <span>{errors.zipCode?.message}</span>
            </div>
          </div>
          <div className="p-5">
            <h4 className="mt-3">Contact Person</h4>
            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.contactName?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-contact-name">Contact Name</label>
              <input
                id="supplier-contact-name"
                placeholder="Enter contact name"
                autoComplete="none"
                aria-describedby="contactName"
                {...register("contactName")}
              />
              <span>{errors.contactName?.message}</span>
            </div>

            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.contactEmail?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-contact-email">Email</label>
              <input
                id="supplier-contact-email"
                placeholder="Enter contact email"
                autoComplete="none"
                aria-describedby="contactEmail"
                {...register("contactEmail")}
              />
              <span>{errors.contactEmail?.message}</span>
            </div>

            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.contactPhoneNumber?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-phone-number">Phone number</label>
              <input
                id="supplier-phone-number"
                placeholder="Enter contact phone number"
                autoComplete="none"
                aria-describedby="contactPhoneNumber"
                {...register("contactPhoneNumber")}
                maxLength={12}
              />
              <span>{errors.contactPhoneNumber?.message}</span>
            </div>

            <div
              className={`supplier-container d-flex flex-column form-group dark-input  ${
                errors.contactExtensionCode?.message && "form-error"
              }`}
            >
              <label htmlFor="supplier-extension-code">Extension Code</label>
              <input
                id="supplier-extension-code"
                placeholder="Enter contact extension code"
                type="text"
                aria-describedby="contactExtensionCode"
                {...register("contactExtensionCode")}
              />
              <span>{errors.contactExtensionCode?.message}</span>
            </div>
          </div>
        </div>
        <div className="form-group d-flex justify-content-center mb-5">
          <button type="submit" className="btn btn-success me-3">
            Register Supplier
          </button>
          <button
            type="submit"
            className="btn btn-danger"
            onClick={() => cancelCb()}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default SuppliersForm;
