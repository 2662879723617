import "./styles.scss";

import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { postSurvey } from "./api";
import { pageRoutes, localStorageNames, UserContext } from "utils";

import logo from "assets/images/logoBig.png";
import { ISurvey } from "./interfaces";
import { IContextUser } from "common/interfaces";

const SurveyStep2 = () => {
  const { mutate: postSurveryMutation, isLoading } = useMutation(postSurvey, {
    onSuccess: (data) => {
      localStorage.removeItem(localStorageNames.surveyStep1);
      if (data.user?.survey?.completed) {
        navigate(pageRoutes.PROFILE);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error saving your survey answers."
      );
    },
  });
  const navigate = useNavigate();
  const user: IContextUser = useContext(UserContext)!;

  useEffect(() => {
    const surveyStep1 = localStorage.getItem(localStorageNames.surveyStep1);
    if (!surveyStep1) {
      navigate(pageRoutes.SURVEY_STEP1);
    }
  });

  const onSurveyButtonClick = (selection: string) => {
    let surveyStep1: any = localStorage.getItem(localStorageNames.surveyStep1);
    surveyStep1 = JSON.parse(surveyStep1) ?? [];

    const surveyBody: ISurvey = {
      step1: surveyStep1,
      step2: selection,
      email: user.email,
    };
    postSurveryMutation(surveyBody);
  };

  return (
    <div className="container-fluid survey-step2">
      <div className="row survey-step2-header py-5">
        <div className="col-12 text-center mb-3">
          <img src={logo} alt="asinmice logo" />
        </div>
        <div className="col-12 text-center">
          <h1>One Last Thing...</h1>
          <h2>
            Have you thought about the potential of an Amazon business? How
            would you <br />
            like to learn the secrets to a successful 7 figure business ?
          </h2>
        </div>
      </div>
      <div className="row survey-step2-body text-center">
        <div className="col-12 survey-step2-body-button-container my-5 pt-5">
          <button
            className="btn"
            onClick={() => onSurveyButtonClick("seriousAboutMySuccess")}
          >
            I am VERY serious about my success on Amazon for myself and my
            family!
          </button>
        </div>
        {isLoading && (
          <div
            className="spinner-border survey-step2-body-loading"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <div className="col-12 survey-step2-body-button-container mb-5">
          <button
            className="btn"
            onClick={() => onSurveyButtonClick("curiouseAboutAmazonBusiness")}
          >
            I am not serious yet, I am curious
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyStep2;
