import { useMutation } from "react-query";
import "./style.scss";

import { Link } from "react-router-dom";
import { postShipmentPlanFilesRequest } from "./api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import FullScreenLoading from "components/FullScreenLoading";

const ShipmentPlanThankYouPage = () => {
  useEffect(() => {
    let userObj = localStorage.getItem("asinmice-obj");
    userObj = JSON.parse(userObj!!);
    // @ts-ignore
    const email = userObj.email;
    const paymentIntentId = localStorage.getItem("asinmice-payment-intent-id");

    if (paymentIntentId) {
      postShipmentPlanPayment({ paymentIntentId, email });
    } else {
      toast.error(
        "Please contact us at support@unielogics.com with session id mentioned in URL, as it seems we couldn't mark the payment as paid. "
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: postShipmentPlanPayment, isLoading: isLoadingPayment } =
    useMutation(postShipmentPlanFilesRequest, {
      onSuccess: () => {
        toast.success("Successfully paid your invoice.");
        localStorage.removeItem("asinmice-payment-intent-id");
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error trying to redirect to payment. Please try again and if the problem persists please contact us. "
        );
      },
    });

  return (
    <>
      {isLoadingPayment && <FullScreenLoading />}
      <div className="thankyou-page-wrapper">
        <div className="section">
          <div className="container">
            <h1 className="text-decoration-underline mb-5">
              Congratulations 🍻 You're all sets!
            </h1>
            <h3>
              Your Shipment plan has been paid and the warehouse is waiting for
              you to send the products!
            </h3>
            <br />
            <div className="is-6 is-offset-desktop-3 mb-3">
              <iframe
                src="https://giphy.com/embed/tMwHvowh7JSM3vZUUR"
                width="480"
                height="270"
                title="giphy video"
                className="giphy-embed"
                allowFullScreen
              ></iframe>
            </div>
            <>
              <p>
                Our team is super excited to make you part of our community.
              </p>
              <a
                href="https://www.facebook.com/asinmice?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                👉🏽 Join Our FB Page For Support & Updates
              </a>
            </>
            <p>
              <Link to="/profitable-products">👉🏽 Back to Products</Link>
              <br />
              ..and dont forget to send your products to the Warehouse!
              <br />
            </p>
            <br />
            <p>
              Cheers 🎊 , <br />
              AsinMice Team
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentPlanThankYouPage;
