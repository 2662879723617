import "./styles.scss";

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { localStorageNames, pageRoutes, UserContext } from "utils";

import logo from "assets/images/logoBig.png";
import boxWhite from "assets/images/boxWhite.png";
import boxBlack from "assets/images/boxBlack.png";
import supportWhite from "assets/images/supportWhite.png";
import supportBlack from "assets/images/supportBlack.png";
import listWhite from "assets/images/listWhite.png";
import listBlack from "assets/images/listBlack.png";
import boxesWhite from "assets/images/boxesWhite.png";
import boxesBlack from "assets/images/boxesBlack.png";
import atomWhite from "assets/images/atomWhite.png";
import atomBlack from "assets/images/atomBlack.png";
import checkedMark from "assets/images/icons/checkedCheckbox.png";
import { IContextUser } from "common/interfaces";

const SurveyStep1 = () => {
  const [images, setImages] = useState({
    box: boxBlack,
    support: supportBlack,
    list: listBlack,
    boxes: boxesBlack,
    atom: atomBlack,
  });
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [itemsWithCheckmark, setItemsWithCheckmark] = useState<any>({
    box: false,
    support: false,
    list: false,
    boxes: false,
    atom: false,
  });
  let navigate = useNavigate();
  const user: IContextUser = useContext(UserContext)!;

  useEffect(() => {
    if (user?.survey?.completed) {
      navigate(pageRoutes.PROFILE);
    }
  });

  useEffect(() => {
    if (selectedItems.length === 2) {
      localStorage.setItem(
        localStorageNames.surveyStep1,
        JSON.stringify(selectedItems)
      );
      navigate("/survey-step2");
    }
    // eslint-disable-next-line
  }, [selectedItems]);

  const onSurveyItemClick = (item: string, checkMarkItem: string) => {
    const indexOfItem = selectedItems.indexOf(item);
    //check if -1, if yes, it means that the items doesn't exist in the array and add it
    if (selectedItems.length < 2) {
      if (indexOfItem === -1) {
        setSelectedItems([...selectedItems, item]);
        setItemsWithCheckmark({
          ...itemsWithCheckmark,
          [checkMarkItem]: true,
        });
        //if item exists, means user already clicked once on it and we need to remove it
      } else {
        selectedItems.splice(indexOfItem, 1);
        setSelectedItems([...selectedItems]);
        setItemsWithCheckmark({
          ...itemsWithCheckmark,
          [checkMarkItem]: false,
        });
      }
    } else {
      toast.error("You can only select 2 priorities for your business!");
    }
  };

  return (
    <div className="container-fluid survey-step1">
      <div className="row survey-step1-header py-5">
        <div className="col-12 text-center mb-3">
          <img src={logo} alt="asinmice logo" />
        </div>
        <div className="col-12 text-center">
          <h1>Let's Customize Your Experience </h1>
          <h2>Select The Top 2 Priorities For Your Business Right Now</h2>
        </div>
      </div>
      <div className="row survey-step1-body py-5">
        <div className="col-md-1"></div>
        <div
          className="col-md-2 text-center survey-step1-body-item"
          onMouseEnter={() =>
            setImages({
              box: boxWhite,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onMouseLeave={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onClick={() => onSurveyItemClick("trackInventoryAccount", "box")}
        >
          <img
            className="mb-3"
            src={images.box}
            alt="track inventory account"
          />
          {itemsWithCheckmark.box && (
            <img
              src={checkedMark}
              alt="checked item"
              className="checked-item"
            />
          )}
          <p>
            Track Inventory &amp;
            <br /> Accounting{" "}
          </p>
        </div>
        <div
          className="col-md-2 text-center survey-step1-body-item"
          onMouseEnter={() =>
            setImages({
              box: boxBlack,
              support: supportWhite,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onMouseLeave={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onClick={() => onSurveyItemClick("creatingATeamToScale", "support")}
        >
          <img
            className="mb-3"
            src={images.support}
            alt="creating a team to scale"
          />
          {itemsWithCheckmark.support && (
            <img
              src={checkedMark}
              alt="checked item"
              className="checked-item"
            />
          )}
          <p>
            Creating a Team
            <br /> To Scale{" "}
          </p>
        </div>
        <div
          className="col-md-2 text-center survey-step1-body-item"
          onMouseEnter={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listWhite,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onMouseLeave={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onClick={() => onSurveyItemClick("findingMoreSupplies", "list")}
        >
          <img className="mb-3" src={images.list} alt="finding more supplies" />
          {itemsWithCheckmark.list && (
            <img
              src={checkedMark}
              alt="checked item"
              className="checked-item"
            />
          )}
          <p>
            Finding More
            <br /> Suppliers
          </p>
        </div>
        <div
          className="col-md-2 text-center survey-step1-body-item"
          onMouseEnter={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesWhite,
              atom: atomBlack,
            })
          }
          onMouseLeave={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onClick={() => onSurveyItemClick("improvingProductResearch", "boxes")}
        >
          <img
            className="mb-3"
            src={images.boxes}
            alt="improving product research"
          />
          {itemsWithCheckmark.boxes && (
            <img
              src={checkedMark}
              alt="checked item"
              className="checked-item"
            />
          )}
          <p>
            Improving Product
            <br /> Research
          </p>
        </div>
        <div
          className="col-md-2 text-center survey-step1-body-item"
          onMouseEnter={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomWhite,
            })
          }
          onMouseLeave={() =>
            setImages({
              box: boxBlack,
              support: supportBlack,
              list: listBlack,
              boxes: boxesBlack,
              atom: atomBlack,
            })
          }
          onClick={() =>
            onSurveyItemClick("implementWharehouseLogistic", "atom")
          }
        >
          <img
            className="mb-3"
            src={images.atom}
            alt="implement wharehouse logistic"
          />
          {itemsWithCheckmark.atom && (
            <img
              src={checkedMark}
              alt="checked item"
              className="checked-item"
            />
          )}
          <p>
            Implement Warehouse
            <br /> Logistic
          </p>
        </div>
      </div>
    </div>
  );
};

export default SurveyStep1;
