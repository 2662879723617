// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid .wo-signup-container {
  max-width: 450px;
  margin-bottom: 10px;
}
.container-fluid .sizing-container {
  max-width: 368px;
}
.container-fluid .form-error input {
  border: 0.5px solid #f27761;
}
.container-fluid .form-error label {
  color: #f27761;
}
.container-fluid .form-error span {
  color: #f27761;
  max-width: 300px;
}
.container-fluid .wob-cost-per-box-sizing input {
  width: 50px;
  margin-right: 5px;
}
.container-fluid .radio-container {
  margin-left: 20px;
}
.container-fluid .wo-submit-buttons button {
  width: 150px;
}
.container-fluid .wo-submit-buttons :first-child {
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/WarehouseOwnersForm/style.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAGI;EACI,gBAAA;EACA,mBAAA;AAFR;AAKI;EACE,gBAAA;AAHN;AAOQ;EACE,2BAAA;AALV;AAOQ;EACE,cCVC;ADKX;AAOQ;EACE,cCbC;EDcD,gBAAA;AALV;AAUQ;EACI,WAAA;EACA,iBAAA;AARZ;AAYI;EACI,iBAAA;AAVR;AAcM;EACE,YAAA;AAZR;AAeM;EACE,kBAAA;AAbR","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.container-fluid{\n    .wo-signup-container{\n        max-width: 450px;\n        margin-bottom:10px;\n    }\n    \n    .sizing-container{\n      max-width: 368px;\n    }\n\n    .form-error {\n        input {\n          border: 0.5px solid $errorRed;\n        }\n        label {\n          color: $errorRed;\n        }\n        span{\n          color: $errorRed;\n          max-width: 300px;\n        }\n    }\n\n    .wob-cost-per-box-sizing{\n        input{\n            width:50px;\n            margin-right: 5px;\n        }\n    }\n\n    .radio-container{\n        margin-left:20px;\n    }\n\n    .wo-submit-buttons{\n      button{\n        width: 150px;\n\n      }\n      :first-child{\n        margin-right:20px;\n      }\n    }\n}","$budhaGold: #8b76b2;\n$sorbusOrange: #8481be;\n$lighterSorbusOrange: #8b76b2;\n$codGray: #191919;\n$mineShaftGray: #252525;\n$selectiveYellow: #8481be;\n$inchWormGreen: \"#7fec12\";\n$errorRed: #f27761;\n$codBlack: #101010;\n$codBlackV2: #171717;\n$tundoraGray: #484848;\n$silverGray: #a6a6a6;\n$pictonBlue: #a44c92;\n$scoprionGray: #5b5b5b;\n$limeade: #3c9c00;\n$sanFelixGreen: #1c6c09;\n$phoenixLinkBlue: #85a9ff;\n$separatorGray: #313749;\n$vulcanBlue: #0f111a;\n$mirageBlue: #151824;\n$textGray: #999999;\n$victoriaBlue: #465298;\n$flamingoRed: #f33f45;\n$thunderbirdRed: #c51a16;\n$green: #02752c;\n$lightGreen: #d5fae0;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
