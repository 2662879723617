import React from 'react';
import { useEffect } from 'react';


const AmazonVendorDetails = ({ vendor }: any) => {
    
    return (
        <div className='p-2'>
            <h2>Vendor Information</h2>
            <p>
                <strong>Vendor Name:</strong> {vendor?.supplierName}
            </p>
            {/* <p>
                <strong>Supplier Link:</strong>{" "}
                <a href={vendor?.supplierLink} target="_blank" rel="noopener noreferrer">
                    {vendor?.supplierLink || 'N/A'}
                </a>
            </p> */}

            <p>
                <strong>Street:</strong> {vendor?.supplierAddress?.street || 'N/A'}
            </p>
            <p>
                <strong>City:</strong> {vendor?.supplierAddress?.city || 'N/A'}
            </p>
            <p>
                <strong>State:</strong> {vendor?.supplierAddress?.state || 'N/A'}
            </p>
            <p>
                <strong>Zip Code:</strong> {vendor?.supplierAddress?.zipCode || 'N/A'}
            </p>

        </div>
    );
};

export default AmazonVendorDetails;