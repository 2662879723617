import axios from "axios";
import {
  IGetProfitableProductDetails,
  IGetProfitableProductDetailsResponse,
  IUpdateNoteProductDetailsResponse,
  IUpdateNoteProductDetailsRequest
} from "../interfaces";
import { apiRoutes } from "utils";

export const getProfitableProductDetails = async ({
  email,
  folderId,
  asin,
}: IGetProfitableProductDetails) => {
  const { data } = await axios.get<IGetProfitableProductDetailsResponse>(
    `${apiRoutes.PROFITABLE_PRODUCT_DETAILS}?email=${email}&folderId=${folderId}&asin=${asin}`
  );
  return data;
};

export const updateNoteProductDetailsRequest = async ({
  email,
  asin,
  title,
  imageUrl,
  price,
  folderId,
  note,
}: IUpdateNoteProductDetailsRequest) => {
  const requestData = {
    supplierUrl: "",
    amazonFees: "",
    pickPack: "",
    totalFees: "",
    breakEven: "",
    costPerItem: "",
    miscExpenses: "",
    totalCostPerSale: "",
    netProfit: "",
    buyboxIsFBA: "",
    units: "",
    totalProfit: "",
    netSalesMargin: "",
    netROI: "",
    offerCountFBA: "",
    offerCountFBM: "",
    qtyPerSet: "",
    productGroup: "",
    brand: "",
    ian: "",
    lastPriceChange: "",
    weight: "",
    WxHxL: "",
    chartsURL: "",
    buyboxStatistics: [
      {
        seller: "",
        xPercentageWon: "",
        lastWon: "",
        stock: "",
        avgPrice: "",
        isFBA: "",
      },
    ],
    variations: [],
  };
  const { data } = await axios.post<IUpdateNoteProductDetailsResponse>(
    apiRoutes.ADD_PROFITABLE_PRODUCT_DETAILS,
    { email, asin, title, imageUrl, price, folderId, note, ...requestData }
  );

  return data;
};