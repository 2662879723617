// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbar {
  position: relative;
}
.searchbar-input {
  border-radius: 15px;
  background: #fff;
  color: #333;
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 18px;
}
.searchbar-input::-webkit-input-placeholder {
  color: #b9b9b9;
}
.searchbar-input::-moz-placeholder {
  color: #b9b9b9;
}
.searchbar-input:-ms-input-placeholder {
  color: #b9b9b9;
}
.searchbar-input:-moz-placeholder {
  color: #b9b9b9;
}
.searchbar-icon {
  position: absolute;
  right: 20px;
  top: 30px;
  color: #b9b9b9;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,cAAA;AAEJ","sourcesContent":[".searchbar {\n  position: relative;\n  &-input {\n    border-radius: 15px;\n    background: #fff;\n    color: #333;\n    display: block;\n    width: 100%;\n    padding: 20px;\n    font-size: 18px;\n  }\n  &-input::-webkit-input-placeholder {\n    color: #b9b9b9;\n  }\n  &-input::-moz-placeholder {\n    color: #b9b9b9;\n  }\n  &-input:-ms-input-placeholder {\n    color: #b9b9b9;\n  }\n  &-input:-moz-placeholder {\n    color: #b9b9b9;\n  }\n  &-icon {\n    position: absolute;\n    right: 20px;\n    top: 30px;\n    color: #b9b9b9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
