import "./style.scss";

import { Link } from "react-router-dom";

const ThankYouPage = () => {
  return (
    <div className="thankyou-page-wrapper">
      <div className="section">
        <div className="container">
          <h1>Congratulations 🍻 You are all in...</h1>
          <h2>Take 2 minutes to watch this intro video!</h2>
          <br />
          <div className="is-6 is-offset-desktop-3 video-wrapper">
            <iframe
              title="test"
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/UA4giAM6deg"
            ></iframe>
          </div>
          <>
            <p>Our team is super excited to make you part of our community.</p>
            <a
              href="https://www.facebook.com/asinmice?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              👉🏽 Join Our FB Page For Support & Updates
            </a>
          </>
          <p>
            <Link to="/login">Access you account</Link>
            <br />
            ..and dont forget to download ⬇️ you chrome extension
            <br />
            <a
              href="https://chromewebstore.google.com/detail/unielogics/bagcekacfgdabdkhamefiohigjiepjpb"
              target="_blank"
              rel="noreferrer"
            >
              Get extension
            </a>
          </p>
          <br />
          <p>
            Cheers 🎊 , <br />
            AsinMice Team
          </p>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
