import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  IProfilePasswordSettings,
  IPasswordSettingsFormType,
} from "./interfaces";

import "./styles.scss";

const ProfilePasswordSettings = ({
  email,
  onPasswordChangeSubmitParent,
}: IProfilePasswordSettings) => {
  const validationScheme = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Current Password field cannot be empty")
      .min(3, "Current Password must be at least 3 characters long")
      .notOneOf(
        [Yup.ref("confirmPassword")],
        "New Password cannot be equal to existing password"
      ),
    password: Yup.string()
      .required("New Password field cannot be empty")
      .min(3, "New Password must be at least 3 characters long"),
    confirmPassword: Yup.string()
      .required("Confirm Password field cannot be empty")
      .oneOf(
        [Yup.ref("password")],
        "New Password and Confirm Password do not match"
      )
      .notOneOf(
        [Yup.ref("currentPassword")],
        "New Password cannot be equal to existing password"
      ),
  });
  const formOptions = {
    resolver: yupResolver(validationScheme),
    defaultValues: useMemo(() => {
      return {
        email,
        password: "",
        confirmPassword: "",
        currentPassword: "",
      };
    }, [email]),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPasswordSettingsFormType>(formOptions);

  useEffect(() => {
    (Object.keys(errors) as (keyof typeof errors)[]).forEach((key, index) => {
      toast.error(errors[key]?.message);
    });
  }, [errors]);

  const onUpdateSubmit = ({ password }: IPasswordSettingsFormType) => {
    //check if no errors
    if (Object.keys(errors).length === 0) {
      onPasswordChangeSubmitParent(password);
    }
  };

  return (
    <div className="col-xl-8 py-4 profile-password-card-right-side">
      <div className="mb-4">
        <h2 className="profile-password-card-right-side-title mb-5">
          Password Settings
          <i className="fa fa-lock ms-3"></i>
        </h2>
        <form onSubmit={handleSubmit(onUpdateSubmit)}>
          <div className="row gx-3 mb-md-5 mb-sm-0">
            <div className="col-md-12 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputEmail">
                Email/ User Name
              </label>
              <input
                className={`form-control disabled ${
                  errors.email && "input-error"
                }`}
                id="inputEmail"
                type="text"
                placeholder=""
                {...register("email", {
                  required: "Email field cannot be empty",
                })}
                disabled
              />
            </div>
          </div>
          <div className="row gx-3 mb-md-3 mb-sm-0">
            <div className="col-md-12 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="inputPhone">
                Enter your old password
              </label>
              <input
                className={`form-control ${
                  errors.currentPassword && "input-error"
                }`}
                id="currentPassword"
                type="password"
                placeholder="------"
                {...register("currentPassword")}
              />
            </div>
          </div>
          <div className="row gx-3 mb-md-3 mb-sm-0">
            <div className="col-md-12 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="password">
                New Password
              </label>
              <input
                className={`form-control ${errors.password && "input-error"}`}
                id="password"
                type="password"
                placeholder="------"
                {...register("password")}
              />
            </div>
          </div>
          <div className="row gx-3 mb-5">
            <div className="col-md-12 mb-md-0 mb-sm-3 input-wrapper">
              <label className="small mb-1" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <input
                className={`form-control ${
                  errors.confirmPassword && "input-error"
                }`}
                id="confirmPassword"
                type="password"
                placeholder="------"
                {...register("confirmPassword")}
              />
            </div>
          </div>
          <button className="btn btn-light-blue mt-md-5 mt-sm-0" type="submit">
            Update
          </button>
          <button
            className="btn btn-dark-blue mt-md-5 mt-sm-0 btn-danger"
            type="button"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};
export default ProfilePasswordSettings;
