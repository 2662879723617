import { useContext, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";

import {
  Footer,
  FullScreenLoading,
  Modal,
  PageTitle,
  SidebarMenu,
  SuppliersForm,
} from "components";

import "./styles.scss";
import { QUERY_KEYS, UserContext } from "utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteSuppliers,
  getSuppliersRequest,
  postRegisterSupplier,
} from "./api";
import { suppliersTableHeaders } from "utils";
import { ISupplier } from "./interfaces";
import { ISupplierForm } from "components/SuppliersForm/interfaces";
import { toast } from "react-toastify";
import { IContextUser } from "common/interfaces";

const Suppliers = () => {
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);
  const [shouldDisplayLocationModal, setShouldDisplayLocationModal] =
    useState(false);
  const [supplierIdToDelete, setSupplierIdToDelete] = useState("");
  const [supplierCoordinates, setSupplierCoordinates] = useState({
    lat: 0,
    long: 0,
    name: "",
  });

  const queryClient = useQueryClient();
  const { data, isLoading: isLoadingGetSuppliers } = useQuery(
    QUERY_KEYS.GET_SUPPLIERS,
    () => getSuppliersRequest(email)
  );

  const { mutate: postSupplierMutate, isLoading: isLoadingPostSupplier } =
    useMutation(postRegisterSupplier, {
      onSuccess: (successData) => {
        toast.success(successData.message);
        setShowSupplierForm(false);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error saving your supplier."
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_SUPPLIERS);
      },
    });

  const { mutate: deleteSuppliersMutate, isLoading: isLoadingDeleteSuppliers } =
    useMutation(deleteSuppliers, {
      onSuccess: (successData) => {
        toast.success(successData.message);
        setSuppliers(successData?.response?.suppliers);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error deleting your supplier."
        );
      },
    });

  useEffect(() => {
    if (data?.response?.suppliers) {
      setSuppliers(data?.response?.suppliers);
    }
  }, [data]);

  const onSubmitSupplierFormCb = ({
    name,
    link,
    onlineSupplier = "No",
    city,
    street,
    state,
    zipCode,
    contactName,
    contactEmail,
    contactPhoneNumber,
    contactExtensionCode,
  }: ISupplierForm) => {
    postSupplierMutate({
      email,
      name,
      onlineSupplier,
      link,
      city,
      street,
      state,
      zipCode,
      contactName,
      contactEmail,
      contactPhoneNumber,
      contactExtensionCode,
    });
  };

  const onModalConfirmDeleteFolderClick = () => {
    setShowDeleteSupplierModal(false);
    deleteSuppliersMutate({ email, _id: supplierIdToDelete });
  };

  return (
    <>
      <div className="content">
        <div className="container-fluid suppliers">
          <div className="sidebar">
            <SidebarMenu active="suppliers" sidebarOnly={true} />
          </div>
          {showDeleteSupplierModal && (
            <Modal
              okButtonText="Delete"
              modalTitle="Are you sure you want to delete this supplier?"
              modalTextContent="Please be advised that selecting 'Delete' will result in the permanent removal of the supplier associated with this user. As a consequence, all products linked to this supplier will remain unassociated with any supplier."
              okModalClick={onModalConfirmDeleteFolderClick}
              cancelModalClick={() => setShowDeleteSupplierModal(false)}
            />
          )}
          {shouldDisplayLocationModal && (
            <Modal
              modalDialogStyle={{ maxWidth: "600px" }}
              modalTitle={supplierCoordinates.name}
              cancelModalClick={() => setShouldDisplayLocationModal(false)}
              children={
                <div style={{ height: "500px", width: "100%" }}>
                  <MapContainer
                    style={{ height: "100%", width: "100%" }}
                    center={[supplierCoordinates.lat, supplierCoordinates.long]}
                    zoom={13}
                    scrollWheelZoom
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      position={[
                        supplierCoordinates.lat,
                        supplierCoordinates.long,
                      ]}
                      icon={
                        new Icon({
                          iconUrl: markerIconPng,
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                        })
                      }
                    />
                  </MapContainer>
                </div>
              }
              cancelButtonText="Close"
            />
          )}
          {(isLoadingGetSuppliers ||
            isLoadingPostSupplier ||
            isLoadingDeleteSuppliers) && <FullScreenLoading />}
          {suppliers.length !== 0 &&
            !isLoadingGetSuppliers &&
            !isLoadingPostSupplier &&
            !isLoadingDeleteSuppliers && (
              <button
                type="submit"
                className="btn btn-primary mb-3"
                onClick={() => setShowSupplierForm(!showSupplierForm)}
              >
                Add Supplier
              </button>
            )}
          {showSupplierForm && (
            <SuppliersForm
              submitCb={(options) => onSubmitSupplierFormCb(options)}
              cancelCb={() => setShowSupplierForm(false)}
            />
          )}
          {suppliers.length !== 0 &&
            !isLoadingGetSuppliers &&
            !isLoadingPostSupplier &&
            !isLoadingDeleteSuppliers &&
            !showSupplierForm && (
              <div className="row">
                <div className="col-sm">
                  <div className="table-responsive max-h-78vh">
                    <table className="table table-sm">
                      <thead className="sticky-header">
                        <tr>
                          {suppliersTableHeaders.map((header) => (
                            <th className="text-center" key={header.name}>
                              {header.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {suppliers.map((supplier: ISupplier, index) => (
                          <tr key={supplier._id}>
                            <td
                              className="align-middle text-center white-space-nowrap"
                              style={{ width: "1%" }}
                            >
                              {index + 1}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {supplier.supplierName}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              <a
                                href={supplier.supplierLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {supplier.supplierLink}
                              </a>
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              <span className="light-gray">Street: </span>
                              {supplier.supplierAddress.street}
                              <br />
                              <span className="light-gray">City: </span>
                              {supplier.supplierAddress.city}
                              <br />
                              <span className="light-gray">State: </span>
                              {supplier.supplierAddress.state} <br />
                              <span className="light-gray">Zip Code: </span>
                              {supplier.supplierAddress.zipCode}
                              <br />
                              <span className="light-gray">Lat: </span>
                              {supplier.supplierAddress.lat}
                              <br />
                              <span className="light-gray">Long: </span>
                              {supplier.supplierAddress.long}
                              <br />
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              <span className="light-gray">Name: </span>
                              {supplier.contactPerson.name}
                              <br />
                              <span className="light-gray">
                                Online Supplier:{" "}
                              </span>
                              {supplier.onlineSupplier[0].toUpperCase() +
                                supplier.onlineSupplier.slice(1)}
                              <br />
                              <span className="light-gray">Email: </span>
                              {supplier.contactPerson.email}
                              <br />
                              <span className="light-gray">Phone Number: </span>
                              {supplier.contactPerson.phoneNumber}
                              <br />
                              <span className="light-gray">
                                Extension Code:{" "}
                              </span>
                              {supplier.contactPerson.extensionCode}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setSupplierCoordinates({
                                    lat: supplier.supplierAddress.lat,
                                    long: supplier.supplierAddress.long,
                                    name: supplier.supplierName,
                                  });
                                  setShouldDisplayLocationModal(true);
                                }}
                              >
                                View location
                              </button>
                            </td>
                            <td className="align-middle text-center">
                              <span className="icon-wrapper">
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                  onClick={() => {
                                    setSupplierIdToDelete(supplier._id);
                                    setShowDeleteSupplierModal(true);
                                  }}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          {suppliers.length === 0 &&
            !isLoadingGetSuppliers &&
            !showSupplierForm && (
              <div className="no-items text-center">
                <h3>No Suppliers!</h3>
                <p>You don't have any Suppliers added yet.</p>
                <p>Start adding suppliers and link them to your products.</p>
                <button
                  type="submit"
                  className="btn btn-primary mb-3"
                  onClick={() => setShowSupplierForm(!showSupplierForm)}
                >
                  Add Supplier
                </button>
                <br />
                <span className="no-items-icon-wrapper">
                  <i className="fas fa-archive"></i>
                  {/* <img src={emptyFolderPng} alt="" /> */}
                </span>
              </div>
            )}
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Suppliers;
