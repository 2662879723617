import {
  IShipmentPlan,
  IShipmentPlanProduct,
} from "pages/shipmentPlans/interfaces";
import { ISupplier } from "pages/suppliers/interfaces";

export interface IGetShipmentPlanResponse {
  status: string;
  message: string;
  response: IShipmentPlan[];
}

export interface IDeleteShipmentPlanProduct {
  email: string;
  shipmentPlanId: string;
  productId: string;
}

export interface IDeleteShipmentPlanProductResponse {
  response: IShipmentPlan[];
  status: string;
}

export interface IAdditionalShipmentInputs {
  [key: string]: string | ISupplier | any;
}

export interface IUpdateShipmentPlanProduct {
  email: string;
  shipmentPlanId: string;
  orderNo?: string;
  receiptNo?: string;
  orderDate?: string;
  shipmentTitle?: string;
  products?: IShipmentPlanProduct[];
  warehouseOwner?: {
    wOwnerEmail: string;
    _id: string;
  };
}

export interface IPayShipmentPlan {
  amount: number,
  customerId: string,
  shipmentPlanId: string,
  email: string
}

export type IPostShipmentPlanFilesResponse = IGetShipmentPlanResponse;

export type IUpdateShipmentPlanResponse = IGetShipmentPlanResponse;

export type IDeleteShipmentPlanFileResponse = IGetShipmentPlanResponse;

export interface IShipmentPlanFiles {
  filename: string;
  _id: string;
}

export enum FileTypes {
  FBA = "fbaLabels",
  OTHER = "otherFiles",
}
