import Cookies from "js-cookie";
import { ILoginResponse } from "pages/login/interfaces";
import { cookiesNames, localStorageNames, pageRoutes } from "utils";

const onLoginSuccess = (user: ILoginResponse) => {
  Cookies.set(cookiesNames.token, user.token, { expires: 1 });
  if (!user.survey) {
    user = {
      ...user,
      survey: {
        step1: [],
        step2: "",
        completed: false,
      },
    };
  }
  localStorage.setItem(localStorageNames.userObj, JSON.stringify(user));
  setTimeout(() => {
    if (user.survey?.completed) {
      window.location.href = pageRoutes.PROFITABLE_PRODUCTS;
    } else {
      window.location.href = pageRoutes.SURVEY_STEP1;
    }
  }, 500);
};

export { onLoginSuccess };
