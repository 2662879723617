import { IPageTitle } from "./interfaces";

import "./styles.scss";

const PageTitle = ({ pageTitle }: IPageTitle) => (
  <div className="row page-title">
    <div className="col-sm mb-3">
      <h3>{pageTitle}</h3>
    </div>
  </div>
);
export default PageTitle;
