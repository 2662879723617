import { iOrder } from "lib/infoplus/interfaces/order";

interface RealShipmentObj {
    email: string;
    orderNo: string;
    receiptNo: string;
    orderDate: string;
    shipmentTitle: string;
    shipmentPlanId: string;
    products: Product[];
    shipmentPlan: ShipmentPlan[];
}

interface Product {
    fnsku: string;
    unitsPerBox: string;
    boxWidth: string;
    boxHeight: string;
    boxLength: string;
    weightPerBox: string;
    asin: string;
    title: string;
    amazonPrice: string;
    imageUrl: string;
    _id: string;
}

interface ShipmentPlan {
    warehouseOwner: {
        lobId: string;
        warehouseId: string;
    };
}

export function mapResponseToOrder(response: RealShipmentObj): iOrder {
    return {
        orderNo: parseInt(response.orderNo),
        customerOrderNo: response.receiptNo || "No recipt id",
        lobId: parseInt(response.shipmentPlan[0].warehouseOwner.lobId), // required
        warehouseId: parseInt(response.shipmentPlan[0].warehouseOwner.warehouseId), // required
        orderDate: response.orderDate, // required
        customerNo: response.shipmentPlanId,
        useOrderNoRoot: parseInt(response.receiptNo) || 404, // Example value
        mediaCode: 'defaultMediaCode', // Replace with actual value or logic to derive it
        legacyRestrictionType: 'defaultRestrictionType', // Replace with actual value or logic to derive it
        lineItems: response.products.map(product => ({
            id: Number(product._id),
            orderNo: parseInt(response.orderNo),
            lobId: parseInt(response.shipmentPlan[0].warehouseOwner.lobId), // required
            sku: product.fnsku, // required
            orderedQty: parseInt(product.unitsPerBox), // required
            // Add other fields as necessary, using default values or derived values
        })),
        // Add other fields from iOrder as necessary, using default values or derived values
    };
}

export const exampleAsnData = {
    "lobId": "22107",
    "poNo": "19243b57-7f56-4f01-b90b-ee16113cc89c",
    "vendorId": "1",
    "warehouseId": "3105",
    "orderDate": "2024-10-23T17:32:45.591Z",
    "type": "Normal",
    "lineItems": [
        {
            "lobId": "22107",
            "warehouseId": "3105",
            "sku": "464331064761",
            "orderQuantity": "2",
            "requestedDeliveryDate": "2024-10-23T17:32:45.591Z",
            "unitCode": "EA",
            "wrapCode": "STD",
            "unitsPerWrap": 1,
            "chargeFreight": "N",
            "maxOvers": 0,
            "maxUnders": 0,
            "distDate": "2024-10-23T17:32:45.591Z",
            "vendorId": "1"
        }
    ]
}
export const exampleOrderData = {
    "lobId": "22107",
    "warehouseId": "3105",
    "orderDate": "2024-10-21T18:46:55.003Z",
    "mediaCode": "Written",
    "legacyRestrictionType": "H/O All",
    "customerNo": "CUST000019",
    "customerOrderNo": "4431342",
    "lineItems": [
        {
            "id": 0,
            "lobId": "22107",
            "sku": 464331064761,
            "orderedQty": 2,
            "poNoId": 0,
            "upc": "",
            "vendorSKU": "",
            "customFields": {
                "FnSku": "fdsafds"
            }
        }
    ]
}
// const successfulASNReqSample = {
//     "id": 4,
//     "createDate": "2024-10-23T18:56:33.742Z",
//     "modifyDate": "2024-10-23T18:56:33.742Z",
//     "lobId": 22107,
//     "poNo": "19243B57-7F56-4F01-B90B-EE16113CC89C",
//     "vendorId": 1,
//     "warehouseId": 3105,
//     "orderDate": "2024-10-23T00:00:00.000Z",
//     "type": "Normal",
//     "billingName": null,
//     "billingAttention": null,
//     "billingStreet1": null,
//     "billingStreet2": null,
//     "billingCity": null,
//     "billingState": null,
//     "billingZipCode": null,
//     "billingPhone": null,
//     "shipToName": null,
//     "shipToAddress": null,
//     "shipToStreet1": null,
//     "shipToStreet2": null,
//     "shipToCity": null,
//     "shipToState": null,
//     "shipToZipCode": null,
//     "shipToPhone": null,
//     "corporateName": null,
//     "corporateAttention": null,
//     "corporateStreet1": null,
//     "corporateStreet2": null,
//     "corporateCity": null,
//     "corporateState": null,
//     "corporateZipCode": null,
//     "accountCode": null,
//     "buyer": null,
//     "carrier": null,
//     "confTo": null,
//     "fob": null,
//     "printed": "No",
//     "projectNo": null,
//     "remarks": null,
//     "requestBy": null,
//     "terms": null,
//     "usedBy": null,
//     "lineItems": [
//         {
//             "id": 14,
//             "poNoId": 4,
//             "lobId": 22107,
//             "legacyPoNo": "19243B57-7F56-4F01-B90B-EE16113CC89C",
//             "warehouseId": 3105,
//             "orderDate": "2024-10-23T00:00:00.000Z",
//             "factCost": null,
//             "mlCost": null,
//             "sku": "464331064761",
//             "orderQuantity": 2,
//             "requestedDeliveryDate": "2024-10-23T00:00:00.000Z",
//             "unitCode": "EACH",
//             "wrapCode": "EACH",
//             "unitsPerWrap": 1,
//             "cost": null,
//             "sell": null,
//             "pricingPer": "Each",
//             "maxFreight": null,
//             "chargeFreight": "No",
//             "maxOther": null,
//             "distDate": "2024-10-23T00:00:00.000Z",
//             "voidDate": null,
//             "freezeAction": "N",
//             "revDate": null,
//             "artBack": null,
//             "origin": null,
//             "sample": null,
//             "sampleTo": null,
//             "maxOvers": 0,
//             "maxUnders": 0,
//             "receivedSfp": 0,
//             "budgetCode": null,
//             "accountingCode": null,
//             "taxExempt": null,
//             "capitalize": null,
//             "accrual": null,
//             "oddQuantity": null,
//             "freightCost": null,
//             "receivedDate": null,
//             "receivedQuantity": null,
//             "fromProd": null,
//             "sfpComplete": null,
//             "endQuantity": null,
//             "endVal": null,
//             "endFact": null,
//             "interimQuantity": null,
//             "interimVal": null,
//             "interimFact": null,
//             "lastAct": null,
//             "weightPerWrap": null,
//             "norcs": null,
//             "vendorId": 1,
//             "bsVendor": null,
//             "mlVendor": null,
//             "receiptNo": null,
//             "paidFull": null,
//             "enteredBy": "info@uniel",
//             "receivedBy": null,
//             "lineNo": null,
//             "prodLot": null,
//             "unitsPerCase": null,
//             "caseWeight": null,
//             "height": null,
//             "width": null,
//             "length": null,
//             "dockTime": null,
//             "modifyDate": "2024-10-23T18:56:33.747Z",
//             "impressions": null,
//             "asnLine": null,
//             "customFields": {}
//         }
//     ],
//     "status": "Open",
//     "customFields": {}
// }
// const successfulOrderReqSample = {
//     "orderNo": 21,
//     "customerOrderNo": null,
//     "lobId": 22107,
//     "warehouseId": 3105,
//     "orderDate": "2024-10-21T00:00:00.000Z",
//     "customerNo": "CUST000019",
//     "useOrderNoRoot": null,
//     "firstShipDate": null,
//     "lastShipDate": null,
//     "deliverOnDate": null,
//     "needByDate": null,
//     "carrierId": 0,
//     "serviceTypeId": "1",
//     "shipVia": null,
//     "mediaCode": "Written",
//     "legacyRestrictionType": "H/O All",
//     "alcoholOrderType": "NOT SPECIFIED",
//     "alternateUsage": "N",
//     "authorizationAmount": 0,
//     "authorizedBy": "INFO@UNIELOGICS",
//     "balanceDue": 0,
//     "batchNo": null,
//     "billToAttention": null,
//     "billToCompany": "John Doe",
//     "billToStreet": "823 Westfield Ave",
//     "billToStreet2": null,
//     "billToStreet3": null,
//     "billToCity": "Elizabeth",
//     "billToState": "NJ",
//     "billToZip": "07208",
//     "billToCountry": null,
//     "billToPhone": "9084880500",
//     "billToEmail": "info@unielogics.com",
//     "numberOfCartons": null,
//     "numberOfPallets": null,
//     "completionStatus": "Y",
//     "parcelAccountId": null,
//     "costCenter": null,
//     "createDate": "2024-10-21T16:08:00.000Z",
//     "customerPONo": null,
//     "distributionChannel": null,
//     "distributionCharges": 0,
//     "division": null,
//     "enteredBy": "INFO@UNIEL",
//     "estimatedWeightLbs": null,
//     "freight": null,
//     "giftMessage": null,
//     "groupOrderId": null,
//     "holdCode": "N",
//     "integrationPartnerId": 15,
//     "numberOfLineItems": 1,
//     "modifyDate": "2024-10-21T16:08:57.000Z",
//     "omsOrderNo": null,
//     "omsCustomerId": null,
//     "orderLoadProgramId": 27,
//     "orderMessage": null,
//     "orderReason": null,
//     "orderSourceId": 1,
//     "packingSlipTemplateId": null,
//     "orderInvoiceTemplateId": null,
//     "orderConfirmationEmailTemplateId": null,
//     "shipmentConfirmationEmailTemplateId": null,
//     "priceLevel": "3",
//     "priceMode": 1,
//     "priorityCode": 10,
//     "fulfillmentProcessId": null,
//     "shipBy": "2024-10-21T00:00:00.000Z",
//     "shipCode": "Ready to ship",
//     "shipDate": null,
//     "shipToAttention": null,
//     "shipToCompany": "JOHN DOE",
//     "shipToStreet": "823 WESTFIELD AVE",
//     "shipToStreet2": null,
//     "shipToStreet3": null,
//     "shipToCity": "ELIZABETH",
//     "shipToState": "NJ",
//     "shipToZip": "07208",
//     "shipToCountry": null,
//     "shipToPhone": "9084880500",
//     "shipToEmail": "INFO@UNIELOGICS.COM",
//     "shippingCharge": 0,
//     "status": "Pending",
//     "stopBackOrders": "N",
//     "subtotal": 0,
//     "tax": 0,
//     "total": 0,
//     "totalPaid": 0,
//     "totalQty": 10,
//     "weightLbs": null,
//     "lineItems": [
//         {
//             "id": 36,
//             "lineItemId": 21,
//             "orderNo": 21,
//             "lobId": 22107,
//             "sku": "464331064761",
//             "poNoId": null,
//             "orderedQty": 10,
//             "allowedQty": null,
//             "shippedQty": null,
//             "backorderQty": null,
//             "revDate": null,
//             "chargeCode": "0",
//             "distributionCode": null,
//             "upc": "3267946514897749",
//             "vendorSKU": null,
//             "orderSourceSKU": null,
//             "unitCost": null,
//             "unitSell": 0,
//             "unitDiscount": null,
//             "extendedCost": null,
//             "extendedSell": 0,
//             "extendedDiscount": null,
//             "ncExtendedSell": null,
//             "itemWeight": null,
//             "productionLot": null,
//             "weightPerWrap": null,
//             "sector": null,
//             "itemAccountCodeId": 1,
//             "itemLegacyLowStockContactId": 2,
//             "itemMajorGroupId": 6,
//             "itemSubGroupId": 4,
//             "itemProductCodeId": null,
//             "itemSummaryCodeId": 5,
//             "customFields": {}
//         }
//     ],
//     "extraOrderData": [],
//     "extraLineItemData": [],
//     "externalShippingSystemId": null,
//     "customFields": {}
// }

// interface Order {
//     orderNo: number;
//     orderDate: string;
//     billToEmail: string;
//     lineItems: OrderLine[];
// }

// interface OrderLine {
//     id: number;
//     orderNo: number;
//     sku: string;
//     title: string;
//     unitsPerBox: number;
//     boxWidth: number;
//     boxHeight: number;
//     boxLength: number;
//     weightPerBox: number;
//     asin: string;
//     amazonPrice: number;
//     imageUrl: string;
// }

// export function mapResponseToOrder(response: any): Order {
//     return {
//         orderNo: Number(response.orderNo),
//         orderDate: response.orderDate,
//         billToEmail: response.email,
//         lineItems: response.products.map((product: any) => ({
//             id: Number(product._id),
//             orderNo: Number(response.orderNo),
//             sku: product.fnsku,
//             title: product.title,
//             unitsPerBox: Number(product.unitsPerBox),
//             boxWidth: Number(product.boxWidth),
//             boxHeight: Number(product.boxHeight),
//             boxLength: Number(product.boxLength),
//             weightPerBox: Number(product.weightPerBox),
//             asin: product.asin,
//             amazonPrice: Number(product.amazonPrice),
//             imageUrl: product.imageUrl,
//         })),
//     };
// }

// // Example usage with your response data
// const response = {
//     email: "franco@peri-mail.com",
//     orderNo: "1b57e30a-e0c7-4e37-b5bd-3377a111fd9f",
//     orderDate: "2024-10-20T01:41:17.557Z",
//     products: [
//         {
//             fnsku: "SD42442342",
//             unitsPerBox: "2",
//             boxWidth: "11",
//             boxHeight: "11",
//             boxLength: "11",
//             weightPerBox: "15",
//             asin: "B014G4AY1A",
//             title: "OLLY Women's Multivitamin Gummy, Overall Health and Immune Support",
//             amazonPrice: "1",
//             imageUrl: "https://m.media-amazon.com/images/I/51vQ7-U329L.jpg",
//             _id: "67145fc90053fc0022a0e70d",
//         },
//     ],
// };

// const order = mapResponseToOrder(response);
// console.log(order);


// export const realShipmnetObj = {
//     "email": "franco@peri-mail.com",
//     "orderNo": "1b57e30a-e0c7-4e37-b5bd-3377a111fd9f",
//     "receiptNo": "443134",
//     "orderDate": "2024-10-20T01:41:17.557Z",
//     "shipmentTitle": "demo title",
//     "shipmentPlanId": "35016797-8bd7-456d-a808-00b5669b0632",
//     "products": [
//         {
//             "shrinkWrap": {
//                 "answer": false,
//                 "amount": ""
//             },
//             "specialPackaging": {
//                 "answer": false,
//                 "amount": ""
//             },
//             "supplier": {
//                 "supplierAddress": {
//                     "lat": "40.6726009",
//                     "long": "-74.2248079",
//                     "street": "823 Westfield Ave",
//                     "city": "Elizabeth",
//                     "state": "NJ",
//                     "zipCode": "07208"
//                 },
//                 "contactPerson": {
//                     "name": "Online Supplier",
//                     "email": "-",
//                     "phoneNumber": "-",
//                     "extensionCode": "-"
//                 },
//                 "_id": "659dc19ed26b30002a742e44",
//                 "supplierName": "Vladut",
//                 "supplierLink": "https://google.com"
//             },
//             "unitsPerBox": "2",
//             "boxWidth": "11",
//             "boxHeight": "11",
//             "boxLength": "11",
//             "fnsku": "SD42442342",
//             "boxes": "3",
//             "weightPerBox": "15",
//             "upc": "dasdas",
//             "isHazmat": false,
//             "comments": "",
//             "_id": "67145fc90053fc0022a0e70d",
//             "asin": "B014G4AY1A",
//             "title": "OLLY Women's Multivitamin Gummy, Overall Health and Immune Support, Vitamins A, D, C, E, Biotin, Folic Acid, Adult Chewable Vitamin, Berry, 45 Day Supply - 90 Count (Pack of 1)",
//             "dateAdded": "2024-01-09T21:58:54.816Z",
//             "amazonPrice": "1",
//             "imageUrl": "https://m.media-amazon.com/images/I/51vQ7-U329L.jpg"
//         }
//     ],
//     "shipmentPlan": [
//         {
//             "payment": {
//                 "id": "none",
//                 "paid": false
//             },
//             "files": {
//                 "fbaLabels": [],
//                 "otherFiles": []
//             },
//             "amazonData": {
//                 "shipFrom": "",
//                 "shipDate": "",
//                 "workFlowId": "",
//                 "trackShipmentValue": "",
//                 "trackShipmentUrl": "",
//                 "shippingCharges": "",
//                 "placementFees": "",
//                 "prepLabelFees": "",
//                 "feeSummary": "",
//                 "shipments": []
//             },
//             "dateAdded": "2024-10-20T01:41:29.000Z",
//             "dateUpdated": "2024-10-20T01:41:48.000Z",
//             "status": "Added",
//             "orderNo": "1b57e30a-e0c7-4e37-b5bd-3377a111fd9f",
//             "receiptNo": "",
//             "orderDate": "2024-10-20T01:41:17.557Z",
//             "warehouseOwner": {
//                 "dateAdded": "2023-12-06T23:08:17.000Z",
//                 "dateModified": "2023-12-06T23:08:17.000Z",
//                 "businessAddress": {
//                     "address": "Vlad",
//                     "state": "Vlad",
//                     "city": "Vlad",
//                     "zipCode": "10001",
//                     "lat": "40.7484413",
//                     "long": "-73.9941194"
//                 },
//                 "costPerBox": [
//                     {
//                         "type": "1",
//                         "name": "2",
//                         "price": "12",
//                         "size": {
//                             "width": "1",
//                             "height": "3",
//                             "length": "2"
//                         }
//                     }
//                 ],
//                 "handleShrink": {
//                     "answer": "no",
//                     "small": {
//                         "price": ""
//                     },
//                     "medium": {
//                         "price": ""
//                     },
//                     "large": {
//                         "price": ""
//                     }
//                 },
//                 "handleHazmat": {
//                     "answer": "no",
//                     "pricePerItem": ""
//                 },
//                 "bubbleWrapping": {
//                     "answer": "no",
//                     "pricePerItem": ""
//                 },
//                 "offerStorage": {
//                     "answer": "no"
//                 },
//                 "_id": "8fcc5818-5dd0-4668-b81f-eed574e619a0",
//                 "name": "vladut",
//                 "lobId": "1",
//                 "warehouseId": "1",
//                 "vendorId": "2",
//                 "phoneNumber": "0675237521",
//                 "llcName": "vlad",
//                 "businessName": "Vladut",
//                 "businessPhoneNumber": "0675237521",
//                 "customerServiceEmailAddress": "vlad@alin.com",
//                 "costPerItemLabeling": "12",
//                 "costPerBoxClosing": "12"
//             },
//             "_id": "35016797-8bd7-456d-a808-00b5669b0632",
//             "products": [
//                 {
//                     "shrinkWrap": {
//                         "answer": false,
//                         "amount": ""
//                     },
//                     "specialPackaging": {
//                         "answer": false,
//                         "amount": ""
//                     },
//                     "supplier": {
//                         "supplierAddress": {
//                             "lat": "40.6726009",
//                             "long": "-74.2248079",
//                             "street": "823 Westfield Ave",
//                             "city": "Elizabeth",
//                             "state": "NJ",
//                             "zipCode": "07208"
//                         },
//                         "contactPerson": {
//                             "name": "Online Supplier",
//                             "email": "-",
//                             "phoneNumber": "-",
//                             "extensionCode": "-"
//                         },
//                         "_id": "659dc19ed26b30002a742e44",
//                         "supplierName": "Vladut",
//                         "supplierLink": "https://google.com"
//                     },
//                     "unitsPerBox": "2",
//                     "boxWidth": "11",
//                     "boxHeight": "11",
//                     "boxLength": "11",
//                     "fnsku": "SD42442342",
//                     "boxes": "3",
//                     "weightPerBox": "15",
//                     "upc": "dasdas",
//                     "isHazmat": false,
//                     "comments": "",
//                     "_id": "67145fc90053fc0022a0e70d",
//                     "asin": "B014G4AY1A",
//                     "title": "OLLY Women's Multivitamin Gummy, Overall Health and Immune Support, Vitamins A, D, C, E, Biotin, Folic Acid, Adult Chewable Vitamin, Berry, 45 Day Supply - 90 Count (Pack of 1)",
//                     "dateAdded": "2024-01-09T21:58:54.816Z",
//                     "amazonPrice": "1",
//                     "imageUrl": "https://m.media-amazon.com/images/I/51vQ7-U329L.jpg"
//                 }
//             ],
//             "shipmentTitle": "demo title"
//         }
//     ]
// }