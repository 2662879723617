import axios from "axios";
import {
	IDeleteWarehouseOwnerRequest,
	IDeleteWarehouseOwnerResponse,
	IGetWarehouseOwnerResponse,
	IPostWarehouseOwnerRequest,
	IPostWarehouseOwnerResponse,
	IUpdateWarehouseOwnerResponse,
} from "pages/adminWarehouseOwners/interfaces";
import { apiRoutes } from "utils";
import { UpdateWarehouseModel } from "../editWarehouse/editwarehouseModel";

export const getWarehouseOwnersRequest = async (
	email: string
) => {
	const { data } =
		await axios.get<IGetWarehouseOwnerResponse>(
			`${apiRoutes.GET_WAREHOUSE_OWNERS}?loggedInEmail=${email}`
		);

	return data;
};

export const postAddWarehouseOwner = async ({
	email,
	wOwnerData,
}: IPostWarehouseOwnerRequest) => {
	const { data } =
		await axios.post<IPostWarehouseOwnerResponse>(
			apiRoutes.POST_ADD_WAREHOUSE_OWNER,
			{
				loggedInEmail: email,
				wOwner: wOwnerData,
			}
		);
	return data;
};

export const UpdateWarehouseOwner = async (
	updateWarehouseModel: UpdateWarehouseModel
) => {
	console.log(updateWarehouseModel);

	const { data } =
		await axios.put<IUpdateWarehouseOwnerResponse>(
			apiRoutes.EDIT_WAREHOUSE_OWNER,
			updateWarehouseModel
		);
	return data;
};

export const deleteWarehouseOwners = async ({
	email,
	_id,
}: IDeleteWarehouseOwnerRequest) => {
	const { data } =
		await axios.delete<IDeleteWarehouseOwnerResponse>(
			apiRoutes.DELETE_WAREHOUSE_OWNER,
			{
				data: { email, _id },
			}
		);

	return data;
};
