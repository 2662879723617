import axios from "axios";
import { apiRoutes } from "utils";
import { IGetSimpleProfileResponse } from "./interfaces";

export const getSimpleProfile = async (email: string) => {
  const { data } = await axios.get<IGetSimpleProfileResponse>(
    `${apiRoutes.SIMPLE_PROFILE}?email=${encodeURIComponent(email)}`
  );
  return data;
};
