import { useQuery } from "react-query";
import { QUERY_KEYS, UserContext } from "utils";

import { useContext, useEffect, useState } from "react";
import { getUsers } from "./api";

import "./styles.scss";
import { users as globalUsers } from "utils/globals";
import { Footer, Loading, SidebarMenu, ViewUsers } from "components";
import { IContextUser } from "common/interfaces";

const AdminView = () => {
  const [users, setUsers] = useState<any>([]);
  let user: IContextUser = useContext(UserContext)!;
  const {
    data,
    refetch: getUSersRequest,
    isLoading: isLoadingUsers,
  } = useQuery(QUERY_KEYS.GET_ALL_USERS, () => getUsers(), {
    refetchOnWindowFocus: false,
    enabled: false, // turned off by default, manual refetch is needed so I can call it on cick
    retry: false,
    cacheTime: 10 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });
  useEffect(() => {
    if (user.email === globalUsers.ADMIN_USER) {
      getUSersRequest();
    } else {
      window.location.href = "/";
    }
  }, [user, getUSersRequest]);

  useEffect(() => {
    if (data) {
      setUsers(data);
    }
  }, [data]);

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="navbar-container">
              <SidebarMenu active="users" sidebarOnly={true} />
            </div>
            <div className="admin-view">
              {isLoadingUsers && <Loading />}
              {users.length > 0 && <ViewUsers users={users} />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminView;
