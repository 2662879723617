import axios from "axios";
import {
  IDeleteShipmentPlan,
  IDeleteShipmentPlanResponse,
  IGetShipmentPlanResponse,
} from "../interfaces";
import { apiRoutes } from "utils";

export const getShipmentPlanRequest = async (email: string) => {
  const { data } = await axios.get<IGetShipmentPlanResponse>(
    `${apiRoutes.GET_ALL_SHIPMENT_PLAN}?email=${encodeURIComponent(email)}`
  );
  return data;
};

export const deleteShipmentPlanRequest = async ({
  email,
  _id,
}: IDeleteShipmentPlan) => {
  const { data } = await axios.delete<IDeleteShipmentPlanResponse>(
    apiRoutes.DELETE_SHIPMENT_PLAN,
    {
      data: { email, _id },
    }
  );

  return data;
};
