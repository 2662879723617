import React, { useState } from "react";
import EditableFormTable from "components/ui/EditableFormtable";
import ReadOnlyDataViewer from "components/ui/ReadOnlyDataViewer";
import useShipmentPlanStore from "utils/zustandStore/useShipmentPlanStore";
import useAppStore from "utils/zustandStore/useAppStore";
import { initializeAsnBodyEssentials } from "./asnRequestObj";
import { exampleAsnData } from "./mapResponseToOrder";

export default function ReviewAsn() {
  const { data } = useShipmentPlanStore();
  const { updateData } = useAppStore();
  const [showExample, setShowExample] = useState(false);
  const spData = data?.shipmentPlan?.shipmentPlan[0];
  const whData = data?.warehouse;
  const [asnState, setAsnState] = useState(
    initializeAsnBodyEssentials({ whData, spData })
  );

  const handleObjectChange = async (newObject: any) => {
    const cleanObject = JSON.parse(JSON.stringify(newObject));
    setAsnState(cleanObject);
    updateData((prevData: any) => ({
      ...prevData,
      asn: cleanObject,
    }));
  };

  return (
    <div>
      <h3>Review ASN Details</h3>
      <EditableFormTable saveChanges={handleObjectChange} data={asnState} />
      <button
        className="btn text-white bg-gray-600 mx-4"
        onClick={() => handleObjectChange(asnState)}
      >
        Confirm ASN
      </button>

      <button
        className="btn text-white bg-gray-600"
        onClick={() => setShowExample(!showExample)}
      >
        {showExample ? "Hide Example" : "Show Example ASN"}
      </button>
      {showExample && <ReadOnlyDataViewer data={exampleAsnData} />}
    </div>
  );
}
