import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { createWmsOrder } from "utils/services/infoplusApi/order";
import { createAsn } from "utils/services/infoplusApi/asn";
import useAppStore from "utils/zustandStore/useAppStore";
import ReadOnlyDataViewer from "components/ui/ReadOnlyDataViewer";

const InfoPlusProcessOrder: React.FC = () => {
  const { data } = useAppStore();
  const asn = data?.asn;
  const order = data?.order;

  const createAsnAndOrderMutation = useMutation(
    async () => {
      toast.info("Processing your request...");

      const orderResponse = await createWmsOrder(order);
      toast.success("Order created successfully!");

      const asnResponse = await createAsn(asn);
      toast.success("ASN created successfully!");

      return { orderResponse, asnResponse };
    },
    {
      onSuccess: () => {
        toast.success("All operations completed successfully!", {
          position: "top-right",
          autoClose: 5000,
        });
      },
      onError: (error: any) => {
        toast.error(`Operation failed: ${error.message || "Unknown error"}`, {
          position: "bottom-center",
          autoClose: false,
        });
      },
    }
  );

  return (
    <div>
      <h2>Process Order and ASN</h2>
      <ReadOnlyDataViewer data={order} />
      <ReadOnlyDataViewer data={asn} />
      <button
        onClick={() => createAsnAndOrderMutation.mutate()}
        disabled={createAsnAndOrderMutation.isLoading}
      >
        {createAsnAndOrderMutation.isLoading
          ? "Processing..."
          : "Create ASN and Order"}
      </button>
    </div>
  );
};

export default InfoPlusProcessOrder;
