export const profitableProductsTableHeaders = [
  {
    name: "#",
  },
  {
    name: "ADDED",
  },
  {
    name: "ASIN",
  },
  {
    name: "IMG",
  },
  {
    name: "TITLE",
  },
  {
    name: "PRICE",
  },
  {
    name: "HAZMAT",
  },
  {
    name: "STATUS",
  },
  {
    name: "ACTIONS",
  },
  {
    name: "SUPPLIERS",
  },
];

export const bucketsTableHeaders = [
  {
    name: "ADDED",
  },
  {
    name: "ASIN",
  },
  {
    name: "BSR",
  },
  {
    name: "IMAGE",
  },
  {
    name: "TITLE",
  },
  {
    name: "Sellers",
  },
  {
    name: "PRICE",
  },
  {
    name: "FEES",
  },
  {
    name: "FULLFILMENT",
  },
  {
    name: "PROFIT EST.",
  },
  {
    name: "ROI",
  },
  {
    name: "ACTIONS",
  },
  {
    name: "BUY",
  },
];

export const savedSearchesTableHeaders = [
  {
    name: "TERM",
  },
  {
    name: "SEARCH URL",
  },
  {
    name: "DATE ADDED",
  },
  {
    name: "PRICE",
  },
  {
    name: "RATING",
  },
  {
    name: "REVIEWS",
  },
  {
    name: "ROI",
  },
  {
    name: "T. S. FEES",
  },
  {
    name: "$ PER SALE",
  },
  {
    name: "ACTIONS",
  },
];

export const suppliersTableHeaders = [
  {
    name: "#",
  },
  {
    name: "NAME",
  },
  {
    name: "LINK",
  },
  {
    name: "ADDRESS",
  },
  {
    name: "CONTACT PERSON",
  },
  {
    name: "LOCATION",
  },
  {
    name: "ACTIONS",
  },
];

export const warehouseOwnersTableHeaders = [
  {
    name: "W. DETAILS",
  },
  {
    name: "P. DETAILS",
  },
  {
    name: "BUSINESS NAME",
  },
  {
    name: "LABELING",
  },
  {
    name: "BOX CLOSING",
  },
  {
    name: "COST PER BOX",
  },
  {
    name: "SHRINK WRAP",
  },
  {
    name: "HAZMAT",
  },
  {
    name: "BUBBLE WRAPPING",
  },
  {
    name: "STORAGE",
  },
  {
    name: "ACTIONS",
  },
];
export const shipmentTableHeaders = [
  {
    name: "#",
  },
  {
    name: "ADDED",
  },
  {
    name: "TITLE",
  },
  {
    name: "PRODUCTS COUNT",
  },
  {
    name: "STATUS",
  },
  {
    name: "ACTIONS",
  },
];
export const shipmentProductsHeaders = [
  {
    name: "ASIN",
  },
  {
    name: "IMAGE",
  },
  {
    name: "TITLE",
  },
  {
    name: "UNITS PER BOX",
  },
  {
    name: "DIMENSIONS",
  },
  {
    name: "PRICE",
  },
  {
    name: "SUPPLIER",
  },
];

export const warehousesMapHeaders = [
  ...shipmentProductsHeaders,
  {
    name: "WAREHOUSES",
  },
];
