import * as Yup from "yup";

export const validationSchema = {
  lobId: Yup.string().required("Lob Id cannot be empty"),
  warehouseId: Yup.string().required("Warehouse Id cannot be empty"),
  vendorId: Yup.string().required("Vendor Id cannot be empty"),
  name: Yup.string()
    .min(3, "Name must be at least 3 characters long")
    .required("Name cannot be empty"),
  email: Yup.string()
    .required("Email cannot be empty")
    .matches(/.+@.+\..+/, "Email value must be a valid one"),
  phoneNumber: Yup.string()
    .matches(
      /^[0-9-]*[0-9]+[0-9-]*$/,
      "Phone number must contain only numeric digits"
    )
    .test(
      "is-at-least-10-digits",
      "Phone number must be at least 10 characters long",
      (value) => {
        const numericValue = value?.replace(/-/g, "");
        return numericValue ? numericValue.length >= 10 : false;
      }
    )
    .required("Phone number cannot be empty"),
  llc: Yup.string().required("LLC cannot be empty"),
  password: Yup.string()
    .required("Password cannot be empty")
    .min(6, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#%&*])/,
      "Password must contain at least 1 capital letter and 1 special character"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password field cannot be empty")
    .oneOf([Yup.ref("password")], "Password and Confirm Password do not match"),
  businessName: Yup.string()
    .required("Business name cannot be empty")
    .min(3, "Business name must be at least 3 characters long"),
  address: Yup.string().required("Address cannot be empty"),
  city: Yup.string()
    .min(3, "City must be at least 3 characters long")
    .required("City cannot be empty"),
  state: Yup.string()
    .min(2, "State must be at least 2 characters long")
    .required("State cannot be empty"),
  zipCode: Yup.string()
    .min(5, "Zip Code must be at least 5 characters long")
    .max(6, "Zip Code must be max 6 characters long")
    .required("Zip Code cannot be empty"),
  businessPhoneNumber: Yup.string()
    .matches(
      /^[0-9-]*[0-9]+[0-9-]*$/,
      "Phone number must contain only numeric digits"
    )
    .test(
      "is-at-least-10-digits",
      "Phone number must be at least 10 characters long",
      (value) => {
        const numericValue = value?.replace(/-/g, "");
        return numericValue ? numericValue.length >= 10 : false;
      }
    )
    .required("Phone number cannot be empty"),
  customerEmailService: Yup.string()
    .required("Business email cannot be empty")
    .matches(/.+@.+\..+/, "Business email value must be a valid one"),
  costPerItemLabeling: Yup.string().required(
    "Cost per item labeling cannot be empty"
  ),
  costPerBoxClosing: Yup.string().required(
    "Cost per box closing cannot be empty"
  ),
  type1: Yup.string(),
  name1: Yup.string(),
  width1: Yup.string(),
  height1: Yup.string(),
  length1: Yup.string(),
  price1: Yup.string(),
  decideShrinkWrap: Yup.string(),
  decideSmallPrice: Yup.string().when("decideShrinkWrap", (val: any) => {
    if (val[0] === "Yes") {
      return Yup.string().required("Small price cannot be empty");
    } else return Yup.string().notRequired();
  }),
  decideMediumPrice: Yup.string().when("decideShrinkWrap", (val: any) => {
    if (val[0] === "Yes") {
      return Yup.string().required("Medium price cannot be empty");
    } else return Yup.string().notRequired();
  }),
  decideLargePrice: Yup.string().when("decideShrinkWrap", (val: any) => {
    if (val[0] === "Yes") {
      return Yup.string().required("Large price cannot be empty");
    } else return Yup.string().notRequired();
  }),
  decideHazmat: Yup.string(),
  decideHazmatPrice: Yup.string().when("decideHazmat", (val: any) => {
    if (val[0] === "Yes") {
      return Yup.string().required("Hazmat price cannot be empty");
    } else return Yup.string().notRequired();
  }),
  decideBubbleWrapping: Yup.string(),
  decideBubbleWrappingPrice: Yup.string().when(
    "decideBubbleWrapping",
    (val: any) => {
      if (val[0] === "Yes") {
        return Yup.string().required("Bubble wrapping price cannot be empty");
      } else return Yup.string().notRequired();
    }
  ),
  decideOfferStorage: Yup.string(),
  decidePricePerPalet: Yup.string().when("decideOfferStorage", (val: any) => {
    if (val[0] === "Yes") {
      return Yup.string().required("Price per palet cannot be empty");
    } else return Yup.string().notRequired();
  }),
  decidePricePerCubicFeet: Yup.string().when(
    "decideOfferStorage",
    (val: any) => {
      if (val[0] === "Yes") {
        return Yup.string().required("Price per cubic feet cannot be empty");
      } else return Yup.string().notRequired();
    }
  ),
};
