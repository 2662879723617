import axios from "axios";
import { apiRoutes } from "utils";
import {
  IGetBucketsProductsResponse,
  IBucketsProductDeleteRequest,
  IBucketsProductsResponse,
} from "../interfaces";

export const getProducts = async (email: string) => {
  const { data } = await axios.get<IGetBucketsProductsResponse>(
    `${apiRoutes.BUCKETS_PRODUCTS}?email=${email}`
  );
  return data;
};

export const postDeleteProduct = async ({
  email,
  asin,
}: IBucketsProductDeleteRequest) => {
  const { data } = await axios.post<IBucketsProductsResponse>(
    apiRoutes.DELETE_BUCKETS_PRODUCT,
    {
      email,
      asin,
    }
  );
  return data;
};
