import React from "react";
import { useParams } from "react-router-dom";
import InfoPlusProcessOrder from "./InfoPlusProcessOrder";

const InfoPlusDetails: React.FC = () => {
  const { id } = useParams();

  return (
    <div>
      <h2>InfoPlus Details {id ? `for ID: ${id}` : "no Id"}</h2>
      <hr />
      <InfoPlusProcessOrder />

    </div>
  );
};

export default InfoPlusDetails;
