import AmazonDataCard from 'components/AmazonDataCard';
import AmazonDataShipmentPlanDetails from 'components/AmazonDataShipmentPlanDetails';
import WarehouseDetails from 'components/WarehouseDetails';
import React, { useEffect, useState } from 'react';
import { IShipmentPlan } from 'pages/shipmentPlans/interfaces';
import AmazonVendorDetails from './AmazonVendorDetails';

interface PricingType {
    labelling: number;
    box: number;
    boxClosing: number;
    bundling: number;
    hazmat: number;
    processingFeesPercentage: number;
}

interface AmazonDataCollapseProps {
    shipmentPlan: IShipmentPlan;
    totalPriceCb: string;
    pricing: PricingType;
    shipmentPlanData: any;
}

export default function AmazonDataCollapse({
    shipmentPlan,
    totalPriceCb,
    pricing,
    shipmentPlanData
}: AmazonDataCollapseProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);

    return (
        <div className="flex flex-wrap gap-4 ml-3 my-2">
            {/* Amazon and Warehouse Details Accordion */}
            <div className="w-2/3   ">
                <div className="border rounded-lg shadow-lg">
                    {/* Header */}
                    <div className="p-1 border-b rounded-t-lg bg-gray-800 text-white">
                        <button
                            className="w-full text-left font-bold text-lg hover:text-blue-600 transition-colors"
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Amazon and Warehouse Details
                        </button>
                    </div>
                    {/* Content */}
                    <div
                        className={`transition-[max-height] duration-500 overflow-hidden ${isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <div className="p-2 flex justify-around">
                            <AmazonVendorDetails vendor={shipmentPlan.supplier} /> 
                            
                            <WarehouseDetails warehouseOwner={shipmentPlan?.warehouseOwner} />

                            <AmazonDataShipmentPlanDetails amazonData={shipmentPlan?.amazonData} shipmentPlanId={shipmentPlan?._id} />

                            {/* {shipmentPlan?.amazonData?.shipments?.length > 0 &&
                                shipmentPlan?.amazonData?.shipments.map((amazonShipment, index: number) => (
                                    <AmazonDataCard
                                        key={index}
                                        shipmentPlan={shipmentPlan}
                                        amazonShipment={amazonShipment}
                                        index={index}
                                    />
                                ))} */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Payment Chart Accordion */}
            <div className="w-3/12">
                <div className="border rounded-lg shadow-lg">
                    {/* Header */}
                    <div className="bg-gray-800 text-white p-1 border-b rounded-t-lg">
                        <button
                            className="w-full text-left font-bold text-lg hover:text-green-600 transition-colors"
                            type="button"
                            onClick={() => setIsPaymentOpen(!isPaymentOpen)}
                        >
                            Pricing Chart |
                            <span className="text-green-500 ml-2">Total: ${totalPriceCb}</span>
                        </button>
                    </div>
                    {/* Content */}
                    <div
                        className={`transition-[max-height] duration-500 overflow-hidden ${isPaymentOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            }`}
                    >
                        <div className="p-4 ">
                            <h6 className="font-semibold mb-3 text-white">Payment Details</h6>
                            <ul className="space-y-2 text-white">
                                <li>
                                    <span className="font-medium">Labelling:</span> ${pricing.labelling}
                                </li>
                                <li>
                                    <span className="font-medium">Box:</span> ${pricing.box}
                                </li>
                                <li>
                                    <span className="font-medium">Box Closing:</span> ${pricing.boxClosing}
                                </li>
                                <li>
                                    <span className="font-medium">Bundling/Special Pack:</span> ${pricing.bundling}
                                </li>
                                <li>
                                    <span className="font-medium">Hazmat:</span> ${pricing.hazmat}
                                </li>
                                <li>
                                    <span className="font-medium">Processing Fees:</span> {pricing.processingFeesPercentage}%
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}