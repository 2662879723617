import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { MultiLineChart } from "components";
import { getKeepaProductRequest } from "./api";
import { getQueryParameterByName, QUERY_KEYS } from "utils";

import "./styles.scss";

interface ICharts {
  asinFromParent?: string;
}

const Charts = ({ asinFromParent }: ICharts) => {
  const [chartData, setChartData] = useState<any>([]);
  const asin = getQueryParameterByName("asin") || asinFromParent || "";
  const { data, isLoading, error } = useQuery(
    QUERY_KEYS.GET_PRODUCT_KEEPA,
    () => getKeepaProductRequest(asin),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 10 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    }
  );

  useEffect(() => {
    if (
      data &&
      !data.error &&
      (data.products[0].csv[1] ||
        data.products[0].csv[2] ||
        data.products[0].csv[3] ||
        data.products[0].csv[4] ||
        data.products[0].csv[5])
    ) {
      constructDataForMultiLineChart(data);
    }
    if (error) {
      toast.error("Something went wrong!");
    }
  }, [data, error]);

  const constructDataForMultiLineChart = (data: any) => {
    const keepaProductsCsv = data.products[0].csv;

    const keepaAmazonPriceHistory = keepaProductsCsv[0] || [];
    const keepaNewPriceHistory = keepaProductsCsv[1] || [];
    const keepaSalesRankHistory = keepaProductsCsv[3] || [];
    const keepaBuyBoxPriceHistory = keepaProductsCsv[18] || [];
    const keepaFbaPriceHistory = keepaProductsCsv[10] || [];
    const sellersNewHistory = keepaProductsCsv[11] || [];

    let keepaChartData = [];
    let keepaLast3MonthsChartData = [];
    let keepaLast6MonthsChartData = [];
    let keepaLast12MonthsChartData = [];

    let prevAmzPrice = null;
    let prevNewPrice = null;
    let prevBuyBoxPrice = null;
    let prevFbaPrice = null;
    let prevNewSellerCount = null;
    let amazonPrice = null;
    let newPrice = null;
    let buyBoxPrice = null;
    let fbaPrice = null;
    let newSellerCount = null;

    let dateToday = new Date();
    let todayTimestamp = dateToday.getTime();
    let last3MonthsLength = todayTimestamp - 1000 * 60 * 60 * 24 * 90;
    let last6MonthsLength = todayTimestamp - 1000 * 60 * 60 * 24 * 180;
    let last12MonthsLength = todayTimestamp - 1000 * 60 * 60 * 24 * 367;

    for (let i = 0; i <= keepaSalesRankHistory.length - 1; i++) {
      if (i % 2 === 0) {
        //Format Amz Price Data
        if (keepaAmazonPriceHistory) {
          amazonPrice = prevAmzPrice;
          if (keepaAmazonPriceHistory.includes(keepaSalesRankHistory[i])) {
            let indexOfAmazonData = keepaAmazonPriceHistory.indexOf(
              keepaSalesRankHistory[i]
            );
            if (keepaAmazonPriceHistory[indexOfAmazonData + 1] === -1) {
              amazonPrice = null;
            } else {
              amazonPrice = keepaAmazonPriceHistory[indexOfAmazonData + 1];
            }
          }
          //Update
          prevAmzPrice = amazonPrice;
        }

        //Format Amz Price Data End

        //Format New Price Data
        if (keepaNewPriceHistory) {
          newPrice = prevNewPrice;
          if (keepaNewPriceHistory.includes(keepaSalesRankHistory[i])) {
            let indexOfNewData = keepaNewPriceHistory.indexOf(
              keepaSalesRankHistory[i]
            );
            if (keepaNewPriceHistory[indexOfNewData + 1] === -1) {
              newPrice = null;
            } else {
              newPrice = keepaNewPriceHistory[indexOfNewData + 1];
            }
          }

          //Update
          prevNewPrice = newPrice;
        }

        //Format New Price Data End

        //Format BuyBox Price Data
        if (keepaBuyBoxPriceHistory) {
          buyBoxPrice = prevBuyBoxPrice;

          if (keepaBuyBoxPriceHistory.includes(keepaSalesRankHistory[i])) {
            let indexOfBuyBoxData = keepaBuyBoxPriceHistory.indexOf(
              keepaSalesRankHistory[i]
            );

            if (keepaBuyBoxPriceHistory[indexOfBuyBoxData + 1] === -1) {
              buyBoxPrice = null;
            } else {
              let buyBoxOriginalPrice =
                keepaBuyBoxPriceHistory[indexOfBuyBoxData + 1];
              let buyBoxShippingPrice =
                keepaBuyBoxPriceHistory[indexOfBuyBoxData + 2];
              buyBoxPrice = buyBoxOriginalPrice + buyBoxShippingPrice;
            }
          }

          //Update
          prevBuyBoxPrice = buyBoxPrice;
        }

        //Format BuyBox Price Data End

        //Format FBA  Price Data
        if (keepaFbaPriceHistory) {
          fbaPrice = prevFbaPrice;

          if (keepaFbaPriceHistory.includes(keepaSalesRankHistory[i])) {
            let indexOfFbaData = keepaFbaPriceHistory.indexOf(
              keepaSalesRankHistory[i]
            );

            if (keepaFbaPriceHistory[indexOfFbaData + 1] === -1) {
              fbaPrice = null;
            } else {
              fbaPrice = keepaFbaPriceHistory[indexOfFbaData + 1];
            }
          }

          //Update
          prevFbaPrice = fbaPrice;
        }

        //Format FBA Price Data End

        //Format new Seller Data
        if (sellersNewHistory) {
          newSellerCount = prevNewSellerCount;

          if (sellersNewHistory.includes(keepaSalesRankHistory[i])) {
            let indexOfNewSellerData = sellersNewHistory.indexOf(
              keepaSalesRankHistory[i]
            );

            if (sellersNewHistory[indexOfNewSellerData + 1] === -1) {
              newSellerCount = null;
            } else {
              newSellerCount = sellersNewHistory[indexOfNewSellerData + 1];
            }
          }

          //Update
          prevNewSellerCount = newSellerCount;
        }

        //Format new Seller Data End

        keepaSalesRankHistory[i + 1] !== -1 &&
          keepaChartData.push({
            date: (keepaSalesRankHistory[i] + 21564000) * 60000,
            bsr: keepaSalesRankHistory[i + 1],
            amazon: prevAmzPrice ? prevAmzPrice / 100 : null,
            buyBox: prevBuyBoxPrice ? prevBuyBoxPrice / 100 : null,
            fba: prevFbaPrice ? prevFbaPrice / 100 : null,
            new: prevNewPrice ? prevNewPrice / 100 : null,
            newOfferCount: prevNewSellerCount,
          });

        //Last 3 Months Data
        if (
          last3MonthsLength <=
          (keepaSalesRankHistory[i] + 21564000) * 60000
        ) {
          keepaLast3MonthsChartData.push({
            date: (keepaSalesRankHistory[i] + 21564000) * 60000,
            bsr: keepaSalesRankHistory[i + 1],
            amazon: prevAmzPrice ? prevAmzPrice / 100 : null,
            buyBox: prevBuyBoxPrice ? prevBuyBoxPrice / 100 : null,
            fba: prevFbaPrice ? prevFbaPrice / 100 : null,
            new: prevNewPrice ? prevNewPrice / 100 : null,
            newOfferCount: prevNewSellerCount,
          });
        }

        //Last 6 Months Data
        if (
          last6MonthsLength <=
          (keepaSalesRankHistory[i] + 21564000) * 60000
        ) {
          keepaLast6MonthsChartData.push({
            date: (keepaSalesRankHistory[i] + 21564000) * 60000,
            bsr: keepaSalesRankHistory[i + 1],
            amazon: prevAmzPrice ? prevAmzPrice / 100 : null,
            buyBox: prevBuyBoxPrice ? prevBuyBoxPrice / 100 : null,
            fba: prevFbaPrice ? prevFbaPrice / 100 : null,
            new: prevNewPrice ? prevNewPrice / 100 : null,
            newOfferCount: prevNewSellerCount,
          });
        }

        //Last 6 Months Data
        if (
          last12MonthsLength <=
          (keepaSalesRankHistory[i] + 21564000) * 60000
        ) {
          keepaLast12MonthsChartData.push({
            date: (keepaSalesRankHistory[i] + 21564000) * 60000,
            bsr: keepaSalesRankHistory[i + 1],
            amazon: prevAmzPrice ? prevAmzPrice / 100 : null,
            buyBox: prevBuyBoxPrice ? prevBuyBoxPrice / 100 : null,
            fba: prevFbaPrice ? prevFbaPrice / 100 : null,
            new: prevNewPrice ? prevNewPrice / 100 : null,
            newOfferCount: prevNewSellerCount,
          });
        }
      }
    }

    // setChartData(keepaLast3MonthsChartData);

    setChartData([
      keepaChartData,
      keepaLast3MonthsChartData,
      keepaLast6MonthsChartData,
      keepaLast12MonthsChartData,
      keepaChartData,
    ]);
  };

  return (
    <>
      <div
        className="container-fluid charts text-center"
        style={{ background: "black" }}
      >
        <div className="row">
          <div className="col-12">
            {isLoading ? (
              <div className="spinner-border charts-loading mt-5" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : !isLoading && data?.error ? (
              <div className="error mt-5">{data?.error.message}</div>
            ) : !isLoading &&
              data &&
              (data.products[0].csv[1] ||
                data.products[0].csv[2] ||
                data.products[0].csv[3] ||
                data.products[0].csv[4] ||
                data.products[0].csv[5]) ? (
              <>
                {/* <MultiLineChart chartData={secondChartData} /> */}
                <MultiLineChart chartData={chartData} />
              </>
            ) : (
              <div className="error mt-5">
                We're sorry, there was an error processing your charts.
                <br />
                We don't have enough data to render the charts.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Charts;
