import axios from "axios";

import { apiRoutes } from "utils";
import {
  IDeleteShipmentPlanFileResponse,
  IDeleteShipmentPlanProduct,
  IDeleteShipmentPlanProductResponse,
  IGetShipmentPlanResponse,
  IPayShipmentPlan,
  IPostShipmentPlanFilesResponse,
  IUpdateShipmentPlanProduct,
  IUpdateShipmentPlanResponse,
} from "../interfaces";

export const getShipmentPlanRequest = async (
  email: string,
  planId: string | null
) => {
  const { data } = await axios.get<IGetShipmentPlanResponse>(
    `${apiRoutes.GET_SHIPMENT_PLAN}?email=${encodeURIComponent(
      email
    )}&_id=${planId}`
  );

  return data;
};

export const deleteShipmentPlanProductRequest = async ({
  email,
  shipmentPlanId,
  productId,
}: IDeleteShipmentPlanProduct) => {
  const { data } = await axios.delete<IDeleteShipmentPlanProductResponse>(
    `${apiRoutes.DELETE_SHIPMENT_PLAN_PRODUCT}`,
    {
      data: {
        email,
        shipmentPlanId,
        productId,
      },
    }
  );

  return data;
};

export const updateShipmentPlanProductRequest = async (
  updatedShipmentPlan: IUpdateShipmentPlanProduct
) => {
  const { data } = await axios.put<IUpdateShipmentPlanResponse>(
    apiRoutes.UPDATE_SHIPMENT_PLAN_PRODUCT,
    updatedShipmentPlan
  );

  return data;
};

export const postShipmentPlanFilesRequest = async (formData: FormData) => {
  const { data } = await axios.post<IPostShipmentPlanFilesResponse>(
    apiRoutes.POST_SHIPMENT_PLAN_UPLOAD_FILES,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return data;
};

export const postShipmentPlanPaymentRequest = async (
  payload: IPayShipmentPlan
) => {
  const { data } = await axios.post<any>(
    apiRoutes.POST_PAY_SHIPMENT_PLAN,
    payload
  );

  return data;
};

export const deleteShipmentPlanFileRequest = async ({
  email,
  shipmentPlanId,
  fileToDelete,
  fileType,
}: any) => {
  const { data } = await axios.delete<IDeleteShipmentPlanFileResponse>(
    apiRoutes.DELETE_SHIPMENT_PLAN_FILE,
    {
      data: {
        email,
        shipmentPlanId,
        fileToDelete,
        fileType,
      },
    }
  );

  return data;
};
