import { createContext } from "react";

export const UserContext = createContext(null);

type ShipmentPlanDateSelectionContextType = {
  shipmentPlanDateSelectionFlag: boolean;
  setShipmentPlanDateSelectionFlag: (flag: boolean) => void;
  shipmentPlanEstimateArrivalDate: Date;
  setShipmentPlanEstimateArrivalDate: (date: Date) => void;
  shipmentPlanDateSelection: Date;
  setShipmentPlanDateSelection: (date: Date) => void;
};

export const ShipmentPlanDateSelectionContext = createContext<
  Partial<ShipmentPlanDateSelectionContextType>
>({
  shipmentPlanDateSelectionFlag: false,
  setShipmentPlanDateSelectionFlag: () => {},
  shipmentPlanEstimateArrivalDate: new Date() || null,
  setShipmentPlanEstimateArrivalDate: () => {},
  shipmentPlanDateSelection: new Date(),
  setShipmentPlanDateSelection: () => {},
});
