// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warehouse-owners .warehouse-business-ul li {
  list-style-type: none;
}
.warehouse-owners .warehouse-business-ul li span {
  font-style: italic;
}

.edit {
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/adminWarehouseOwners/styles.scss"],"names":[],"mappings":"AAEI;EACE,qBAAA;AADN;AAEM;EACE,kBAAA;AAAR;;AAMA;EACE,kBAAA;AAHF","sourcesContent":[".warehouse-owners {\n  .warehouse-business-ul {\n    li {\n      list-style-type: none;\n      span {\n        font-style: italic;\n      }\n    }\n  }\n}\n\n.edit{\n  padding-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
