import { Footer, Loading, PageTitle, SidebarMenu } from "components";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { QUERY_KEYS, UserContext } from "utils";
import { deleteSavedSearchesRequest, getSavedSearchesRequest } from "./api";
import { ISavedSearches } from "./interfaces";
import { toast } from "react-toastify";
import { savedSearchesTableHeaders } from "utils/configs";
import dayjs from "dayjs";
import { IContextUser } from "common/interfaces";

const SavedSearches = () => {
  let user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const { data, isLoading: isLoadingGetSavedSearches } = useQuery(
    QUERY_KEYS.GET_SAVED_SEARCHES,
    () => getSavedSearchesRequest(email)
  );
  const [savedSearches, setSavedSearches] = useState<any>([]);

  useEffect(() => {
    if (data?.response?.savedSearches) {
      setSavedSearches(data?.response?.savedSearches);
    }
  }, [data]);

  const {
    mutate: deleteSavedSearchesMutate,
    isLoading: isLoadingDeleteSavedSearches,
  } = useMutation(deleteSavedSearchesRequest, {
    onSuccess: (successData) => {
      toast.success(`Successfully deleted product.`);
      setSavedSearches(successData?.response?.savedSearches);
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ?? "There was an error signing you in."
      );
    },
  });

  const onDeleteIconClick = (_id: string) => {
    deleteSavedSearchesMutate({ email, _id });
  };

  return (
    <>
      <div className="content">
        <div className="container-fluid saved-searches">
          <SidebarMenu active="saved-searches" sidebarOnly={true} />
          {isLoadingGetSavedSearches && isLoadingDeleteSavedSearches && (
            <Loading />
          )}
          {savedSearches.length > 0 &&
            !isLoadingDeleteSavedSearches &&
            !isLoadingGetSavedSearches && (
              <div className="row">
                <div className="col-sm">
                  <div className="table-responsive max-h-78vh">
                    <table className="table table-sm">
                      <thead className="sticky-header">
                        <tr>
                          {savedSearchesTableHeaders.map((header) => (
                            <th className="text-center" key={header.name}>
                              {header.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {savedSearches.map((sSearch: ISavedSearches) => (
                          <tr key={sSearch._id}>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.savedSearchTerm}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              <a
                                href={sSearch.savedSearchUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {sSearch.savedSearchUrl}
                              </a>
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.dateAdded
                                ? dayjs(sSearch.dateAdded).format("MM/DD/YY")
                                : ""}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.tunnelVisionAvg.price}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.tunnelVisionAvg.rating}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.tunnelVisionAvg.reviews}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.tunnelVisionAvg.roi}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.tunnelVisionAvg.tsfees}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {sSearch.tunnelVisionAvg.perSale}
                            </td>
                            <td className="align-middle text-center">
                              <span className="icon-wrapper">
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                  onClick={() => onDeleteIconClick(sSearch._id)}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          {savedSearches.length === 0 &&
            !isLoadingDeleteSavedSearches &&
            !isLoadingGetSavedSearches && (
              <div className="row">
                <div className="col-sm">
                  <div className="no-items text-center">
                    <h3>No Saved Searches yet!</h3>
                    <p>
                      Start saving your searches from the extension and they
                      will appear here!
                    </p>
                    <span className="no-items-icon-wrapper">
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SavedSearches;
