import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IBlacklistAddFormType, IBlacklistAddProp } from "./interfaces";
import "./styles.scss";

const AddBlacklist = ({ submitFromParent }: IBlacklistAddProp) => {
  const [showAddSection, setShowAddSection] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IBlacklistAddFormType>();

  useEffect(() => {
    toast.error(errors.blacklistItem?.message);
  }, [errors]);

  const onSubmitForm = (data: IBlacklistAddFormType) => {
    submitFromParent(data);
  };

  const onAddNewBrandClick = () => {
    setShowAddSection(!showAddSection);
  };

  return (
    <div className="add-blacklist row">
      <div className="col-8">
        {!showAddSection && (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => onAddNewBrandClick()}
          >
            Add new brands
          </button>
        )}
        {showAddSection && (
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="form-group dark-input">
              <label
                htmlFor="blacklist-input"
                className="blacklist-email-label"
              >
                Add Blacklist brands
              </label>
              <input
                type="text"
                className="form-control my-3"
                id="blacklist-input"
                aria-describedby="blacklist-input-help"
                placeholder="Enter blacklist company name/s"
                {...register("blacklistItem", {
                  required: "Name of the company is mandatory",
                })}
              />
              <small id="blacklist-input-help">
                If you want to add one company type: Nike
                <br />
                If you want to add multiple companies once type: Nike, Adidas,
                Rebook
              </small>
            </div>
            <br />
            <button type="submit" className="btn btn-primary me-3">
              Submit
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={() => onAddNewBrandClick()}
            >
              Cancel
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddBlacklist;
