import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import LogoBig from "assets/images/LogoPrincipal.png";
import passwordShow from "assets/images/icons/showPass.png";
import passwordHide from "assets/images/icons/hidePass.png";
import GoogleSignInButton from "components/GoogleSignInButton";
import { apiRoutes, cookiesNames, localStorageNames, pageRoutes } from "utils";
import { ILoginResponse } from "./interfaces";

const updates = [
  {
    text: "Amazon Order Fulfillment Automations",
    date: "Feb, 18th 2025",
  },
  {
    text: "Shopify & Ebay Inventory and Order Management",
    date: "May 9th, 2025",
  },
  {
    text: "Team Management and Accounting Systems",
    date: "May 26th, 2025",
  },
];

const LoginPage: React.FC = () => {
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const [showPassword, setShowPassword] = useState(false); 
  const togglePasswordVisibility = () => { setShowPassword(!showPassword); }; 

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>();

  const { mutate, isLoading } = useMutation(async (data: { email: string; password: string }) => {
    const response = await fetch(apiRoutes.LOGIN, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Login failed");
    }
    return response.json();
  });

  const onSubmit = async (data: { email: string; password: string }) => {
    mutate(data, {
      onSuccess: (user: ILoginResponse) => {
        toast.success("Login successful!");
        if (rememberMeChecked) {
          Cookies.set(cookiesNames.token, user.token, { expires: 30 });
        } else {
          Cookies.set(cookiesNames.token, user.token);
        }

        if (!user.survey) {
          user = {
            ...user,
            survey: { step1: [], step2: "", completed: false },
          };
        }

        localStorage.setItem(localStorageNames.userObj, JSON.stringify(user));

        setTimeout(() => {
          if (user.survey?.completed) {
            window.location.href = pageRoutes.PROFITABLE_PRODUCTS;
          } else {
            window.location.href = pageRoutes.SURVEY_STEP1;
          }
        }, 500);
      },
      onError: (error: any) => {
        toast.error(error.message || "Invalid credentials");
      },
    });
  };

  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      const errorMessage =
        errors[key as keyof typeof errors]?.message || "Invalid input";
      toast.error(String(errorMessage));
    });
  }, [errors]);

  const onRememberMeClick = () => {
    setRememberMeChecked(!rememberMeChecked);
    Cookies.set("rememberMe", JSON.stringify(!rememberMeChecked));
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Navigation */}
      <nav
        role="navigation"
        aria-label="Main Navigation"
        className="absolute top-0 left-0 right-0 p-4 z-10 flex items-center justify-between"
      >
        {/* Logo */}
        <Link to="/">
          <picture>
            <source srcSet={LogoBig} media="(min-width: 768px)" />
            <img src={LogoBig} alt="Unielogics Logo" className="logo-img" />
            <style>{`
              .logo-img {
                width: 12rem; /* Default size for larger screens */
              }
              @media (max-width: 768px) {
                .logo-img {
                  width: 12rem; /* Change size when LogoSmall is visible */
                }
              }
            `}</style>
          </picture>
        </Link>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex space-x-6">
          <li>
            <Link to="/" className="text-purple-300 hover:text-gray-200">
              Home
            </Link>
          </li>
          <li className="text-purple-500">/</li>
          <li>
            <Link to="/team" className="text-purple-300 hover:text-gray-200">
              Our Team
            </Link>
          </li>
          <li className="text-purple-500">/</li>
          <li>
            <Link to="/faq" className="text-purple-300 hover:text-gray-200">
              FAQ
            </Link>
          </li>
        </ul>

        {/* Burger Menu */}
        <button
          className="lg:hidden text-purple-300 hover:text-gray-200"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label="Toggle menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center">
          <button
            className="absolute top-4 right-4 text-purple-300 hover:text-gray-200"
            onClick={toggleMenu}
            aria-label="Close menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="flex flex-col items-center space-y-4">
            <li>
              <Link
                to="/"
                className="text-purple-300 hover:text-gray-200 text-xl"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/team"
                className="text-purple-300 hover:text-gray-200 text-xl"
              >
                Our Team
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                className="text-purple-300 hover:text-gray-200 text-xl"
              >
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      )}

      <div className="flex-grow grid grid-cols-1 md:grid-cols-2">
        {/* Left Section */}
        <div
          className="relative flex flex-col p-8 bg-[#0f0c29] text-white left-section"
          style={{ minHeight: "575px" }}
        >
          <div className="flex flex-col justify-center h-full">
            <div className="flex flex-col gap-2">
              <h1 className="font-bold bg-gradient-to-r from-purple-300 to-white bg-clip-text text-transparent text-[28px] lg:text-[44px]">
                Welcome Back, Expert!
              </h1>
              <p className="text-[26px] text-purple-200">
                Here are some of the new features publishing soon..
              </p>
              <div className="flex flex-col gap-2 mt-4">
                {updates.map(({ text, date }, index) => (
                  <div key={index} className="flex items-baseline">
                    <span className="text-purple-200 text-[16px] lg:text-[20px]">
                      - {text}
                    </span>
                    <span className="flex-grow border-dotted border-b border-purple-200 mx-4"></span>
                    <span className="text-purple-200 text-[16px] lg:text-[20px]">
                      {date}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative flex flex-col items-center justify-center p-4 sm:p-8 bg-black text-white">
          <div className="flex-1 flex flex-col items-center justify-center w-full max-w-sm gap-6 lg:mt-[90px] mb-6">
            {/* Login Form */}
            <h2 className="text-2xl font-bold mb-6">Log Back In</h2>
            <form
              className="space-y-6 w-full max-w-md"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="welcome@valuedcustomer.com"
                  className="form-control w-full px-3 py-2 rounded-md text-black"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">
                    {String(errors.email.message)}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <div className="flex relative items-center">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="Enter Your Password"
                    className="form-control w-full px-3 py-2 rounded-md text-black"
                    {...register("password", { required: "Password is required" })}
                  >
                  </input>
                  <img
                    src={showPassword ? passwordHide : passwordShow}
                    alt="Toggle Password Visibility"
                    onClick={togglePasswordVisibility}
                    className="absolute -right-10 top-1/2 transform -translate-y-1/2 w-8 h-8 cursor-pointer" 
                  />

                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm">
                    {String(errors.password.message)}
                  </p>
                )}
              </div>
              <div className="form-group flex items-center">
                <input
                  type="checkbox"
                  id="remember-me"
                  checked={rememberMeChecked}
                  onChange={onRememberMeClick}
                  className="mr-2"
                />
                <label htmlFor="remember-me" className="text-sm">
                  Remember me
                </label>
                <Link to={apiRoutes.FORGOT_PASSWORD} className="text-sm ml-auto">
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="btn w-full mt-3 bg-[#3B82F6] text-white text-lg font-medium px-16 hover:bg-blue-600 transition-colors"
              >
                {isLoading ? "Logging in..." : "Log In"}
              </button>
            </form>
            <div className="mt-6">
              <GoogleSignInButton />
            </div>
            <div className="mt-6 text-sm">
              Don't have an account?{" "}
              <Link
                to={{ pathname: "/register", search: "?plan=plan37" }}
                className="text-blue-400 hover:underline"
              >
                Register Now
              </Link>
            </div>

          </div>

          {/* Footer */}
          <footer className="flex flex-col items-center text-gray-400 gap-4 mt-8">
            <img src={Logo} alt="Unielogics Logo" className="w-24" />
            <div className="flex flex-row gap-4">
              <a
                href="https://docs.google.com/document/d/10apy8E_ith2dFrKrfeFMFCdTy14vx8lK/edit"
                className="hover:text-gray-300"
              >
                Terms Of Use
              </a>
              <span>|</span>
              <a
                href="https://docs.google.com/document/d/1ftEd8TCG2ujxKUzAndHBNO9aQnm4Tdx0J5jzLdYo68c/edit?tab=t.0"
                className="hover:text-gray-300"
              >
                Privacy Policy
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
