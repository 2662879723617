import React from "react";
import useShipmentPlanStore from "utils/zustandStore/useShipmentPlanStore";
import ReviewOrder from "./generateReq/ReviewOrder";
import ReviewAsn from "./generateReq/ReviewAsn";

export default function InfoplusPageContainer() {
  const { data } = useShipmentPlanStore();
  // // Extracting the data from Zustand store with optional chaining for safer access
  const shipmentPlanObj = data?.shipmentPlan;
  const shipmentPlanData = shipmentPlanObj?.shipmentPlan[0];
  const warehouseData = data?.warehouse;

  if (!shipmentPlanData || !warehouseData) {
    return (
      <div>
        Review Order is not available. Please select a shipment plan. To fix
        make sure you have selected a shipment plan and a warehouse. Most likely
        you have not updated your shipment plan after updating your warehouse.
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1>Infoplus Page Container</h1>

      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          color: "green",
        }}
      >
        {!warehouseData?.lobId ? (
          <span style={{ color: "red", paddingLeft: "10rem" }}>
            No warehouse data available. Please select a warehouse. Check before
            you continue - update shipmentplan after updating your warehouse.
            Else enter the values manually
          </span>
        ) : (
          <span>warehouse is present</span>
        )}

        {!shipmentPlanData?.orderDate ? (
          <span style={{ color: "red" }}>
            No shipment plan data available. Please initialize a shipment plan.
          </span>
        ) : (
          <span>shipment plan is present</span>
        )}

        {!shipmentPlanData?.products ? (
          <span style={{ color: "red" }}>
            No product data available in the shipment plan. Please add products
            to the shipment plan.
          </span>
        ) : (
          <span>product is present</span>
        )}
      </div>

      <ReviewOrder />
      <hr />
      <ReviewAsn />

      <a
        style={{
          display: "block",
          marginTop: "1rem",
          padding: "1rem",
          backgroundColor: "green",
          color: "white",
          textDecoration: "none",
          textAlign: "center",
          borderRadius: "4px",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
        href={`/infoplus/details/${shipmentPlanData._id}`}
      >
        Continue - and place your order + ASN
      </a>
    </div>
  );
}
