import axios from "axios";

import { apiRoutes } from "utils";
import {
  IConfirmShipmentPlanPayload,
  IConfirmShipmentPlanPaymentResponse,
} from "../interfaces";

export const postShipmentPlanFilesRequest = async (
  payload: IConfirmShipmentPlanPayload
) => {
  const { data } = await axios.post<IConfirmShipmentPlanPaymentResponse>(
    apiRoutes.POST_CONFIRM_PAYMENT_INTENT,
    payload
  );

  return data;
};
