import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";

import "./styles.scss";
import dayjs from "dayjs";
import { transformKeyIntoReadableText } from "utils";

const styles = StyleSheet.create({
  page: {
    padding: "25px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  productImage: {
    width: "200px",
    marginRight: "20px",
  },
  productTitle: {
    width: "350px",
  },
  document: {
    width: "800px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  splitSection: {
    display: "flex",
    flexDirection: "row",
  },
  sectionTitle: {
    marginBottom: "10px",
    textDecoration: "underline",
  },
  sectionDescriptionTitle: {
    color: "#999999",
    fontSize: "14px",
  },
  sectionDescriptionSubTitle: {
    color: "#757575",
    fontSize: "16px",
    textDecoration: "underline",
  },
  sectionText: {
    color: "#000000",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: "50px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableImage: {
    width: "25px",
    height: "25px",
    margin: "0 auto",
    marginTop: "5px",
    marginBottom: "5px",
  },
  center25Col: {
    marginTop: "10px",
  },
  marginB5: {
    marginBottom: "5px",
  },
});

const PdfContent = ({ productDetails }: any) => {
  const generateKeys = (data: any) => {
    return Array.from(new Set(data.flatMap((item: any) => Object.keys(item))));
  };
  const buyBoxKeys =
    productDetails[0]?.buyboxStatistics.length > 0
      ? generateKeys(productDetails[0]?.buyboxStatistics)
      : [];

  const variationsKeys =
    productDetails[0]?.variations.length > 0
      ? generateKeys(productDetails[0]?.variations)
      : [];

  const generateRows = ({ item, key }: any) => {
    if (key === "img") {
      return <Image style={styles.tableImage} src={item} />;
    }
    return <Text style={[styles.tableCell, styles.center25Col]}>{item}</Text>;
  };

  const generateHeaders = (key: string) => {
    return transformKeyIntoReadableText(key);
  };

  const extractFirstPartFromUrl = (url: string) => {
    const withoutProtocol = url.replace(/^(https?:\/\/)?(www\.)?/, "");
    const parts = withoutProtocol.split(".");

    if (parts.length >= 2) {
      return parts[0];
    }

    return withoutProtocol;
  };

  return (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.productImage} src={productDetails[0].imageUrl} />
          <Text style={styles.productTitle}>{productDetails[0].title}</Text>
        </View>
        <View style={styles.splitSection}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Calculator</Text>
            <Text style={styles.sectionDescriptionTitle}>
              Price:{" "}
              <Text style={styles.sectionText}>${productDetails[0].price}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Amazon Fees:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].amazonFees}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Pick & Pack:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].pickPack}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Total Fees:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].totalFees}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Break Even:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].breakEven}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Cost Per Item:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].costPerItem}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Misc Expenses:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].miscExpenses}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Total Cost Per Sale:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].totalCostPerSale}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Net Profit:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].netProfit}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Units:{" "}
              <Text style={styles.sectionText}>${productDetails[0].units}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Total Profit:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].totalProfit}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Net Sales Margin:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].netSalesMargin}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Net ROI:{" "}
              <Text style={styles.sectionText}>
                ${productDetails[0].netROI}
              </Text>
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Details</Text>
            <Text style={styles.sectionDescriptionTitle}>
              Asin:{" "}
              <Text style={styles.sectionText}>${productDetails[0].asin}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Buybox is FBA:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].buyboxIsFBA ? "Yes" : "No"}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              FBA:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].offerCountFBA}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              FBM:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].offerCountFBM}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Quantity Per Set:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].qtyPerSet}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Product group:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].productGroup}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Brand:{" "}
              <Text style={styles.sectionText}>{productDetails[0].brand}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Ian:{" "}
              <Text style={styles.sectionText}>{productDetails[0].ian}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Last Price Change:{" "}
              <Text style={styles.sectionText}>
                {dayjs(productDetails[0].lastPriceChange).format("MM/DD/YY")}
              </Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Weight:{" "}
              <Text style={styles.sectionText}>{productDetails[0].weight}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              W x H x L:{" "}
              <Text style={styles.sectionText}>{productDetails[0].WxHxL}</Text>
            </Text>
            <Text style={styles.sectionDescriptionTitle}>
              Hazmat:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].isHazmat ? "YES" : "NO"}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.splitSection}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Supplier Data</Text>
            {productDetails[0]?.supplier?._id ? (
              <View>
                <Text style={styles.sectionDescriptionTitle}>
                  Name:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.supplierName}
                  </Text>
                </Text>

                <Text style={[styles.sectionDescriptionTitle, styles.marginB5]}>
                  URL:{" "}
                  <Link src={productDetails[0]?.supplier.supplierLink}>
                    {extractFirstPartFromUrl(
                      productDetails[0]?.supplier.supplierLink
                    )}
                  </Link>{" "}
                </Text>

                <Text style={styles.sectionDescriptionSubTitle}>
                  Contact Person:
                </Text>
                <Text style={styles.sectionDescriptionTitle}>
                  Name:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.contactPerson?.name}
                  </Text>
                </Text>
                <Text style={styles.sectionDescriptionTitle}>
                  Email:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.contactPerson?.email}
                  </Text>
                </Text>

                <Text style={styles.sectionDescriptionTitle}>
                  Phone Number:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.contactPerson?.phoneNumber}
                  </Text>
                </Text>

                <Text style={[styles.sectionDescriptionTitle, styles.marginB5]}>
                  Extension Code:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.contactPerson?.extensionCode}
                  </Text>
                </Text>

                <Text style={styles.sectionDescriptionSubTitle}>Address:</Text>
                <Text style={styles.sectionDescriptionTitle}>
                  City:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.supplierAddress?.city}
                  </Text>
                </Text>

                <Text style={styles.sectionDescriptionTitle}>
                  State:
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.supplierAddress?.state}
                  </Text>{" "}
                </Text>

                <Text style={styles.sectionDescriptionTitle}>
                  Street:{" "}
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.supplierAddress?.street}
                  </Text>
                </Text>

                <Text style={styles.sectionDescriptionTitle}>
                  Zip Code:
                  <Text style={styles.sectionText}>
                    {productDetails[0]?.supplier.supplierAddress?.zipCode}
                  </Text>{" "}
                </Text>
              </View>
            ) : (
              <Text style={styles.sectionText}>
                "No supplier selected for this product"
              </Text>
            )}
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Notes:</Text>
            <Text style={styles.sectionDescriptionTitle}>
              Note:{" "}
              <Text style={styles.sectionText}>
                {productDetails[0].note
                  ? productDetails[0].note
                  : "No notes for this product"}
              </Text>
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Buy Box Statistics</Text>
          {productDetails[0]?.buyboxStatistics.length > 0 ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                {buyBoxKeys
                  .filter((key) => key !== "_id")
                  .map((key: any) => (
                    <View style={styles.tableCol} key={key}>
                      <Text style={styles.tableCell}>
                        {generateHeaders(key)}
                      </Text>
                    </View>
                  ))}
              </View>
              {productDetails[0]?.buyboxStatistics.map(
                (item: any, rowIndex: number) => (
                  <View style={styles.tableRow} key={rowIndex}>
                    {buyBoxKeys
                      .filter((key) => key !== "_id")
                      .map((key: any) => (
                        <View style={styles.tableCol} key={key}>
                          {generateRows({ item: item[key], key })}
                        </View>
                      ))}
                  </View>
                )
              )}
            </View>
          ) : (
            "There are no Buy Box Statistics for this product"
          )}
          <Text style={styles.sectionTitle}>Variations View</Text>
          {productDetails[0]?.variations.length > 0 ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                {variationsKeys
                  .filter((key) => key !== "_id")
                  .map((key: any) => (
                    <View style={styles.tableCol} key={key}>
                      <Text style={styles.tableCell}>
                        {generateHeaders(key)}
                      </Text>
                    </View>
                  ))}
              </View>
              {productDetails[0]?.variations.map(
                (item: any, rowIndex: number) => (
                  <View style={styles.tableRow} key={rowIndex}>
                    {variationsKeys
                      .filter((key) => key !== "_id")
                      .map((key: any) => (
                        <View style={styles.tableCol} key={key}>
                          {generateRows({ item: item[key], key })}
                        </View>
                      ))}
                  </View>
                )
              )}
            </View>
          ) : (
            "There are no Variations View for this product"
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PdfContent;
