import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { QUERY_KEYS, UserContext } from "utils";
import { getProducts, postDeleteProduct } from "./api";
import { Footer, Loading, PageTitle, SidebarMenu } from "components";
import { useDebounce } from "utils";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { ellipsis } from "utils";

import "./styles.scss";
import { bucketsTableHeaders } from "utils/configs";
import { IContextUser } from "common/interfaces";

const Products = () => {
  let user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const { data, isLoading: isLoadingGetProducts } = useQuery(
    QUERY_KEYS.GET_PRODUCTS,
    () => getProducts(email)
  );
  const [products, setProducts] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { mutate: deteleProductMutate, isLoading: isLoadingDeleteProducts } =
    useMutation(postDeleteProduct, {
      onSuccess: (successData) => {
        toast.success(`Successfully deleted product.`);
        setProducts(successData.products.productsDetails);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ?? "There was an error signing you in."
        );
      },
    });

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (data?.products.productsDetails) {
      setProducts(data?.products.productsDetails);
    }
  }, [data]);

  const search = () => {
    const productsToSearchTrough = data?.products.productsDetails || [];
    const results = productsToSearchTrough.filter(
      (product: any) =>
        //   // @ts-ignore
        product["price"].toLowerCase().includes(searchTerm.toLowerCase()) ||
        product["title"].toLowerCase().includes(searchTerm.toLowerCase()) ||
        product["asin"].toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProducts(results);
  };

  const onDeleteIconClick = (asin: string) => {
    deteleProductMutate({ email, asin });
  };

  return (
    <>
      <div className="content">
        <div className="container-fluid products">
          <div className="sidebar">
            <SidebarMenu
              active="buckets"
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
            />
          </div>
          <PageTitle pageTitle="Buckets" />
          {(isLoadingGetProducts || isLoadingDeleteProducts) && <Loading />}
          {products.length !== 0 &&
            !isLoadingGetProducts &&
            !isLoadingDeleteProducts && (
              <div className="row">
                <div className="col-sm">
                  <div className="table-responsive max-h-78vh">
                    <table className="table table-sm">
                      <thead className="sticky-header">
                        <tr>
                          {bucketsTableHeaders.map((header) => (
                            <th className="text-center" key={header.name}>
                              {header.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product: any) => (
                          <tr key={product.asin}>
                            <td className="align-middle text-center white-space-nowrap">
                              {product.date
                                ? dayjs(product.date).format("MM/DD/YY")
                                : ""}
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              <a
                                href={product.detailPageURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {product.asin}
                              </a>
                            </td>
                            <td className="align-middle text-center white-space-nowrap">
                              {product.salesRank}
                            </td>
                            <td
                              className="align-middle text-center white-space-nowrap"
                              style={{ width: "3%" }}
                            >
                              <img src={product.imageUrl} alt="product" />
                            </td>
                            <td
                              className="align-middle white-space-nowrap"
                              title={product.title}
                              style={{ width: "25%" }}
                            >
                              <a
                                href={product.detailPageURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {ellipsis(product.title, 120)}
                              </a>
                            </td>
                            <td
                              className="align-middle text-center"
                              style={{ width: "2%" }}
                            >
                              {product.offersCount}
                            </td>
                            <td className="align-middle text-center">
                              {product.price}
                            </td>
                            <td className="align-middle text-center"></td>
                            <td className="align-middle text-center"></td>
                            <td className="align-middle text-center"></td>
                            <td className="align-middle text-center"></td>
                            <td className="align-middle text-center">
                              <span className="icon-wrapper me-3">
                                <a
                                  href={product.detailPageURL}
                                  className="align-self-end"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                              <span className="icon-wrapper">
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                  onClick={() =>
                                    onDeleteIconClick(product.asin)
                                  }
                                ></i>
                              </span>
                            </td>
                            <td className="align-middle text-center">
                              <button
                                type="button"
                                className="btn btn-danger btn-dark-blue"
                              >
                                REPLENISH
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          {products.length === 0 &&
            !data?.products.productsDetails &&
            !isLoadingGetProducts &&
            !isLoadingDeleteProducts && (
              <div className="row p-5">
                <div className="d-flex justify-content-center align-items-center m-5">
                  <h3 className="text-center">
                    You don't have any product added to your account yet.
                    <br />
                    You can start adding products from amazon directly with our
                    extension.
                  </h3>
                </div>
              </div>
            )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Products;
