export const routesToTitles:Record<string, string> = {
    '/profitable-products': 'Profitable Products',
    '/saved-searches': 'Saved Searches',
    '/shipment-plans': 'Shpment Plans',
    '/suppliers': 'Suppliers',
    '/admin/warehouse-owners': 'Warehouses',
    '/tech-support': 'Support',
    // '/shipment-plan/:id': (shipmentPlan: any) => {
    //     return shipmentPlan[0]?.shipmentTitle || 'Default Shipment Plan Title';
    // }
}