import { IFeedbackUrls } from "pages/adminFeedbackUrl/interfaces";
import { useState } from "react";
import "./styles.scss";

const ViewFeedbackUrl = ({ feedbackUrls, saveEditedFeedbackUrlValue }: any) => {
  const [clickedFeedbackName, setClickedFeedbackLink] = useState({
    feedbackName: "",
    _id: "",
  });
  const [editedValue, setEditedValue] = useState("");

  const onEditClick = (feedbackName: string, _id: string) => {
    setClickedFeedbackLink({ feedbackName, _id });
    setEditedValue(feedbackName);
  };

  const onSaveClick = (_id: string) => {
    saveEditedFeedbackUrlValue({ _id, newValue: editedValue });
    setClickedFeedbackLink({ feedbackName: "", _id: "" });
    setEditedValue("");
  };

  return (
    <table className="blacklist-table-view table ">
      <thead>
        <tr>
          <th>#</th>
          <th>Feedback Name</th>
          <th>Feedback Url</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {feedbackUrls.map((url: IFeedbackUrls, i: number) => (
          <tr key={url.name}>
            <td>{i + 1}</td>
            <td>
              <span>{url.name}</span>
            </td>
            <td>
              <span
                className={`${
                  clickedFeedbackName._id === url._id ? "hide" : "show"
                }`}
              >
                {url.link}
              </span>
              <input
                type="text"
                className={`${
                  clickedFeedbackName._id === url._id ? "show" : "hide"
                }`}
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
              />
            </td>

            <td className="">
              {clickedFeedbackName._id !== url._id ? (
                <button
                  className="btn btn-success width-100 me-3"
                  onClick={() => onEditClick(url.link, url._id)}
                >
                  Edit
                </button>
              ) : (
                <button
                  className="btn btn-success width-100 me-3"
                  onClick={() => onSaveClick(url._id)}
                >
                  Save
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ViewFeedbackUrl;
