import "./styles.scss";

import { Message } from "./interfaces";
import { SidebarMenu, PageTitle } from "components";
import { useState, useEffect, useRef } from "react";
import botAvatar from "../../assets/images/botAvatar.png";
import userAvatar from "../../assets/images/userAvatar.png";

const TechSupport = () => {
  const [messages, setMessages] = useState<Message[]>([
    { sender: "bot", text: "Please send me your question below." },
  ]);
  const [input, setInput] = useState<string>("");
  const [botResponse, setBotResponse] = useState<string>("");

  const chatDisplayRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (!input.trim()) return;

    const userMessage: Message = { sender: "user", text: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setInput("");

    generateBotResponse(input);
  };

  const generateBotResponse = (userText: string) => {
    const botResponse: Message = {
      sender: "bot",
      text: getBotReply(userText),
    };
    setMessages((prevMessages) => [...prevMessages, botResponse]);
  };

  const getBotReply = (userText: string): string => {
    //add bot api here
    if (userText.toLowerCase().includes("hello"))
      return "Hello! How can I assist you today?";
    if (userText.toLowerCase().includes("help"))
      return "I’m here to help! What do you need assistance with?";
    if (userText.toLowerCase().includes("warehouse"))
      return "To manage the warehouse, please use the sidebar, navigate to Logistics/Shipment Plans, and go to the step 2 of shipment plan and click 'Find A Warehouse' button.";
    if (userText.toLowerCase().includes("profit"))
      return "To view profitable product,  please use the sidebar, navigate to Products/Profitable, and click on a product to view the data chart and sale records.";
    if (
      userText.toLowerCase().includes("payment") &&
      !userText.toLowerCase().includes("status")
    )
      return "To make payment, please use the sidebar, navigate to Logistics/Shipment Plans, and go to the step 2 of shipment plan and click 'Make Payment' button.";
    else if (userText.toLowerCase().includes("payment status"))
      return "To see payment status, please use the sidebar, navigate to Logistics/Shipment Plans, and go to the step 4 of shipment plan and click 'Payment Status' button.";
    if (
      userText.toLowerCase().includes("profile") ||
      userText.toLowerCase().includes("password") ||
      userText.toLowerCase().includes("number") ||
      userText.toLowerCase().includes("name")
    )
      return "To change your profile infos, please use the sidebar, navigate to Profile, and you can change the profile details there.";
    if (userText.toLowerCase().includes("suppliers"))
      return "To see a list of suppliers, please use the sidebar, navigate to Suppliers, and you can view the list of suppliers. Click on View Location for each supplier to see the map.";
    if (
      userText.toLowerCase().includes("blacklist") ||
      userText.toLowerCase().includes("users") ||
      userText.toLowerCase().includes("feedback") ||
      userText.toLowerCase().includes("warehouse owner")
    )
      return "Please navitate to Admin Panel in the sidebar to manage these features.";
    if (userText.toLowerCase().includes("resupply"))
      return "To resupply a product, please use the sidebar, navigate to Products/Buckets, and click on 'REPLENISH' to resupply the product.";
    if (
      userText.toLowerCase().includes("course") ||
      userText.toLowerCase().includes("tutorial")
    )
      return "To learn how to use this platform, please visit the Academy tab in the sidebar.";
    return "Sorry, I didn’t understand that. Can you please clarify?";
  };

  const getAvatar = (sender: string) =>
    sender === "bot" ? botAvatar : userAvatar;

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="content">
      <div className="container-fluid tech-support">
        <div className="sidebar">
          <SidebarMenu active="shipment-plans" sidebarOnly={true} />
        </div>
      </div>

      <div className="chatbot-container">
        <div className="chat-title">Need Assistance? Send Me Your Question</div>
        <div className="chat-display">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              <img
                src={getAvatar(message.sender)}
                alt="avatar"
                className="avatar"
              />
              <span>{message.text}</span>
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className="chat-input"
          />
          <button onClick={handleSendMessage} className="send-button">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default TechSupport;
