import React from "react";
import { SupplierDetailsProps } from "../interfaces";

const SupplierDetails: React.FC<SupplierDetailsProps> = ({ supplier }) => {


  const { supplierName, supplierAddress } = supplier;

  if (!supplierAddress) {
    return <p>Supplier address not available</p>;
  }

  return (
    <div className="wm-supplier-address-wrapper">
      <h1>FROM</h1>
      <div className="wm-supplier-address wm-address-box">

        <h2>Supplier</h2>
        <div className="d-flex flex-column fixed-length-container">
          <span>Name: {supplierName}</span>
          <span>Street: {supplierAddress.street}</span>
          <span>City: {supplierAddress.city}</span>
          <span>State: {supplierAddress.state}</span>
          <span>Zipcode: {supplierAddress.zipCode}</span>
        </div>
      </div>

    </div>
  );
};

export default SupplierDetails;
