import React, { useState, CSSProperties } from "react";

interface Props {
  data: any;
  saveChanges: (updatedData: any) => Promise<void>;
}

const EditableFormTable: React.FC<Props> = ({ data, saveChanges }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentData, setCurrentData] = useState<any>(data);
  const [error, setError] = useState<string | null>(null);

  const toggleEdit = async () => {
    if (isEditing) {
      try {
        await saveChanges(currentData);
        setError(null);
      } catch (err) {
        setError("Failed to save changes. Please try again.");
      }
    }
    setIsEditing(!isEditing);
  };

  const cancelEdit = () => {
    setCurrentData(data);
    setIsEditing(false);
  };

  const handleInputChange = (path: string[], value: string) => {
    const newData = { ...currentData };
    let temp = newData;
    path.slice(0, -1).forEach((key) => (temp = temp[key]));
    temp[path[path.length - 1]] = value;
    setCurrentData(newData);
  };

  const addField = (path: string[]) => {
    const key = prompt("Enter the key name for the new field:");
    if (!key) return;
    const value = prompt("Enter the value for the new field:");
    const newData = { ...currentData };
    let temp = newData;
    path.forEach((key) => (temp = temp[key]));
    temp[key] = value || "";
    setCurrentData(newData);
  };

  const addArrayItem = (path: string[]) => {
    const newData = { ...currentData };
    let temp = newData;
    path.forEach((key) => (temp = temp[key]));
    temp.push({});
    setCurrentData(newData);
  };

  const removeField = (path: string[], field: string) => {
    try {
      const newData = JSON.parse(JSON.stringify(currentData)); // Deep copy
      let temp = newData;
      path.forEach((key) => {
        if (!temp[key]) throw new Error(`Invalid path: ${key}`);
        temp = temp[key];
      });
      delete temp[field];
      setCurrentData(newData);
    } catch (error) {
      console.error("Error removing field:", error);
    }
  };

  const removeArrayItem = (path: string[], index: number) => {
    try {
      const newData = JSON.parse(JSON.stringify(currentData)); // Deep copy
      let temp = newData;
      path.forEach((key) => {
        if (!temp[key]) throw new Error(`Invalid path: ${key}`);
        temp = temp[key];
      });
      if (!Array.isArray(temp)) throw new Error("Target is not an array");
      temp.splice(index, 1);
      setCurrentData(newData);
    } catch (error) {
      console.error("Error removing array item:", error);
    }
  };

  const renderData = (item: any, path: string[] = []) => {
    if (Array.isArray(item)) {
      return (
        <div>
          <div style={styles.sectionHeader}>
            {path[path.length - 1] || "List"}
          </div>
          <table style={styles.table}>
            <tbody>
              {item.map((arrayItem, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>.</td>
                  {/* <td style={styles.tableCell}>{index + 1}</td> */}
                  <td style={styles.tableCell}>
                    {renderData(arrayItem, [...path, String(index + 1)])}
                  </td>
                  {isEditing && (
                    <td>
                      <button
                        onClick={() => removeArrayItem(path, index)}
                        style={styles.deleteButton}
                      >
                        Remove
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              {isEditing && (
                <tr>
                  <td colSpan={3}>
                    <button
                      onClick={() => addArrayItem(path)}
                      style={styles.addButton}
                    >
                      Add Item
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    }

    if (typeof item === "object" && item !== null) {
      return (
        <div>
          <div style={styles.sectionHeader}>
            {path[path.length - 1] || "Data"}
          </div>
          <table style={styles.table}>
            <tbody>
              {Object.entries(item).map(([key, value], index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{key}</td>
                  <td style={styles.tableCell}>
                    {renderData(value, [...path, key])}
                  </td>
                  {isEditing && (
                    <td>
                      <button
                        onClick={() => removeField(path, key)}
                        style={styles.deleteButton}
                      >
                        Remove
                      </button>
                    </td>
                  )}
                </tr>
              ))}
              {isEditing && (
                <tr>
                  <td colSpan={3}>
                    <button
                      onClick={() => addField(path)}
                      style={styles.addButton}
                    >
                      Add Field
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    }

    return isEditing ? (
      <input
        type="text"
        value={String(item)}
        onChange={(e) => handleInputChange(path, e.target.value)}
        style={styles.input}
      />
    ) : (
      <span>{String(item)}</span>
    );
  };

  return (
    <div style={styles.container}>
      {error && <div style={styles.error}>{error}</div>}
      <div style={styles.buttonGroup}>
        <button
          onClick={toggleEdit}
          style={isEditing ? styles.saveButton : styles.editButton}
        >
          {isEditing ? "Save" : "Edit"}
        </button>
        {isEditing && (
          <button onClick={cancelEdit} style={styles.cancelButton}>
            Cancel
          </button>
        )}
      </div>
      {renderData(currentData)}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    padding: "10px",
    // border: "2px solid #4caf50",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    margin: "2px 0",
    border: "1px solid #ddd",
  },
  tableCell: {
    padding: "4px",
    borderBottom: "1px solid #ddd",
  },
  buttonGroup: {
    marginBottom: "10px",
  },
  editButton: {
    padding: "4px 8px",
    backgroundColor: "#4caf50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  saveButton: {
    padding: "4px 8px",
    backgroundColor: "#2196F3",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "4px 8px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  input: {
    padding: "1px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "100%",
    backgroundColor: "#black",
    color: "white",
  },
  error: {
    color: "#f44336",
    marginBottom: "10px",
  },
  sectionHeader: {
    cursor: "pointer",
    padding: "1px",
    minWidth: "420px",
    backgroundColor: "#7542f5",
    borderBottom: "1px solid #ddd",
  },
};

export default EditableFormTable;
