import { useEffect, useState } from "react";

export const getQueryParameterByName = (name: string) => {
  const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const plans = {
  PLAN_37: "plan37",
  PLAN_287: "plan287",
};

export const users = {
  ADMIN_USER: "franco@peri-mail.com",
};

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const ellipsis = (text: string, maxValue: number) => {
  return text.substring(0, maxValue - 3) + "...";
};

export const transformKeyIntoReadableText = (key: string) => {
  const words = key.split(/(?=[A-Z])/);

  const transformedString = words
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");

  return transformedString;
};

export const uuidv4 = () =>
  "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

export const getFormattedAddress = (address: any) => {
  if (!address) {
    return {
      shipToName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    };
  }
  const [shipToName, addressLine, city, state, zipCode, country] =
    address.split(", ");
  return {
    shipToName: shipToName || "",
    address: addressLine || "",
    city: city || "",
    state: state || "",
    zipCode: zipCode || "",
    country: country || "",
  };
};

export const reformatAddress = (shipToName: string, address: string, city: string, state: string, zipCode: string, country: string) => {
  return `${shipToName}, ${address}, ${city}, ${state}, ${zipCode}, ${country}`;
};