export const initializeAsnBody = ({ whData, spData }: any) => {
    const asnBody = {
        // Required fields
        lobId: whData?.lobId,
        poNo: spData?.orderNo || "defaultPoNo",
        vendorId: whData?.vendorId,
        warehouseId: whData?.warehouseId,
        orderDate: spData?.orderDate,
        type: "Standard",

        // Line items mapping
        lineItems: spData?.products?.map((item: any, i: number) => ({
            id: i,
            lobId: whData?.lobId,
            warehouseId: whData?.warehouseId,
            sku: item?.sku || "",
            orderQuantity: item?.quantity || 0,
            requestedDeliveryDate: spData?.orderDate,
            unitCode: "EA",
            wrapCode: "STD",
            unitsPerWrap: 1,
            chargeFreight: "N",
            maxOvers: 0,
            maxUnders: 0,
            distDate: spData?.orderDate,
            vendorId: whData?.vendorId,
            customFields: {
                FnSku: item?.fnsku,
            }
        })) || [],

        // Optional fields
        billingName: "",
        billingAttention: "",
        billingStreet1: "",
        billingStreet2: "",
        billingCity: "",
        billingState: "",
        billingZipCode: "",
        billingPhone: "",
        shipToName: "",
        shipToAddress: "",
        shipToStreet1: "",
        shipToStreet2: "",
        shipToCity: "",
        shipToState: "",
        shipToZipCode: "",
        shipToPhone: "",
        corporateName: "",
        corporateAttention: "",
        corporateStreet1: "",
        corporateStreet2: "",
        corporateCity: "",
        corporateState: "",
        corporateZipCode: "",
        status: "New",
        customFields: {}
    };

    return asnBody;
};

export const initializeAsnBodyEssentials = ({ whData, spData }: any) => {
    const asnBody = {
        lobId: whData?.lobId,
        poNo: spData?.orderNo,
        vendorId: whData?.vendorId,
        warehouseId: whData?.warehouseId,
        orderDate: spData?.orderDate,
        type: "Normal",
        lineItems: spData?.products?.map((item: any, i: number) => ({
            lobId: whData?.lobId,
            warehouseId: whData?.warehouseId,
            sku: item?.sku,
            orderQuantity: item?.quantity,
            requestedDeliveryDate: spData?.orderDate,
            unitCode: "EA",
            wrapCode: "STD",
            unitsPerWrap: 1,
            chargeFreight: "N",
            maxOvers: 0,
            maxUnders: 0,
            distDate: spData?.orderDate,
            vendorId: whData?.vendorId
        })) || []
    };

    return asnBody;
};
