import { useMutation, useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";

import { Footer, Loading, SidebarMenu, ViewFeedbackUrl } from "components";
import { QUERY_KEYS, UserContext } from "utils";

import "./styles.scss";
import { getFeedbackUrls, updateFeedbackUrlRequest } from "./api";
import {
  IEditFeedback,
  IEditFeedbackResponse,
  IFeedbackUrls,
} from "./interfaces";
import { toast } from "react-toastify";
import { users } from "utils/globals";
import { IContextUser } from "common/interfaces";

const AdminFeedbackUrl = () => {
  const [feedbackUrls, setFeedbackUrls] = useState<IFeedbackUrls[] | any>([]);
  const {
    data,
    isLoading: isLoadingGetFeedbackUrls,
    refetch: getFeedbackTrigger,
  } = useQuery(QUERY_KEYS.GET_BLACKLIST, () => getFeedbackUrls(), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  let user: IContextUser = useContext(UserContext)!;

  useEffect(() => {
    if (user.email !== users.ADMIN_USER) {
      window.location.href = "/";
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      setFeedbackUrls(data?.items);
    }
  }, [data]);

  const { mutate: updateFeedbackUrl, isLoading: isLoadingUpdateFeedbackUrl } =
    useMutation(updateFeedbackUrlRequest, {
      onSuccess: (data: IEditFeedbackResponse) => {
        if (data.status === "success") {
          getFeedbackTrigger();
          toast.success(
            `Successfully edited feedback url: ${data.editedFLink.name}`
          );
        } else {
          toast.error(
            "There was an error whilte trying to update the feedback url."
          );
        }
        return;
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error whilte trying to update the feedback url."
        );
        return;
      },
    });

  const saveEditedFeedbackUrlValue = ({ _id, newValue }: IEditFeedback) => {
    updateFeedbackUrl({ _id, newValue });
  };

  return (
    <>
      <div className="content">
        <div className="container admin-blacklist">
          <div className="row">
            <div className="navbar-container">
              <SidebarMenu active="feedback" sidebarOnly={true} />
            </div>

            <div className="navigation mb-md-5"></div>
            {isLoadingGetFeedbackUrls || isLoadingUpdateFeedbackUrl ? (
              <Loading />
            ) : (
              <>
                <ViewFeedbackUrl
                  feedbackUrls={feedbackUrls}
                  saveEditedFeedbackUrlValue={saveEditedFeedbackUrlValue}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminFeedbackUrl;
