import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { postGoogleLoginRequest } from "./api";
import { toast } from "react-toastify";
import { onLoginSuccess } from "./handlers";

const GoogleSignInButton = () => {
  const handleOnSuccess = async (credentialResponse: CredentialResponse) => {
    // Verify credential response
    const { clientId, credential } = credentialResponse;
    if (!clientId || !credential) {
      return;
    }

    // Redirect login to home page
    try {
      const user = await postGoogleLoginRequest(clientId, credential);
      if (!user) {
        throw new Error("User not found.");
      }
      onLoginSuccess(user);
    } catch (error: any) {
      toast.error("There was an error signing you in with Google.");
    }
  };
  const handleOnError = () => {
    toast.error("There was an error signing you in with Google.");
  };
  return <GoogleLogin onSuccess={handleOnSuccess} onError={handleOnError} />;
};

export default GoogleSignInButton;
