// Team Members Images: 
// import John from "assets/images/team/John.webp";
import Jefferson from "assets/images/team/Jefferson.webp";
import David from "assets/images/team/David.webp";
import Chandra from "assets/images/team/Chandra.webp";
import Henrry from "assets/images/team/HenryLam.webp";
import Jie from "assets/images/team/Jie.webp";
import Raju from "assets/images/team/Raju.webp";
import Hongzhong from "assets/images/team/Hongzhong.webp";
import Alwaz from "assets/images/team/Alwaz.webp";



import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import LogoBig from "assets/images/LogoPrincipal.png";
 
interface TeamMember {
  name: string;
  position: string;
  experience: string;
  description: string;
  image: string;
}
 
const teamMembers: TeamMember[] = [
  // {
  //   name: "John Franco",
  //   position: "CEO & Founder",
  //   experience: "+7 years of experience in logistics and e-commerce.",
  //   description:
  //     "Líder visionario dedicado a revolucionar la logística del comercio electrónico mediante estrategias innovadoras y tecnologías de vanguardia que optimizan procesos y redefinen la experiencia del cliente.",
  //   image: John,
  // },
  {
    name: "Jefferson Gudiel",
    position: "Lead Front-End Developer",
    experience:
      "+7 years of experience as a computer science engineer in software development.",
    description:
      "Ingeniero de desarrollo de software y aplicaciones web, especializado en crear soluciones escalables, robustas y orientadas al usuario, con enfoque en la calidad, innovación y adaptabilidad en entornos cambiantes.",
    image: Jefferson,
  },
  {
    name: "David",
    position: "QA Engineer.",
    experience: "+5 years of experience as a software engineer",
    description:
      "Specializing in the development of user-centric applications, using modern technologies to create scalable and high-performance solutions.",
    image: David,
  },
  {
    name: "Chandra Bachhu",
    position: "Back-End Developer.",
    experience: "+1 year of experience as a Full-Stack developer.",
    description:
      "Experience in developing innovative applications that offer efficient, scalable and high-impact solutions, focused on optimizing resources and improving the user experience.",
    image: Chandra,
  },
  {
    name: "Henry Lam",
    position: "Front-End Developer",
    experience:
      "+4 years of experience as a Software Engineer in full stack web development",
    description:
      "Specializing in creating scalable and high-performance applications, with a focus on quality, innovation and meeting objectives.",
    image: Henrry,
  },
  {
    name: "Jie cheng Yao",
    position: "Full-Stack Developer",
    experience: "+5 experience in Full-Stack development",
    description:
      "Specialized in developing scalable web applications, optimized for high performance and adapted to grow with the client's needs, using modern architectures and cloud technologies.",
    image: Jie,
  },
  {
    name: "Hongzhong Hu",
    position: "Front-End Developer",
    experience: "+1 experience in Full-Stack development",
    description:
      "Specializing in the development of geospatial and map-based applications, with experience in advanced mapping tools and a rigorous approach to code quality control to ensure accurate and scalable solutions.",
    image: Hongzhong,
  },
  {
    name: "Raju Ram Dadarwal",
    position: "Data Engineer.",
    experience: "+12 years of experience as a software engineer",
    description:
      "Ingeniero de software especializado en desarrollar aplicaciones escalables y de alto rendimiento, con enfoque en soluciones eficientes, calidad y adaptabilidad a entornos cambiantes.",
    image: Raju,
  },
  {
    name: "Alwaz",
    position: "Full-Stack Developer",
    experience: "+3 experience in Full-Stack development",
    description:
      "Specialized in developing scalable web applications, optimized for high performance and adapted to grow with the client's needs, using modern architectures and cloud technologies.",
    image: Alwaz,
  },
];
 
const OurTeam: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
 
  const toggleMenu = () => setIsMobileMenuOpen((prev) => !prev);
 
  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {/* Header */}
      <header
        className="absolute top-0 left-0 right-0 p-4 z-10 flex items-center justify-between"
        role="navigation"
        aria-label="Main Navigation">
        {/* Logo */}
        <Link to="/">
          <picture>
            <source srcSet={LogoBig} media="(min-width: 768px)" />
            <img src={Logo} alt="Unielogics Logo" className="logo-img" />
            <style>{`
          .logo-img {
            width: 12rem; /* Default size for larger screens */
          }
          @media (max-width: 768px) {
            .logo-img {
              width: 12rem; /* Adjust size for smaller screens */
            }
          }
        `}</style>
          </picture>
        </Link>

        {/* Desktop Navigation */}
        <ul className="hidden sm:flex space-x-6">
          <li>
            <a href="/" className="text-purple-300 hover:text-gray-200">
              Home
            </a>
          </li>
          <li className="text-purple-500">/</li>
          <li>
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
              className="text-purple-300 hover:text-gray-200">
              Warehouse Owners
            </Link>
          </li>
          <li className="text-purple-500">/</li>
          <li>
            <Link to="/faq" className="text-purple-300 hover:text-gray-200">
              FAQ
            </Link>
          </li>
        </ul>

        {/* Burger Menu */}
        <button
          className="sm:hidden text-purple-300 hover:text-gray-200"
          onClick={toggleMenu}
          aria-label="Toggle menu">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center">
          <button
            className="absolute top-4 right-4 text-purple-300 hover:text-gray-200"
            onClick={toggleMenu}
            aria-label="Close menu">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <nav>
            <ul className="space-y-4 text-center">
              <li>
                <a
                  href="/"
                  className="text-lg text-purple-300 hover:text-gray-200">
                  Home
                </a>
              </li>
              <li>
                <Link
                  to="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
                  className="text-lg text-purple-300 hover:text-gray-200">
                  Warehouse Owners
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className="text-lg text-purple-300 hover:text-gray-200">
                  FAQ
                </Link>
              </li>
            </ul>
            <a href="/">
              <img
                src={LogoBig}
                alt="Unielogics Logo"
                className="w-52 h-22 mt-8"
              />
            </a>
          </nav>
        </div>
      )}

      {/* Hero Section */}
      <main className="px-4 py-8 md:py-16 md:px-6 lg:px-8 mt-16 lg:mt-0">
        <section className="mx-auto max-w-4xl text-center">
          <h2 className="mb-4 text-xl sm:text-2xl font-light md:text-3xl">
            More Than Just A Team
          </h2>
          <h1 className="mb-6 sm:mb-8 text-3xl sm:text-4xl font-bold md:text-5xl lg:text-6xl">
            A Powerhouse Of Innovators
          </h1>
          <p className="mb-8 sm:mb-12 text-base sm:text-lg leading-relaxed text-gray-300">
            At Unielogics, our team is united by a shared passion for
            innovation, logistics, and empowering eCommerce businesses to
            thrive. Each member brings unique expertise, creativity, and
            dedication to building solutions that revolutionize the way
            eCommerce operates.
          </p>
        </section>

        {/* Team Grid */}
        <section className="mt-12 sm:mt-16">
          {/* Team Members */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-lg bg-gray-900">
                {/* Image */}
                <img
                  src={member.image}
                  alt={member.name}
                  className="aspect-[3/4] w-full object-cover"
                />
                {/* Content */}
                <div className="p-4">
                  <h3 className="text-lg sm:text-xl font-bold mb-1">
                    {member.name}
                  </h3>
                  <p className="text-gray-400 mb-3">{member.position}</p>
                  <p className="text-gray-300 text-sm">{member.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Separator Line */}
          <div className="mt-16 sm:mt-24 border-t border-gray-800"></div>
          <div className="mt-6">
            <h4 className="text-center text-4xl font-semibold text-gray-100">
              Join The Movement
            </h4>
            <p className="text-center text-2xl text-gray-400 mt-2">
              Interested in joining a team that's reshaping eCommerce logistics?
              Explore our careers page and be part of something revolutionary.
            </p>
            <div className="flex justify-center mt-6">
              <a
                href="/careers"
                className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300">
                Explore Careers
              </a>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer>
        <div className="max-w-4xl mx-auto py-6 sm:py-8 px-4 flex flex-col items-center gap-4 sm:gap-6">
          <a href="/">
            <img
              src={LogoBig}
              alt="Unielogics Logo"
              className="h-12 sm:h-16 w-auto"
            />
          </a>
          <div className="flex items-center gap-4 text-sm text-gray-500">
            <a
              href="https://docs.google.com/document/d/10apy8E_ith2dFrKrfeFMFCdTy14vx8lK/edit"
              className="hover:text-gray-400">
              Terms Of Use
            </a>
            <span>|</span>
            <a
              href="https://docs.google.com/document/d/1ftEd8TCG2ujxKUzAndHBNO9aQnm4Tdx0J5jzLdYo68c/edit?tab=t.0"
              className="hover:text-gray-400">
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
 
export default OurTeam;
