import axios from "axios";
import { apiRoutes } from "utils";
import {
  IDeleteSavedSearchesResponse,
  IGetSavedSearchesResponse,
  ISavedSearchesDeleteRequest,
} from "../interfaces";

export const getSavedSearchesRequest = async (email: string) => {
  const { data } = await axios.get<IGetSavedSearchesResponse>(
    `${apiRoutes.GET_SAVED_SEARCHES}?email=${email}`
  );
  return data;
};

export const deleteSavedSearchesRequest = async ({
  email,
  _id,
}: ISavedSearchesDeleteRequest) => {
  const response = await axios.delete<IDeleteSavedSearchesResponse>(
    apiRoutes.DELETE_SAVED_SEARCHES,
    {
      data: { email, _id },
    }
  );
  return response.data;
};
