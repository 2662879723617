export const QUERY_KEYS = {
  GET_PROFILE: "GET_PROFILE",
  GET_SIMPLE_PROFILE: "GET_SIMPLE_PROFILE",
  POST_CHECKOUT: "POST_CHECKOUT",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCT_KEEPA: "GET_PRODUCT_KEEPA",
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_BLACKLIST: "GET_BLACKLIST",
  GET_SEARCH: "GET_SEARCH",
  GET_FOLDERS: "GET_FOLDERS",
  GET_SUPPLIERS: "GET_SUPPLIERS",
  GET_PROFITABLE_PRODUCT_DETAILS: "GET_PROFITABLE_PRODUCT_DETAILS",
  GET_SAVED_SEARCHES: "GET_SAVED_SEARCHES",
  GET_WAREHOUSE_OWNERS: "GET_WAREHOUSE_OWNERS",
  GET_SHIPMENT_PLAN: "GET_SHIPMENT_PLAN",
};
