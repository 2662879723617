export
    const initializeOrderBody = ({ whData, spData }: any) => {
        const orderBody = {
            // Required fields first
            lobId: whData?.lobId,
            warehouseId: whData?.warehouseId,
            orderDate: spData?.orderDate,
            mediaCode: "defaultMediaCode",
            legacyRestrictionType: "defaultRestrictionType",
            lineItems:
                spData?.products?.map((item: any, i: number) => ({
                    id: i,
                    lobId: whData?.lobId,
                    sku: "",
                    orderedQty: 0,
                    poNoId: 0,
                    allowedQty: 0,
                    shippedQty: 0,
                    backorderQty: 0,
                    revDate: "",
                    chargeCode: "",
                    distributionCode: "",
                    upc: "",
                    vendorSKU: "",
                    orderSourceSKU: "",
                    unitCost: 0.0,
                    unitSell: 0.0,
                    extendedCost: 0.0,
                    extendedSell: 0.0,
                    ncExtendedSell: 0.0,
                    itemWeight: 0.0,
                    productionLot: "",
                    weightPerWrap: 0.0,
                    sector: "",
                    itemAccountCodeId: 0,
                    itemLegacyLowStockContactId: 0,
                    itemMajorGroupId: 0,
                    itemSubGroupId: 0,
                    itemProductCodeId: 0,
                    itemSummaryCodeId: 0,
                    customFields: {},
                })) || [],

            // Optional fields after
            orderNo: spData?.orderNo,
            customerOrderNo: spData?.receiptNo,
            customerNo: "Add Customer number!!",
            useOrderNoRoot: "Add this value!!",
            firstShipDate: "",
            lastShipDate: "",
            deliverOnDate: "",
            needByDate: "",
            carrierId: 0,
            serviceTypeId: "",
            shipVia: "",
            alcoholOrderType: "",
            alternateUsage: "",
            authorizationAmount: 0.0,
            authorizedBy: "",
            balanceDue: 0.0,
            batchNo: "",
            billToAttention: "",
            billToCompany: "",
            billToStreet: "",
            billToStreet2: "",
            billToStreet3: "",
            billToCity: "",
            billToState: "",
            billToZip: "",
            billToCountry: "",
            billToPhone: "",
            billToEmail: "",
            numberOfCartons: 0,
            numberOfPallets: 0,
            completionStatus: "",
            parcelAccountId: 0,
            costCenter: "",
            createDate: "",
            customerPONo: "",
            distributionChannel: "",
            distributionCharges: 0.0,
            division: 0,
            enteredBy: "",
            estimatedWeightLbs: 0.0,
            freight: 0.0,
            giftMessage: "",
            groupOrderId: 0,
            holdCode: "",
            integrationPartnerId: 0,
            numberOfLineItems: 0,
            modifyDate: "",
            omsOrderNo: 0,
            omsCustomerId: 0,
            orderLoadProgramId: 0,
            orderMessage: "",
            orderReason: 0,
            orderSourceId: 0,
            packingSlipTemplateId: 0,
            orderConfirmationEmailTemplateId: 0,
            shipmentConfirmationEmailTemplateId: 0,
            priceLevel: "",
            priorityCode: 0,
            fulfillmentProcessId: 0,
            shipBy: "",
            shipCode: "",
            shipDate: "",
            shipToAttention: "",
            shipToCompany: "",
            shipToStreet: "",
            shipToStreet2: "",
            shipToStreet3: "",
            shipToCity: "",
            shipToState: "",
            shipToZip: "",
            shipToCountry: "",
            shipToPhone: "",
            shipToEmail: "",
            shippingCharge: 0.0,
            status: "",
            stopBackOrders: "",
            subtotal: 0.0,
            tax: 0.0,
            total: 0.0,
            totalPaid: 0.0,
            totalQty: 0,
            weightLbs: 0.0,
            extraOrderData: [
                {
                    sequence: "",
                    category: "",
                    code: "",
                    value: "",
                    customFields: {},
                },
            ],
            extraLineItemData: [
                {
                    sku: "",
                    category: "",
                    code: "",
                    value: "",
                    customFields: {},
                },
            ],
            externalShippingSystemId: 0,
            customFields: {},
        };
        return orderBody;
    };
let asinMiceProfile = localStorage.getItem("asinmice-obj");
if (!asinMiceProfile) {
    asinMiceProfile = JSON.stringify({ customerID: "defaultCustomerID" });
}
const parsedProfile = JSON.parse(asinMiceProfile);
const { customerID } = parsedProfile;
export const initializeOrderBodyEssentials = ({ whData, spData }: any) => {
    const orderBody = {
        lobId: whData?.lobId,
        warehouseId: whData?.warehouseId,
        orderDate: spData?.orderDate,
        mediaCode: "Written",
        legacyRestrictionType: "H/O All",
        customerNo: customerID,
        customerOrderNo: spData?.receiptNo || "Add Customer order number!!",
        lineItems: spData?.products?.map((item: any, i: number) => ({
            id: i,
            lobId: whData?.lobId,
            sku: 464331064761,
            orderedQty: 2,
            poNoId: 0,
            upc: item?.upc,
            vendorSKU: "",
            customFields: {
                FnSku: item?.fnsku,
            },
        })) || [],
    };

    return orderBody;
};
// export const initializeOrderBodyEssentials = ({ whData, spData }: any) => {
//     const orderBody = {
//         lobId: whData?.lobId,
//         warehouseId: whData?.warehouseId,
//         orderDate: spData?.orderDate,
//         mediaCode: "Written",
//         legacyRestrictionType: "H/O All",
//         customerNo: "defaultCustomerNo",
//         customerOrderNo: spData?.receiptNo || "Add Customer order number!!",
//         lineItems: spData?.products?.map((item: any, i: number) => ({
//             id: i,
//             lobId: whData?.lobId,
//             sku: "",
//             orderedQty: 0,
//             poNoId: 0,
//             allowedQty: 0,
//             shippedQty: 0,
//             backorderQty: 0,
//             revDate: "",
//             chargeCode: "",
//             distributionCode: "",
//             upc: "",
//             vendorSKU: "",
//             orderSourceSKU: "",
//             unitCost: 0.0,
//             unitSell: 0.0,
//             extendedCost: 0.0,
//             extendedSell: 0.0,
//             ncExtendedSell: 0.0,
//             itemWeight: 0.0,
//             productionLot: "",
//             weightPerWrap: 0.0,
//             sector: "",
//             itemAccountCodeId: 0,
//             itemLegacyLowStockContactId: 0,
//             itemMajorGroupId: 0,
//             itemSubGroupId: 0,
//             itemProductCodeId: 0,
//             itemSummaryCodeId: 0,
//             customFields: {},
//         })) || [],
//     };

//     return orderBody;
// };
