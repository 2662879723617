import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { ISidebarMenu } from "./interfaces";

import logoPng from "assets/images/logoSquare.png";

import "./styles.scss";
import {
  cookiesNames,
  localStorageNames,
  pageRoutes,
  UserContext,
} from "utils";
import { users } from "utils/globals";
import { IContextUser } from "common/interfaces";
import { useLocation } from "react-router-dom";
import { routesToTitles } from "../../utils/routesToTitles";

const SidebarMenu = ({
  active,
  setSearchTerm,
  searchTerm,
  sidebarOnly,
  shipmentPlanTitle,
  profittableProductASIN,
  warehouseSelection,
}: ISidebarMenu) => {
  let user: IContextUser = useContext(UserContext)!;
  const [topProfileDropdwonMenu, setTopProfileDropdownMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState("");

  const {
    PROFITABLE_PRODUCTS,
    SAVED_SEARCHES,
    ADMIN,
    ADMIN_BLACKLIST,
    FEEDBACK_URL,
    WAREHOUSE_OWNERS,
  } = pageRoutes;

  const onSignOutClick = () => {
    localStorage.removeItem(localStorageNames.userObj);
    Cookies.remove(cookiesNames.token);
    window.location.href = pageRoutes.LOGIN;
  };

  const expandSideBar = () => {
    setExpandedMenu("");
    setOpenMenu(!openMenu);
  };

  const expandMenu = (menu: string) => {
    if (menu === expandedMenu) {
      setExpandedMenu("");
      return;
    }
    setExpandedMenu(menu);
  };

  const location = useLocation();

  let pageTitle = routesToTitles[location.pathname];

  if(shipmentPlanTitle) {
    pageTitle = shipmentPlanTitle;
  }

  if(profittableProductASIN) {
    pageTitle = `ASIN: ${profittableProductASIN}`;
  }

  if(warehouseSelection) {
    pageTitle = "Warehouses Map";
  }

  return (
    <div className="sidebar-menu px-3">
      <nav className="navbar navbar-default">
        <div className="navbar-header fixed-brand">
          <div className="sidebar-brand">
            <Link to="/profitable-products">
              <img className="sidebar-logo" src={logoPng} alt="asinmice-logo" />
            </Link>
          </div>
        </div>

        <div className="form-group right-content d-flex me-3">
          <div className="sidebar-container">
            <div className="sidebar-title">
              <h2>{pageTitle}</h2>
            </div>
          </div>
          {!sidebarOnly && (
            <div className="input-group search-box me-3">
              <input
                className="form-control"
                id="search"
                name="search"
                type="text"
                value={searchTerm}
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div className="input-group-addon input-icon-wrapper">
                <i className="fa fa-sm fa-search"></i>
              </div>
            </div>
          )}
          <div className="d-flex extension-download me-3 d-none d-md-block">
            <button className="btn btn-success">
              <a href="/tech-support" target="_blank" rel="noreferrer">
                <span className="fa-stack fa-sm menu-trigger-icon-wrapper">
                  <i className="fa fa-sm fa-headset fa-stack-1x"></i>
                </span>
                Support
              </a>
            </button>
          </div>
          <div
            className={`profile-content dropdown ${
              topProfileDropdwonMenu && "show"
            }`}
            onClick={() => setTopProfileDropdownMenu(!topProfileDropdwonMenu)}
          >
            <span className="profile-bubble">
              {user.firstName.substring(0, 1)}
              {user.lastName.substring(0, 1)}
            </span>

            <div
              className={`dropdown-menu ${topProfileDropdwonMenu && "show"}`}
              aria-labelledby="dropdownMenuButton"
            >
              <Link className="dropdown-item" to="/profile">
                Profile
              </Link>

              <a
                className="dropdown-item"
                href="#/"
                onClick={() => onSignOutClick()}
              >
                Log Out
              </a>
            </div>
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav">
            <li>
              <button
                className="navbar-toggle collapse in"
                data-toggle="collapse"
                id="menu-toggle-2"
              >
                <span
                  className="glyphicon glyphicon-th-large"
                  aria-hidden="true"
                ></span>
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <div
        id="sidebar-wrapper"
        className={openMenu ? "toggled" : ""}
        onMouseLeave={() => setOpenMenu(false)}
      >
        <div
          className="menu-trigger d-flex align-items-center"
          onClick={() => expandSideBar()}
        >
          <span className="fa-stack fa-sm menu-trigger-icon-wrapper">
            <i className="fa fa-sm fa-bars fa-stack-1x "></i>
          </span>
          Menu
        </div>

        <br />

        <ul className="sidebar-nav nav-pills nav-stacked mt-5" id="menu">
          <li>
            <Link to={PROFITABLE_PRODUCTS}>
              <span className="fa-stack fa-sm pull-left">
                <i className="fa fa-sm fa-dollar fa-stack-1x "></i>
              </span>
              Profitable
              <span className="badge-new">New</span>
            </Link>
          </li>
          <li>
            <Link to={SAVED_SEARCHES}>
              <span className="fa-stack fa-sm pull-left">
                <i className="fa fa-sm fa-floppy-o fa-stack-1x "></i>
              </span>
              Saved Searches
              <span className="badge-new">New</span>
            </Link>
          </li>

          <li>
            <Link to={pageRoutes.SHIPMENT_PLANS}>
              <span className="fa-stack fa-sm pull-left">
                <i className="fa fa-sm fa-truck fa-stack-1x "></i>
              </span>
              Logistics
              <span className="badge-new">New</span>
            </Link>
          </li>

          <li className={`${active === "suppliers" && "active-sub-menu"}`}>
            <Link to={pageRoutes.SUPPLIERS}>
              <span className="fa-stack fa-sm pull-left">
                <i className="fa fa-sm fa-archive fa-stack-1x "></i>
              </span>
              Suppliers
              <span className="badge-new">New</span>
            </Link>
          </li>
          {user.email === users.ADMIN_USER && (
            <>
              <li
                className={expandedMenu === "admin" ? "expanded" : ""}
                onClick={() => {
                  setExpandedMenu("");
                  setOpenMenu(true);
                  expandMenu("admin");
                }}
              >
                <a href="#/">
                  <span className="fa-stack fa-sm pull-left">
                    <i className="fa fa-sm fa-dashboard fa-stack-1x "></i>
                  </span>
                  {expandedMenu === "admin" ? (
                    <i className="fa fa-sm fa-caret-down fa-stack-1x expandable-icon" />
                  ) : (
                    <i className="fa fa-sm fa-caret-up fa-stack-1x expandable-icon" />
                  )}
                  ADMIN PANEL
                </a>

                <ul className="nav-pills nav-stacked">
                  <li
                    className={`${active === "blacklist" && "active-sub-menu"}`}
                  >
                    <Link className="nav-link" to={ADMIN_BLACKLIST}>
                      <span className="fa-stack fa-sm pull-left">
                        <i className="fa fa-sm fa-free-code-camp fa-stack-1x "></i>
                      </span>
                      Blacklist Items
                    </Link>
                  </li>

                  <li className={`${active === "users" && "active-sub-menu"}`}>
                    <Link className="nav-link" to={ADMIN}>
                      <span className="fa-stack fa-sm pull-left">
                        <i className="fa fa-sm fa-users fa-stack-1x "></i>
                      </span>
                      Users List
                    </Link>
                  </li>

                  <li
                    className={`${active === "feedback" && "active-sub-menu"}`}
                  >
                    <Link className="nav-link" to={FEEDBACK_URL}>
                      <span className="fa-stack fa-sm pull-left">
                        <i className="fa fa-sm fa-link fa-stack-1x "></i>
                      </span>
                      Feedback URLs
                    </Link>
                  </li>
                  <li
                    className={`${
                      active === "warehouse-owners" && "active-sub-menu"
                    }`}
                  >
                    <Link className="nav-link" to={WAREHOUSE_OWNERS}>
                      <span className="fa-stack fa-sm pull-left">
                        <i className="fa fa-sm fa-warehouse fa-stack-1x"></i>
                      </span>
                      Warehouse owners
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SidebarMenu;
