export const cookiesNames = {
  token: "asinmice-token",
};

export const localStorageNames = {
  rememberMe: "asinmice-website-remember-me",
  surveyStep1: "asinmice-survey-step1",
  userObj: "asinmice-obj",
  selectedShipmentPlans: "asinmice-selected-shipment-items",
};
