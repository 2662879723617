// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 890px) {
  #verticallyCentered {
    z-index: 100000;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/styles.scss"],"names":[],"mappings":"AACE;EADF;IAEI,eAAA;EACF;AACF","sourcesContent":["#verticallyCentered {\n  @media (max-width: 890px) {\n    z-index: 100000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
