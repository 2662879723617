export { default as HomePage } from "./homepage";
export { default as Team } from "./OurTeam";
export { default as Faq } from "./Faq";
export { default as Login } from "./login";
export { default as Register } from "./register";
export { default as Profile } from "./profile";
export { default as Products } from "./buckets";
export { default as SurveyStep1 } from "./surveyStep1";
export { default as SurveyStep2 } from "./surveyStep2";
export { default as Charts } from "./charts";
export { default as AdminView } from "./adminUsers";
export { default as AdminBlacklist } from "./adminBlacklist";
export { default as AdminFeedbackUrl } from "./adminFeedbackUrl";
export { default as ProfitableProducts } from "./profitableProducts";
export { default as ProfitableProductsDetails } from "./profitableProductsDetails";
export { default as SavedSearches } from "./savedSearches";
export { default as ShipmentPlan } from "./shipmentPlans";
export { default as ShipmentPlanItem } from "./shipmentPlan";
export { default as TechSupport} from "./techSupport";