import "./styles.scss";

import { useContext, useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

import {
  cookiesNames,
  localStorageNames,
  pageRoutes,
  UserContext,
} from "utils";
import { INav } from "./interfaces";
import { users } from "utils/globals";

import logoPng from "assets/images/logo.png";
import { IContextUser } from "common/interfaces";

const HomePageNavBar = ({ active }: INav) => {
  let user: IContextUser = useContext(UserContext)!;
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const hamburgerMenuClick = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onSignOutClick = () => {
    localStorage.removeItem(localStorageNames.userObj);
    Cookies.remove(cookiesNames.token);
    window.location.href = pageRoutes.LOGIN;
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <Link className="navbar-brand" to="/">
        <img className="navbar-logo" src={logoPng} alt="asinmice-logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#topNavBar"
        aria-controls="topNavBar"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => hamburgerMenuClick()}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${
          showMobileMenu ? "mobile-menu-bg show" : ""
        }`}
        id="topNavBar"
      >
        <ul className="navbar-nav mr-auto">
          <li
            className={`nav-item ${
              active === "home" || (!active && "is-active")
            }`}
          >
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className={`nav-item ${active === "download" && "is-active"}`}>
            <a
              className="nav-item"
              href="https://chromewebstore.google.com/detail/unielogics/bagcekacfgdabdkhamefiohigjiepjpb"
              target="_blank"
              rel="noreferrer"
            >
              Download Extension
            </a>
          </li>
          {window.location.pathname === "/" && (
            <li className={`nav-item ${active === "pricing" && "is-active"}`}>
              <a href="#pricing" className="nav-link">
                Pricing
              </a>
            </li>
          )}
          {!user && (
            <li
              className={`nav-item ${active === "login" && "is-active"} ${
                window.location.pathname.includes("login") && "d-none"
              }`}
            >
              <Link className="nav-link" to="/login">
                LOGIN
              </Link>
            </li>
          )}
          {user && (
            <>
              <li
                className={`nav-item ${active === "products" && "is-active"}`}
              >
                <Link className="nav-link" to="/profitable-products">
                  Products
                </Link>
              </li>
              <li className={`nav-item ${active === "profile" && "is-active"}`}>
                <Link className="nav-link" to="/profile">
                  Profile
                </Link>
              </li>
              {user.email === users.ADMIN_USER && (
                <>
                  <li
                    className={`nav-item ${
                      active === "blacklist" && "is-active"
                    }`}
                  >
                    <Link className="nav-link" to="/admin/blacklist">
                      Blacklist
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${active === "users" && "is-active"}`}
                  >
                    <Link
                      className="nav-link"
                      to="/admin/users/a61366e5-c437-45ab-ba17-4e10025abfa7"
                    >
                      Users
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      active === "feedback" && "is-active"
                    }`}
                  >
                    <Link className="nav-link" to="/admin/feedback-url">
                      Feedback URLS
                    </Link>
                  </li>
                </>
              )}
              <li className="nav-item" onClick={() => onSignOutClick()}>
                Sign out
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default HomePageNavBar;
