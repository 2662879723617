import "./style.scss";

import { Link } from "react-router-dom";

const CheckoutErrorPage = () => {
  return (
    <div className="checkout-error-page-wrapper">
      <div className="section">
        <div className="container">
          <h1>
            We are sorry but something went wrong with your payment. :( <br />
            You can select later a plan or if you encounter any kind of issues,
            please contact us at{" "}
            <a href="mailto:support@unielogics.com?subject=Plan selection issue">
              support@unielogics.com
            </a>
          </h1>
          <div className=" is-6">
            <Link className="button is-primary mt-2" to="/">
              Go Home
            </Link>
          </div>
          <br />
          <div className=" is-6">
            <Link className="button is-primary mt-2" to="/login">
              Go To Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutErrorPage;
