/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

import "./styles.scss";

const MultiLineChart = ({ chartData }: any) => {
  const rootDivId = "multiLineChartDiv";

  useLayoutEffect(() => {
    if (chartData.length) generateAndDrawLineChart("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData[0]]);

  var root: any;
  const generateAndDrawLineChart = (data: any) => {
    let chartnewData = chartData[0];

    if (data) {
      root.dispose();
      if (data === "3m") {
        chartnewData = chartData[1];
      }

      if (data === "6m") {
        chartnewData = chartData[2];
      }

      if (data === "12m") {
        chartnewData = chartData[3];
      }
      if (data === "all") {
        chartnewData = chartData[4];
      }
    }

    root = am5.Root.new(rootDivId);

    var myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fill: am5.color(0x000000),
      fontSize: "1.5em",
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Dark.new(root),
      // myTheme
    ]);

    root.autoResize = true;

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    // Set the background color
    chart.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var valueAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
    });
    valueAxisRenderer.labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    var valueAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: valueAxisRenderer,
        height: am5.percent(70),
      })
    );
    valueAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: "Prices",
        fontWeight: "bold",
        paddingBottom: 5,
        paddingTop: 5,
      })
    );

    var volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
    });
    volumeAxisRenderer.labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    var volumeAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: volumeAxisRenderer,
        height: am5.percent(80),
        layer: 15,
        numberFormat: "#a",
      })
    );
    volumeAxis.axisHeader.set("paddingTop", 10);
    volumeAxis.axisHeader.children.push(
      am5.Label.new(root, {
        text: "Sales Rank",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 5,
      })
    );

    var volumeAxisRenderer2 = am5xy.AxisRendererY.new(root, {
      inside: true,
    });
    volumeAxisRenderer2.labels.template.setAll({
      centerY: am5.percent(100),
      maxPosition: 0.98,
    });
    var volumeAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: volumeAxisRenderer2,
        height: am5.percent(70),
        layer: 5,
        numberFormat: "#a",
      })
    );
    volumeAxis2.axisHeader.set("paddingTop", 10);
    volumeAxis2.axisHeader.children.push(
      am5.Label.new(root, {
        text: "Sellers(New)",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 5,
      })
    );

    var dateAxisRenderer = am5xy.AxisRendererX.new(root, {});
    dateAxisRenderer.labels.template.setAll({
      minPosition: 0.01,
      maxPosition: 0.99,
    });
    var dateAxis = chart.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        groupData: true,
        // groupCount: 10,
        groupIntervals: [
          {
            timeUnit: "day",
            count: 1,
          },
        ],
        baseInterval: {
          timeUnit: "second",
          count: 1,
        },
        renderer: dateAxisRenderer,
      })
    );
    dateAxis.set("tooltip", am5.Tooltip.new(root, {}));

    var fbaSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        name: "FBA",
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: "fba",
        valueXField: "date",
        connect: false,
        tooltip: am5.Tooltip.new(root, {
          // eslint-disable-next-line no-template-curly-in-string
          labelText: "[#fff]{name} : [font-size: 1 #fff]${valueY}[/]",
        }),
        fill: am5.color("#ff9878"),
        stroke: am5.color("#ff9878"),
      })
    );

    var newSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        name: "New",
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: "new",
        valueXField: "date",
        connect: false,
        tooltip: am5.Tooltip.new(root, {
          // eslint-disable-next-line no-template-curly-in-string
          labelText: "[#fff]{name} : [font-size: 1 #fff]${valueY}[/]",
        }),
        fill: am5.color("#FF7F50"),
        stroke: am5.color("#FF7F50"),
      })
    );

    var amazonSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        name: "Amazon",
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: "amazon",
        valueXField: "date",
        connect: false,
        autoGapCount: 9999999999999999999999999999999999999999999,
        tooltip: am5.Tooltip.new(root, {
          // eslint-disable-next-line no-template-curly-in-string
          labelText: "[#fff]{name} : [font-size: 1 #fff]${valueY}[/]",
        }),
        fill: am5.color("#00FFFF"),
        stroke: am5.color("#00FFFF"),
      })
    );
    amazonSeries.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    });
    amazonSeries.strokes.template.setAll({
      strokeWidth: 1,
    });

    var buyBoxSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        name: "Buy Box",
        xAxis: dateAxis,
        yAxis: valueAxis,
        valueYField: "buyBox",
        valueXField: "date",
        connect: false,
        tooltip: am5.Tooltip.new(root, {
          // eslint-disable-next-line no-template-curly-in-string
          labelText: "[#fff]{name} : [font-size: 1 #fff]${valueY}[/]",
        }),
        fill: am5.color("#ff00b4"),
        stroke: am5.color("#ff00b4"),
      })
    );

    var bsrSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        name: "Bsr",
        xAxis: dateAxis,
        yAxis: volumeAxis,
        valueYField: "bsr",
        valueXField: "date",
        connect: true,
        autoGapCount: 9999999999999999999999999999999999999999999,
        tooltip: am5.Tooltip.new(root, {
          labelText: "[#fff]{name} : [font-size: 1 #fff]{valueY}[/]",
        }),
        fill: am5.color("#E6E6FA"),
        stroke: am5.color("#E6E6FA"),
      })
    );
    bsrSeries.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    });
    bsrSeries.strokes.template.setAll({
      strokeWidth: 1,
    });

    var newOfferSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        name: "New Offers",
        xAxis: dateAxis,
        yAxis: volumeAxis2,
        valueYField: "newOfferCount",
        valueXField: "date",
        connect: true,
        autoGapCount: 9999999999999999999999999999999999999999999,
        tooltip: am5.Tooltip.new(root, {
          labelText: "[#fff]{name} : [font-size: 1 #fff]{valueY}[/]",
        }),
        fill: am5.color("#FF7F50"),
        stroke: am5.color("#FF7F50"),
      })
    );
    newOfferSeries.fills.template.setAll({
      fillOpacity: 0.2,
      visible: true,
    });
    newOfferSeries.strokes.template.setAll({
      strokeWidth: 1,
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {})
    );
    legend.labels.template.setAll({
      fontSize: 14,
      fontWeight: "300",
    });
    legend.valueLabels.template.setAll({
      fontSize: 14,
      fontWeight: "800",
    });

    legend.data.setAll(chart.series.values);

    // Stack axes vertically
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/#Stacked_axes
    chart.leftAxesContainer.set("layout", root.verticalLayout);

    var processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy-MM-dd",
      numericFields: ["bsr", "buyBox", "new", "newOfferCount", "fba", "amazon"],
    });
    processor.processMany(chartnewData);

    // Set data
    fbaSeries.data.setAll(chartnewData);
    newSeries.data.setAll(chartnewData);
    amazonSeries.data.setAll(chartnewData);
    buyBoxSeries.data.setAll(chartnewData);

    bsrSeries.data.setAll(chartnewData);
    newOfferSeries.data.setAll(chartnewData);

    chart.zoomOutButton.set("forceHidden", true);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  };

  const setActiveWithJavascriptToAvoidReRendering = (monthNr: string) => {
    const prevSelectedElements = document.querySelectorAll(".am5stock-link");

    const selectedElement = document.querySelector(`.month-${monthNr}`);
    if (selectedElement) {
      prevSelectedElements.forEach((prevSelected) => {
        prevSelected.classList.remove("active-month");
      });
      selectedElement.classList.add("active-month");
    }
  };
  const handle3MonthSelectionClick = () => {
    generateAndDrawLineChart("3m");
    setActiveWithJavascriptToAvoidReRendering("3");
  };

  const handle6MonthSelectionClick = () => {
    generateAndDrawLineChart("6m");
    setActiveWithJavascriptToAvoidReRendering("6");
  };

  const handle12MonthSelectionClick = () => {
    generateAndDrawLineChart("12m");
    setActiveWithJavascriptToAvoidReRendering("12");
  };

  const handleAllMonthSelectionClick = () => {
    generateAndDrawLineChart("all");
    setActiveWithJavascriptToAvoidReRendering("all");
  };

  return (
    <div className="container-fluid charts text-center">
      <div className="row">
        <div className="col-12">
          <div id="chartcontrols">
            <div className="am5stock-control-label">
              <a
                className="am5stock-link month-3"
                data-period="month3"
                onClick={() => handle3MonthSelectionClick()}
              >
                3M
              </a>
              <a
                className="am5stock-link month-6"
                data-period="month6"
                onClick={() => handle6MonthSelectionClick()}
              >
                6M
              </a>
              <a
                className="am5stock-link month-12"
                data-period="month12"
                onClick={() => handle12MonthSelectionClick()}
              >
                12M
              </a>
              <a
                className="am5stock-link month-all active-month"
                data-period="monthAll"
                onClick={() => handleAllMonthSelectionClick()}
              >
                All
              </a>
            </div>
          </div>
          <div
            id="multiLineChartDiv"
            style={{ width: "auto", height: "402px" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MultiLineChart;
