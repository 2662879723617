// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderNo-container {
  position: relative;
}

.orderNo-btn {
  position: absolute;
  border-radius: 2px;
  background: transparent;
  color: white;
  top: 40%;
  left: 300px;
  z-index: 2;
  border: none;
  height: 35px;
  font-size: 18px;
  text-decoration: underline;
}

.shipment-plan-table-container {
  max-height: 250px;
  overflow-y: scroll;
}
.shipment-plan-table-container tr {
  border-bottom: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/ShipmentPlanModalList/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,0BAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;AACF;AAAE;EACE,0BAAA;AAEJ","sourcesContent":[".orderNo-container {\n  position: relative;\n}\n\n.orderNo-btn {\n  position: absolute;\n  border-radius: 2px;\n  background: transparent;\n  color: white;\n  top: 40%;\n  left: 300px;\n  z-index: 2;\n  border: none;\n  height: 35px;\n  font-size: 18px;\n  text-decoration: underline;\n}\n\n.shipment-plan-table-container {\n  max-height: 250px;\n  overflow-y: scroll;\n  tr {\n    border-bottom: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
