import "./style.scss";

import { Link } from "react-router-dom";

const ShipmentPlanErrorPage = () => {
  return (
    <div className="w-payment-checkout-error-page-wrapper">
      <div className="section">
        <div className="container">
          <h1>
            We are sorry but something went wrong with your payment. :( <br />
            Please try again, and if the error persists, please contact us at{" "}
            <a href="mailto:support@asinmice.com?subject=Plan selection issue">support@asinmice.com</a>
          </h1>
          <div className="is-6 is-offset-desktop-3 mb-3">
            <iframe
              src="https://giphy.com/embed/fqst7AVqF6AVLlYklE"
              width="480"
              height="480"
              title="giphy frame"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </div>
          <div className=" is-6">
            <Link className="button is-primary mt-2" to="/shipment-plans">
              Go To Shipment Plans
            </Link>
          </div>
          <br />
          <div className=" is-6">
            <Link className="button is-primary mt-2" to="/profitable-products">
              Go To Products
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentPlanErrorPage;
