import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import LogoBig from "assets/images/LogoPrincipal.png";

function useWindowWidth() {
  const [isWideScreen, setIsWideScreen] = useState(
    typeof window !== "undefined" ? window.innerWidth >= 1400 : false
  );

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth >= 1400);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isWideScreen;
}

const FAQPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleQuestion = (index: number) => {
    setExpandedQuestions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    let rafId: number | null = null;
    let targetScrollTop = scrollContainer.scrollTop;

    const smoothScroll = () => {
      const currentScrollTop = scrollContainer.scrollTop;
      const diff = targetScrollTop - currentScrollTop;
      if (Math.abs(diff) < 1) {
        scrollContainer.scrollTop = targetScrollTop;
        rafId = null;
        return;
      }
      scrollContainer.scrollTop += diff * 0.1;
      rafId = requestAnimationFrame(smoothScroll);
    };

    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      targetScrollTop = Math.max(
        0,
        Math.min(
          scrollContainer.scrollHeight - scrollContainer.clientHeight,
          targetScrollTop + e.deltaY
        )
      );
      if (rafId === null) {
        rafId = requestAnimationFrame(smoothScroll);
      }
    };

    scrollContainer.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      scrollContainer.removeEventListener("wheel", handleScroll);
      if (rafId !== null) {
        cancelAnimationFrame(rafId);
      }
    };
  }, []);

  const faqItems = [
    {
      question:
        "What is UnieLogics, and how can it benefit my Ecommerce business?",
      answer:
        "UnieLogics is a SaaS platform that connects eCommerce sellers with a nationwide network of warehouses, offering streamlined logistics, inventory management, and fulfillment services. Our platform helps you save on shipping costs, optimize inventory placement, and scale your operations seamlessly.",
    },
    {
      question: "How do I get started with UnieLogics?",
      answer:
        "Simply sign up on our platform, provide your logistics requirements, and our system will match you with the best-suited warehouse partners. You can start creating shipment plans and managing orders immediately.",
    },
    {
      question: "Are there minimum order quantities or long-term contracts?",
      answer:
        "No, UnieLogics offers flexible solutions with no minimum order quantity or binding contracts. You only pay for the services you use.",
    },
    {
      question: "Can UnieLogics help with Amazon FBA fulfillment?",
      answer:
        "Yes, our platform supports Amazon sellers by automating order fulfillment and providing shipment plans compatible with Amazon’s requirements, ensuring smooth and hassle-free operations.",
    },
    {
      question: "What are the requirements to join the UnieLogics network?",
      answer:
        "To qualify, your warehouse should meet our operational standards, including adequate storage space, a loading dock, and proximity to major shipping routes. We also ask for transparency in your pricing for prep services, such as labeling, bundling, and reboxing.",
    },
    {
      question: "What is the cost of joining the network?",
      answer:
        "Qualified warehouses can join for free, gaining access to our WMS and eCommerce seller network. Non-qualified facilities can sign up for $499 per month to access our software and services.",
    },
    {
      question: "How does the WMS provided by UnieLogics work?",
      answer:
        "Our WMS streamlines your warehouse operations with features like barcode scanning, inventory tracking, automated rate shopping, and workflow management. It’s designed to enhance efficiency and reduce overhead costs.",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      {/* Left Section */}
      <div className="w-full md:w-1/2 bg-[#1E213D] py-16 px-8 md:py-28 md:px-16 flex flex-col h-screen">
        <h1 className="text-[28px] lg:text-[44px] font-bold text-white mb-12">
          Frequently Asked Questions
        </h1>

        <div
          ref={scrollContainerRef}
          className="overflow-y-auto flex-grow pr-4 smooth-scroll w-full md:w-11/12 lg:w-5/6 xl:w-full mx-auto px-4 md:px-8 lg:px-12">
          {faqItems.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isExpanded={expandedQuestions.includes(index)}
              onToggle={() => toggleQuestion(index)}
            />
          ))}
        </div>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-1/2 bg-black min-h-screen p-6 md:p-16 flex flex-col">
        {/* Navigation */}
        <nav
          className="absolute top-0 left-0 right-0 p-4 z-10 flex items-center justify-between"
          role="navigation"
          aria-label="Main Navigation">
          {/* Logo */}
          <Link to="/">
            <picture>
              <source srcSet={LogoBig} media="(min-width: 768px)" />
              <img src={Logo} alt="Unielogics Logo" className="logo-img" />
              <style>{`
          .logo-img {
            width: 12rem; /* Default size for larger screens */
          }
          @media (max-width: 768px) {
            .logo-img {
              width: 12rem; /* Adjust size for smaller screens */
            }
          }
        `}</style>
            </picture>
          </Link>

          {/* Desktop Menu */}
          <ul className="hidden md:flex space-x-6">
            <li>
              <a href="/" className="text-purple-300 hover:text-gray-200">
                Home
              </a>
            </li>
            <li className="text-purple-500">/</li>
            <li>
              <Link to="/team" className="text-purple-300 hover:text-gray-200">
                Our Team
              </Link>
            </li>
            <li className="text-purple-500">/</li>
            <li>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
                className="text-purple-300 hover:text-gray-200">
                Warehouse Owners
              </a>
            </li>
          </ul>

          {/* Burger Menu */}
          <button
            className="md:hidden text-purple-300 hover:text-gray-200"
            onClick={toggleMenu}
            aria-label="Toggle menu">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </nav>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center md:hidden">
            <button
              className="absolute top-4 right-4 text-purple-300 hover:text-gray-200"
              onClick={toggleMenu}
              aria-label="Close menu">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="flex flex-col items-center space-y-6">
              <li>
                <a
                  href="/"
                  className="text-purple-300 hover:text-gray-200 text-xl">
                  Home
                </a>
              </li>
              <li>
                <Link
                  to="/team"
                  className="text-purple-300 hover:text-gray-200 text-xl">
                  Our Team
                </Link>
              </li>
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSflYitbApQARcFWUZqslwjzQYlxcClc0lwK7QmALH6W_Ke5iw/viewform"
                  className="text-purple-300 hover:text-gray-200 text-xl">
                  Warehouse Owners
                </a>
              </li>
            </ul>
            <a href="/">
              <img
                src={LogoBig}
                alt="Unielogics Logo"
                className="w-52 h-22 mt-8"
              />
            </a>
          </div>
        )}

        {/* Main Content */}
        <div className="flex-grow flex flex-col items-center justify-center space-y-12">
          {/* Warehouse Owner Section */}
          <div className="text-center space-y-4">
            <h2 className="text-xl text-white">Warehouse Owner?</h2>
            <a
              href="https://partners.unielogics.com/widget/booking/oOnMnK8jXlmvSrIjaXEt"
              className="inline-block">
              <button className="bg-[#3B82F6] text-white text-base font-medium px-20 py-6 rounded-full hover:bg-blue-600 transition-colors">
                Speak To Sales
              </button>
            </a>
          </div>

          {/* Seller Section */}
          <div className="text-center space-y-4">
            <h2 className="text-xl text-white">
              Seller looking for logistics?
            </h2>
            <a href="/register?plan=plan37" className="inline-block">
              <button className="bg-[#3B82F6] text-white text-base font-medium px-20 py-6 rounded-full hover:bg-blue-600 transition-colors">
                Try For FREE
              </button>
            </a>
          </div>

          {/* Login Section */}
          <div className="text-center space-y-4">
            <a href="/login" className="text-lg text-white hover:text-gray-300">
              Already have an account? Login
            </a>
          </div>
        </div>

        {/* Footer */}
        <div className="flex flex-col items-center gap-2">
          <div className="flex items-center gap-2 w-36">
            <a href="/">
              <img src={LogoBig} alt="Unielogics Logo" />
            </a>
          </div>
          <div className="flex items-center gap-4 text-xs text-gray-500">
            <a
              href="https://docs.google.com/document/d/10apy8E_ith2dFrKrfeFMFCdTy14vx8lK/edit"
              className="hover:text-gray-400">
              Terms of Use
            </a>
            <span>|</span>
            <a
              href="https://docs.google.com/document/d/1ftEd8TCG2ujxKUzAndHBNO9aQnm4Tdx0J5jzLdYo68c/edit?tab=t.0"
              className="hover:text-gray-400">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

interface FAQItemProps {
  question: string;
  answer: string;
  isExpanded: boolean;
  onToggle: () => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  isExpanded,
  onToggle,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const isWideScreen = useWindowWidth();

  useEffect(() => {
    if (isExpanded) {
      const contentEl = contentRef.current;
      if (contentEl) {
        setHeight(contentEl.scrollHeight);
      }
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div className={`mb-6 ${isWideScreen ? "group" : ""}`}>
      <h2
        className={`text-2xl md:text-3xl text-white mb-2 cursor-pointer flex justify-between items-center ${
          isWideScreen
            ? "transition-transform duration-300 group-hover:scale-105"
            : ""
        }`}
        onClick={onToggle}>
        {question}
        <span
          className={`text-xl ${
            isWideScreen ? "transition-transform duration-300" : ""
          }`}
          style={{ transform: isExpanded ? "rotate(45deg)" : "rotate(0deg)" }}>
          +
        </span>
      </h2>
      <div
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{ maxHeight: isExpanded ? `${height}px` : "0" }}>
        <div ref={contentRef}>
          <p
            className={`text-base md:text-lg text-gray-300 leading-relaxed ${
              isWideScreen
                ? "transition-transform duration-300 group-hover:scale-105 group-hover:text-gray-100"
                : ""
            }`}>
            {answer}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
