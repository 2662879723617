import { Button } from "@amcharts/amcharts5";
import "./styles.scss";
import { useState, useEffect } from "react";
import { getQueryParameterByName, pageRoutes } from "utils";
import { useNavigate } from "react-router-dom";
import {} from "utils/globals";
import { ShipmentPlanDateSelectionContext } from "utils/context";
import { set } from "lodash";

interface IStepperData {
  name: string;
  step: string;
  status: string;
}

interface IStepper {
  darkMode?: boolean;
  stepperData: IStepperData[];
}

const Stepper = ({ darkMode, stepperData }: IStepper) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showPayPal, setShowPayPal] = useState(false);

  // shipment plan date selection context
  const [shipmentPlanDateSelectionFlag, setShipmentPlanDateSelectionFlag] =
    useState(false);
  const [shipmentPlanEstimateArrivalDate, setShipmentPlanEstimateArrivalDate] =
    useState(new Date());
  const [shipmentPlanDateSelection, setShipmentPlanDateSelection] = useState(
    new Date()
  );

  const planId = getQueryParameterByName("id");
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<string | "">("");
  const [estimatedArrivalDate, setEstimatedArrivalDate] = useState<string | "">(
    ""
  );

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
      setShowPayPal(false);
    }
  };

  const handlePaymentRedirect = () => {
    setShowPayPal(true);
  };

  useEffect(() => {
    if (window.paypal) {
      const container = document.getElementById("paypal-button-container");

      if (container) {
        window.paypal
          .Buttons({
            style: {
              color: "gold",
              shape: "rect",
              size: "large",
              label: "pay",
            },
          })
          .render("#paypal-button-container");
      } else {
        // console.error("#paypal-button-container not found in the DOM.");
      }
    } else {
      console.error("PayPal SDK not loaded");
    }
  }, [showPayPal]);

  const handlePaymentStatusRedirect = () =>
    navigate(pageRoutes.SHIPMENT_PLAN_PAYMENT_THANK_YOU);

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setShowPayPal(false);
    }
    console.log("handlePrevious", currentStep);
  };

  const handleShipmentDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedDate(event.target.value);
    console.log("selectedDate", selectedDate);
  };

  const handleArrivalDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEstimatedArrivalDate(event.target.value);
    console.log("estimatedArrivalDate", estimatedArrivalDate);
  };

  const submitDates = () => {
    setShipmentPlanDateSelectionFlag(false);
  };

  const closePopup = () => {
    // setShipmentDateSelection(false);
    // setShowPopup(false);
    setShipmentPlanDateSelectionFlag(false);
  };

  return (
    <div>
      <div className="steps-wrapper">
        <button
          className={`stepper-button ${
            currentStep === 1 ? "btn-disabled" : ""
          }`}
          onClick={handlePrevious}
          disabled={currentStep === 1}
        >
          Previous
        </button>
        <ol className={`steps ${darkMode && "dark-mode"}`}>
          {stepperData.map((stepper, index) => (
            <li
              key={stepper.step}
              className={`step ${
                stepper.status === "completed"
                  ? index <= 1 ||
                    (index === 3 &&
                      stepperData[index - 1].status === "completed") ||
                    (index > 3 && stepperData[index - 1].status !== "completed")
                    ? "is-complete"
                    : "is-partial-completed"
                  : ""
              } ${stepper.status === "active" ? "is-active" : ""} ${
                index === currentStep ? "is-highlighted" : ""
              }`}
              data-step={stepper.step}
            >
              {stepper.name}
            </li>
          ))}
        </ol>
        <button
          className={`stepper-button ${
            currentStep === 3 ? "btn-disabled" : ""
          }`}
          onClick={handleNext}
          disabled={currentStep === 3}
        >
          Next
        </button>
      </div>

      {currentStep === 1 && (
        <div>
          <div className="shipment-date-container">
            {shipmentPlanDateSelectionFlag && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <h2>Select Shipment and Arrival Dates</h2>

                  {/* updates shipment plan estimated arrival and ship by*/}
                  <ShipmentPlanDateSelectionContext.Provider
                    value={{
                      shipmentPlanDateSelection,
                      setShipmentPlanDateSelection,
                      shipmentPlanEstimateArrivalDate,
                      setShipmentPlanEstimateArrivalDate,
                    }}
                  >
                    <div className="popup-form">
                      <label htmlFor="shipment-date">Shipment Date</label>

                      <input
                        id="shipment-date"
                        type="date"
                        value={selectedDate}
                        onChange={handleShipmentDateChange}
                      />
                      {/* </ShipmentPlanDateSelectionContext.Provider> */}

                      <label htmlFor="arrival-date">
                        Estimated Arrival Date
                      </label>

                      {/* update estimated arrival date in shipment plan */}

                      <input
                        id="arrival-date"
                        type="date"
                        value={estimatedArrivalDate}
                        onChange={handleArrivalDateChange}
                      />
                    </div>
                  </ShipmentPlanDateSelectionContext.Provider>

                  {/* close pop up when confirm/cancel */}
                  <ShipmentPlanDateSelectionContext.Provider
                    value={{
                      shipmentPlanDateSelectionFlag,
                      setShipmentPlanDateSelectionFlag,
                    }}
                  >
                    <div className="popup-actions">
                      <button onClick={submitDates} className="btn btn-confirm">
                        Confirm
                      </button>
                      <button onClick={closePopup} className="btn btn-cancel">
                        Cancel
                      </button>
                      {/* </ShipmentPlanDateSelectionContext.Provider> */}
                    </div>
                  </ShipmentPlanDateSelectionContext.Provider>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="payment-container" id="paypalContainer">
          <div className="center-button-container">
            <button
              onClick={handlePaymentRedirect}
              className="redirect-button"
              id="redirectButton"
            >
              Make Payment
            </button>
          </div>
          {showPayPal && (
            <div className="paypal-parent-container">
              <div className="paypal-container">
                <div id="paypal-button-container"></div>
              </div>
            </div>
          )}
        </div>
      )}
      {currentStep === 3 && (
        <div className="center-button-container">
          <button
            onClick={handlePaymentStatusRedirect}
            className="redirect-button"
          >
            Payment Status
          </button>
        </div>
      )}
    </div>
  );
};

export default Stepper;
