// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipment-plans .no-interaction:hover, .shipment-plans .no-interaction:focus, .shipment-plans .no-interaction:active {
  background-color: transparent !important;
}
.shipment-plans-products-table tbody {
  font-size: 0.8rem;
}
.shipment-plans-products-table tbody tr {
  background-color: #484848;
}
.shipment-plans-products-table tbody tr:hover {
  background-color: #484848 !important;
}
.shipment-plans-products-table tbody td {
  width: 5%;
}

.table th,
.table td {
  border-bottom: 1.7px solid rgba(160, 158, 158, 0.545);
}`, "",{"version":3,"sources":["webpack://./src/pages/shipmentPlans/styles.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAII;EAGE,wCAAA;AALN;AASI;EACE,iBAAA;AAPN;AAQM;EACE,yBCJM;ADFd;AAOQ;EACE,oCAAA;AALV;AAQM;EACE,SAAA;AANR;;AAYA;;EAEE,qDAAA;AATF","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.shipment-plans {\n  .no-interaction {\n    &:hover,\n    &:focus,\n    &:active {\n      background-color: transparent !important;\n    }\n  }\n  &-products-table {\n    tbody {\n      font-size: 0.8rem;\n      tr {\n        background-color: $tundoraGray;\n        &:hover {\n          background-color: $tundoraGray !important;\n        }\n      }\n      td {\n        width: 5%;\n      }\n    }\n  }\n}\n\n.table th,\n.table td {\n  border-bottom: 1.7px solid rgba(160, 158, 158, 0.545); \n}","$budhaGold: #8b76b2;\n$sorbusOrange: #8481be;\n$lighterSorbusOrange: #8b76b2;\n$codGray: #191919;\n$mineShaftGray: #252525;\n$selectiveYellow: #8481be;\n$inchWormGreen: \"#7fec12\";\n$errorRed: #f27761;\n$codBlack: #101010;\n$codBlackV2: #171717;\n$tundoraGray: #484848;\n$silverGray: #a6a6a6;\n$pictonBlue: #a44c92;\n$scoprionGray: #5b5b5b;\n$limeade: #3c9c00;\n$sanFelixGreen: #1c6c09;\n$phoenixLinkBlue: #85a9ff;\n$separatorGray: #313749;\n$vulcanBlue: #0f111a;\n$mirageBlue: #151824;\n$textGray: #999999;\n$victoriaBlue: #465298;\n$flamingoRed: #f33f45;\n$thunderbirdRed: #c51a16;\n$green: #02752c;\n$lightGreen: #d5fae0;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
