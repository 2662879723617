import { IUser } from "common/interfaces";
import "./styles.scss";

const ViewUsers = ({ users }: any) => {
  return (
    <>
      {users.length && (
        <table className="users-table-view table mt-5">
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Plan Status</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Plan</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: IUser, index: number) => (
              <tr key={user.hash}>
                <td>{index + 1}</td>
                <td>
                  {user.firstName} {user.lastName}
                </td>
                <td>
                  <span
                    className={`status-indicator status-indicator--${
                      user.hasActiveSubscription && user.plan === "trial"
                        ? "new"
                        : user.hasActiveSubscription
                        ? "active"
                        : "inactive"
                    }`}
                  >
                    {user.hasActiveSubscription && user.plan === "trial"
                      ? "Trial"
                      : user.hasActiveSubscription
                      ? "Active"
                      : "Inactive"}
                  </span>
                </td>
                <td>
                  <a
                    href={`mailto:${user.email}?subject=Welcome Call With Dr Mice`}
                  >
                    {user.email}
                  </a>
                </td>
                <td>
                  {user.phoneNumber ? (
                    <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{user.plan}</td>
                <td>{user.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ViewUsers;
