import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface DataState<T> {
    data: T | null;
    setData: (data: T) => void;
    updateData: (updater: (prevData: T | null) => T) => void;
}

const useAppStore = create(
    persist<DataState<any>>(
        (set) => ({
            data: null,
            setData: (data) => set({ data }),
            updateData: (updater) => set((state) => ({ data: updater(state.data) })),
            deleteData: () => set({ data: null }),
        }),
        {
            name: 'wms-data-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useAppStore;
