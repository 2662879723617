import React from "react";

interface WarehouseDetailsProps {
  selectedWarehouse: {
    name: string;
    city: string;
    address: string;
    state: string;
    zipCode: string;
    distance?: number; // Optional, if not always present
  };
}

const WarehouseDetails: React.FC<WarehouseDetailsProps> = ({ selectedWarehouse }) => {

  // Copy to clipboard function
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        console.log("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };


  return (
    <div className="wm-supplier-address-wrapper">
      <h1>TO</h1>
      <div className="wm-warehouse-address wm-address-box">
        <h2>Warehouse</h2>
        <div className="d-flex flex-column fixed-length-container">
          {["name","address", "city",  "state", "zipCode"].map((field) => (
            <span key={field} onClick={() => copyToClipboard(selectedWarehouse[field as keyof typeof selectedWarehouse] as string)}>
              {`${field.charAt(0).toUpperCase() + field.slice(1)}: ${selectedWarehouse[field as keyof typeof selectedWarehouse]}`}
              <i className="fa fa-clipboard ms-2 fa-xs" aria-hidden="true"></i>
            </span>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default WarehouseDetails;
