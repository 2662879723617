import { InfoPlusOrderI } from "lib/infoplus/interfaces/order";
import wmsClient from "../interceptor/interceptor";
import { wmsRoutes } from "../interceptor/routes";

export const getWmsOrderById = async (id: number) => {
    try {
        const response = await wmsClient.get(`${wmsRoutes.order}/${id}`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching order:', error);
        throw error;
    }
};

export const getWmsOrdersList = async (filter: string = "", page: number = 1, limit: number = 20, sort: string = "") => {
    try {
        const response = await wmsClient.get(wmsRoutes.orderFilter, {
            params: {
                filter,
                page,
                limit,
                sort
            }
        });
        return response.data;
    }
    catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};
export const createWmsOrder = async (data: InfoPlusOrderI) => {
    try {
        const response = await wmsClient.post(wmsRoutes.order, data);
        return response.data;
    }
    catch (error) {
        console.error('Error creating order:', error);
        throw error;
    }
};

export const updateWmsOrder = async (id: number, data: any) => {
    try {
        const response = await wmsClient.put(`${wmsRoutes.order}/${id}`, data);
        return response.data;
    }
    catch (error) {
        console.error('Error updating order:', error);
        throw error;
    }
};