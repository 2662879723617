import axios from "axios";

import { apiRoutes } from "utils";
import { IGetKeepaProductResponse } from "../interfaces";

export const getKeepaProductRequest = async (asin: string) => {
  const { data } = await axios.get<IGetKeepaProductResponse>(
    `${apiRoutes.GET_KEEPA_CHART_DATA}?domain=1&stats=365&buybox=1&history=1&offers=20&asin=${asin}`
  );
  return data;
};
