// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen-loader {
  position: fixed;
  z-index: 100000;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(gray, transparent);
}

.full-screen-loader:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-radial-gradient(rgba(27, 27, 27, 0.178), rgba(2, 2, 2, 0.233));
}`, "",{"version":3,"sources":["webpack://./src/components/FullScreenLoading/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,8CAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EAEA,kFAAA;AAAF","sourcesContent":[".full-screen-loader {\n  position: fixed;\n  z-index: 100000;\n  overflow: show;\n  margin: auto;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: radial-gradient(gray, transparent);\n}\n\n.full-screen-loader:before {\n  content: \"\";\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n\n  background: -webkit-radial-gradient(\n    rgba(27, 27, 27, 0.178),\n    rgba(2, 2, 2, 0.233)\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
