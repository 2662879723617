import React, { useState } from "react";
import useAppStore from "utils/zustandStore/useAppStore";
import useShipmentPlanStore from "utils/zustandStore/useShipmentPlanStore";
import { initializeOrderBodyEssentials } from "./orderRequestObj";
import { exampleOrderData } from "./mapResponseToOrder";
import ReadOnlyDataViewer from "components/ui/ReadOnlyDataViewer";
import EditableFormTable from "components/ui/EditableFormtable";

export default function ReviewOrder() {
  const { data } = useShipmentPlanStore();
  const { updateData } = useAppStore();
  const [showExample, setShowExample] = useState(false);
  const spData = data?.shipmentPlan?.shipmentPlan[0];
  const whData = data?.warehouse;
  const [orderState, setOrderState] = useState(
    initializeOrderBodyEssentials({ whData, spData })
  );

  const handleObjectChange = async (newObject: any) => {
    const cleanObject = JSON.parse(JSON.stringify(newObject));
    setOrderState(cleanObject);
    updateData((prevData: any) => ({
      ...prevData,
      order: cleanObject,
    }));
  };

  return (
    <div className="gap-4">
      <h3>Review Order Details</h3>
      <EditableFormTable saveChanges={handleObjectChange} data={orderState} />
      <button
        className="btn text-white bg-gray-600 mx-4"
        onClick={() => handleObjectChange(orderState)}
      >
        Confirm Order
      </button>

      <button
        className="btn text-white bg-gray-700"
        onClick={() => setShowExample(!showExample)}
      >
        {showExample ? "Hide Example" : "Show Example Order"}
      </button>
      {showExample && <ReadOnlyDataViewer data={exampleOrderData} />}
    </div>
  );
}
