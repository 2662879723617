import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { UserContext } from "utils";

const PrivateRoutes = () => {
  let user: any = useContext(UserContext);

  return user.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
