// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-blacklist .navbar-container {
  padding: 0 55px;
  background-color: black;
}
.admin-blacklist .search-input-group {
  width: 400px;
  margin: 0 auto;
}
.admin-blacklist .search-reload {
  color: black;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
  padding: 0 15px;
  background: #e9ecef;
  border: 1px solid #ced4da;
}
.admin-blacklist .search-reload:hover,
.admin-blacklist .search-reload:active {
  background: #d0d5da;
}`, "",{"version":3,"sources":["webpack://./src/pages/adminFeedbackUrl/styles.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,uBAAA;AAAJ;AAEE;EACE,YAAA;EACA,cAAA;AAAJ;AAEE;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAEE;;EAEE,mBAAA;AAAJ","sourcesContent":[".admin-blacklist {\n  .navbar-container {\n    padding: 0 55px;\n    background-color: black;\n  }\n  .search-input-group {\n    width: 400px;\n    margin: 0 auto;\n  }\n  .search-reload {\n    color: black;\n    font-size: 26px;\n    font-weight: bold;\n    cursor: pointer;\n    padding: 0 15px;\n    background: #e9ecef;\n    border: 1px solid #ced4da;\n  }\n  .search-reload:hover,\n  .search-reload:active {\n    background: #d0d5da;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
