import axios from "axios";
import { ILoginResponse } from "pages/login/interfaces";
import { apiRoutes } from "utils";

export const postGoogleLoginRequest = async (
  client_id: string,
  credential: string
) => {
  // const url = "http://localhost:4242/users/googleLogin";
  const { data } = await axios.post<ILoginResponse>(
    apiRoutes.GOOGLE_LOGIN,
    // url,
    {
      client_id,
      credential,
    }
  );
  return data;
};
