import axios from "axios";
import { apiRoutes } from "utils";
import {
  IEditFeedback,
  IEditFeedbackResponse,
  IGetFeedbackUrl,
} from "../interfaces";

export const getFeedbackUrls = async () => {
  const { data } = await axios.get<IGetFeedbackUrl>(
    `${apiRoutes.GET_FEEDBACK_URL}`
  );
  return data;
};

export const updateFeedbackUrlRequest = async ({
  _id,
  newValue,
}: IEditFeedback) => {
  const { data } = await axios.patch<IEditFeedbackResponse>(
    apiRoutes.EDIT_FEEDBACK_URL,
    {
      _id,
      newValue,
    }
  );
  return data;
};
