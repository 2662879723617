import { Fragment } from "react";
import "./styles.scss";
import { transformKeyIntoReadableText } from "utils";

interface ITable {
  tableHeaders?: any[];
  tableData: any[];
  maxHeightClass?: string;
  noMaxHeight?: boolean;
}
const Table = ({
  tableHeaders,
  tableData,
  maxHeightClass,
  noMaxHeight,
}: ITable) => {
  const allKeys = Array.from(
    new Set(tableData.flatMap((item) => Object.keys(item)))
  );

  const generateRows = ({ item, key }: any) => {
    if (key === "img") {
      return <img src={item} alt="" />;
    }
    return item;
  };

  const generateHeaders = (key: string) => {
    return transformKeyIntoReadableText(key);
  };

  return (
    <div
      className={`table-responsive ${
        !noMaxHeight && "max-h-78vh"
      } profitable-products ${maxHeightClass ? maxHeightClass : "max-h-78vh"}`}
    >
      <table className="table table-sm">
        <thead className="sticky-header">
          <tr>
            {tableHeaders
              ? tableHeaders.map((header) => (
                  <th className="text-center" key={header.id}>
                    {header.name}
                  </th>
                ))
              : allKeys.map((key: string) => (
                  <th className="text-center" key={key}>
                    {generateHeaders(key)}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item: any, rowIndex: number) => (
            <Fragment key={rowIndex}>
              <tr>
                {allKeys.map((key: string, columnIndex: number) => (
                  <td
                    className="align-middle text-center white-space-nowrap"
                    key={columnIndex}
                  >
                    {generateRows({ item: item[key], key })}
                  </td>
                ))}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
