import axios from "axios";
import { apiRoutes } from "utils";
import {
  IBlacklistPagination,
  IDeleteBlacklistBrandResponse,
  IEditBlacklist,
  IEditBlacklistBrandResponse,
  IGetBlacklist,
  IGetSearchResponse,
  IPostBlacklistBrandsResponse,
} from "../interfaces";

export const getBlacklist = async ({
  page,
  itemsPerPage,
}: IBlacklistPagination) => {
  const { data } = await axios.get<IGetBlacklist>(
    `${apiRoutes.BLACKLIST}?page=${page}&itemsPerPage=${itemsPerPage}`
  );
  return data;
};

export const getSearchRequest = async (searchTerm: string) => {
  const { data } = await axios.get<IGetSearchResponse>(
    `${apiRoutes.SEARCH_BLACKLIST_BRAND}?searchTerm=${searchTerm}`
  );
  return data;
};

export const postBlacklistBrandsRequest = async (brands: string[]) => {
  const { data } = await axios.post<IPostBlacklistBrandsResponse>(
    apiRoutes.POST_BLACKLIST_BRANDS,
    {
      brands,
    }
  );
  return data;
};

export const deleteBlackListBrandRequest = async (brand: string) => {
  const { data } = await axios.delete<IDeleteBlacklistBrandResponse>(
    apiRoutes.DELETE_BLACKLIST_BRAND,
    {
      data: { brand },
    }
  );

  return data;
};

export const updateBlacklistBrandRequest = async ({
  _id,
  newValue,
}: IEditBlacklist) => {
  const { data } = await axios.patch<IEditBlacklistBrandResponse>(
    apiRoutes.EDIT_BLACKLIST_BRAND,
    {
      _id,
      newValue,
    }
  );
  return data;
};
