// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts {
  min-height: 100vh;
}
.charts-loading {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/charts/styles.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAEE;EACE,YAAA;AAAJ","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.charts {\n  min-height: 100vh;\n  &-loading {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
