import L from 'leaflet';

// Create a custom DivIcon
export const supplierIcon = new L.DivIcon({
  className: 'custom-supplier-icon', // Custom class for CSS styling
  html: `
    <div style="
      width: 50px;
      height: 50px;
      background-color: #1A73E8;
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      border: 2px solid #FFFFFF;">
      I
    </div>
  `,
  iconSize: [50, 50], // Size of the icon
  iconAnchor: [25, 25], // Position relative to the marker point
});