// export const WMS_URL = "http://127.0.0.1:8023";
export const WMS_URL = "https://infoplus-api-fastapi-es4z.onrender.com/";
export const wmsRoutes = {
    location: '/location',
    locationFilter: '/location/search',
    warehouse: '/warehouse',
    warehouseFilter: '/warehouse/search',
    asn: '/asn',
    asnFilter: '/asn/search',
    lob: '/lineOfBusiness',
    lobFilter: '/lineOfBusiness/search',
    vendor: '/vendor',
    vendorFilter: '/vendor/search',
    item: '/item',
    itemFilter: '/item/search',
    order: '/order',
    orderFilter: '/order/search',
    user: '/user',
    customer: '/customer',
    zone: '/zone',
}