import "./style.scss";

const FullScreenLoading = () => {
  return (
    <div className="row">
      <div className="col-12 full-screen-loader d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border login-form-submit-loading" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoading;
