import axios from "axios";
import { apiRoutes } from "utils";
import { ISurvey, ISurveyResponse } from "../interfaces";

export const postSurvey = async ({ email, step1, step2 }: ISurvey) => {
  const { data } = await axios.post<ISurveyResponse>(apiRoutes.SURVEY, {
    email,
    step1,
    step2,
  });
  return data;
};
