import React, { CSSProperties } from "react";

interface Props {
  data: any;
}

const ReadOnlyDataViewer: React.FC<Props> = ({ data }) => {
  const renderData = (item: any, path: string[] = []): React.ReactNode => {
    if (Array.isArray(item)) {
      return (
        <div style={styles.nestedContainer}>
          <div style={styles.sectionHeader}>Array:</div>
          <table style={styles.table}>
            <tbody>
              {item.map((arrayItem, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{index}</td>
                  <td style={styles.tableCell}>
                    {renderData(arrayItem, [...path, String(index)])}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    if (typeof item === "object" && item !== null) {
      return (
        <div style={styles.nestedContainer}>
          <div style={styles.sectionHeader}>Object:</div>
          <table style={styles.table}>
            <tbody>
              {Object.entries(item).map(([key, value], index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{key}</td>
                  <td style={styles.tableCell}>
                    {renderData(value, [...path, key])}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    // For primitive values (string, number, boolean, etc.)
    return <span>{String(item)}</span>;
  };

  return <div style={styles.container}>{renderData(data)}</div>;
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    padding: "20px",
    backgroundColor: "#1e1e1e",
    color: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.5)",
  },
  nestedContainer: {
    marginLeft: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    margin: "10px 0",
    backgroundColor: "#333",
    border: "1px solid #444",
  },
  tableCell: {
    padding: "10px",
    borderBottom: "1px solid #444",
    color: "#f5f5f5",
  },
  sectionHeader: {
    fontWeight: "bold",
    marginBottom: "5px",
    color: "#00acc1",
  },
};

export default ReadOnlyDataViewer;
