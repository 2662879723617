// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  color: white;
  background-color: #151824;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer .footer-title {
  font-family: "FuturaBook";
  font-size: 14px;
  margin-left: 50px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .footer .footer-title {
    font-family: "FuturaBook";
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,yBCeW;EDdX,YAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;AADF;AAEE;EACE,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;;AAIA;EAEI;IACE,yBAAA;IACA,eAAA;EAFJ;AACF","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.footer {\n  color: white;\n  background-color: $mirageBlue;\n  height: 30px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  .footer-title {\n    font-family: \"FuturaBook\";\n    font-size: 14px;\n    margin-left: 50px;\n    margin-bottom: 0;\n  }\n}\n\n@media (max-width: 768px) {\n  .footer {\n    .footer-title {\n      font-family: \"FuturaBook\";\n      font-size: 20px;\n    }\n  }\n}\n","$budhaGold: #8b76b2;\n$sorbusOrange: #8481be;\n$lighterSorbusOrange: #8b76b2;\n$codGray: #191919;\n$mineShaftGray: #252525;\n$selectiveYellow: #8481be;\n$inchWormGreen: \"#7fec12\";\n$errorRed: #f27761;\n$codBlack: #101010;\n$codBlackV2: #171717;\n$tundoraGray: #484848;\n$silverGray: #a6a6a6;\n$pictonBlue: #a44c92;\n$scoprionGray: #5b5b5b;\n$limeade: #3c9c00;\n$sanFelixGreen: #1c6c09;\n$phoenixLinkBlue: #85a9ff;\n$separatorGray: #313749;\n$vulcanBlue: #0f111a;\n$mirageBlue: #151824;\n$textGray: #999999;\n$victoriaBlue: #465298;\n$flamingoRed: #f33f45;\n$thunderbirdRed: #c51a16;\n$green: #02752c;\n$lightGreen: #d5fae0;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
