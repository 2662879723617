import { useMutation, useQuery } from "react-query";
import { IContextUser } from "common/interfaces";
import { Footer, FullScreenLoading, PageTitle, SidebarMenu } from "components";
import { Icon, Map } from "leaflet";
import { getWarehouseOwnersRequest } from "pages/adminWarehouseOwners/api";
import SupplierDetails from "./subComponent/SupplierDetails";
import "leaflet-routing-machine";

import {
  IClosestWarehouses,
  IWarehouseOwners,
} from "pages/adminWarehouseOwners/interfaces";
import {
  getShipmentPlanRequest,
  updateShipmentPlanProductRequest,
} from "pages/shipmentPlan/api";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import {
  QUERY_KEYS,
  UserContext,
  ellipsis,
  getQueryParameterByName,
  pageRoutes,
} from "utils";
import warehouseImg from "../../assets/images/icons/leaflet-warehouse.png";
import { isEmpty, orderBy, set } from "lodash";
import { warehousesMapHeaders } from "utils/configs";
import "./styles.scss";
import { toast } from "react-toastify";

import L from "leaflet";
import "leaflet-routing-machine";
import useShipmentPlanStore from "utils/zustandStore/useShipmentPlanStore";
import { supplierIcon } from "./supplierIcon";
import WarehouseDetails from "./subComponent/WarehouseDetails";
import { ShipmentPlanDateSelectionContext } from "utils/context";

const WarehousesMap: React.FC = () => {
  const planId = getQueryParameterByName("id");
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const { updateData } = useShipmentPlanStore();
  const handleUpdateWarehouseState = (whData: any) => {
    updateData((prevData: any) => ({
      ...prevData,
      warehouse: whData,
    }));
  };
  const [shipmentPlan, setShipmentPlan] = useState<IShipmentPlan[]>([]);

  // flag for displaying shipment plan date selection pop-up
  const [shipmentPlanDateSelectionFlag, setShipmentPlanDateSelectionFlag] =
    useState(false);

  const [warehouseOwners, setWarehouseOwners] = useState<IWarehouseOwners[]>(
    []
  );

  const [selectedWarehouse, setSelectedWarehouse] = useState<
    IClosestWarehouses | undefined
  >(undefined);
  const [map, setMap] = useState<Map | null>(null);

  const popUpRef = useRef(null);

  const navigate = useNavigate();

  const {
    data: shipmentPlanData,
    error: shipmentPlanDataError,
    isLoading: isLoadingShipmentPlan,
  } = useQuery(
    QUERY_KEYS.GET_SHIPMENT_PLAN,
    () => getShipmentPlanRequest(email, planId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const {
    data: warehousesData,
    error: warehousesDataError,
    isLoading: isLoadingWarehouses,
  } = useQuery(
    QUERY_KEYS.GET_WAREHOUSE_OWNERS,

    () => getWarehouseOwnersRequest(email),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (shipmentPlanDataError || warehousesDataError) {
      navigate(`${pageRoutes.SHIPMENT_PLANS}`);
    }

    if (shipmentPlanData?.response) {
      setShipmentPlan(shipmentPlanData.response);
    }

    if (warehousesData?.response) {
      setWarehouseOwners(warehousesData.response);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipmentPlanDataError,
    warehousesData,
    warehousesDataError,
    shipmentPlanData,
  ]);

  const {
    mutate: updateShipmentProduct,
    isLoading: isLoadingUpdateShipmentPlanProduct,
  } = useMutation(updateShipmentPlanProductRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully updated shipment plan warehouse.");
      }
      navigate(`${pageRoutes.SHIPMENT_PLAN_ID}?id=${planId}`);
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your plan warehouse."
      );
    },
  });

  const shouldDisplayLoader =
    isLoadingShipmentPlan ||
    isLoadingWarehouses ||
    isLoadingUpdateShipmentPlanProduct;
  const shouldDisplayMap = !shouldDisplayLoader && !isEmpty(shipmentPlan);

  const acceptWarehouseOwner = () => {
    if (planId && selectedWarehouse) {
      const dataToUpdateShipmentPlan = {
        email,
        shipmentPlanId: planId,
        warehouseOwner: {
          wOwnerEmail: selectedWarehouse.email,
          _id: selectedWarehouse._id || "",
        },
      };
      handleUpdateWarehouseState(dataToUpdateShipmentPlan);
      updateShipmentProduct(dataToUpdateShipmentPlan);
      setShipmentPlanDateSelectionFlag(true);
    }
  };

  const removeMarkers = (map: Map, control: L.Routing.Control) => {
    const plan = control.getPlan() as unknown as { _markers: [] };
    const markers = plan._markers;

    // Check if markers exist and log each one
    if (markers && markers.length > 0) {
      markers.forEach((marker, index) => {
        // Example: Hide each marker by setting its icon's display style
        if (map.hasLayer(marker)) {
          map.removeLayer(marker);
        }
      });
    }
  };
  useEffect(() => {
    if (map && shipmentPlan.length && warehouseOwners.length) {
      const supplierLat =
        shipmentPlan[0].supplier.supplierAddress.lat;
      const supplierLong =
        shipmentPlan[0].supplier.supplierAddress.long;
      const sortedWarehousesCoordinates: IClosestWarehouses[] = orderBy(
        warehouseOwners.map((warehouse) => {
          const lat = Number(warehouse.warehouses[0].businessAddress.lat);
          const long = Number(warehouse.warehouses[0].businessAddress.long);
          const distance = Number(
            (
              map.distance([supplierLat, supplierLong], [lat, long]) *
              0.000621371192
            ).toFixed(2)
          );

          return {
            ...warehouse.warehouses[0].businessAddress,
            name: warehouse.warehouses[0].name,
            _id: warehouse.warehouses[0]._id,
            email: warehouse.email,
            distance,
          };
        }),
        ["distance"],
        ["asc"]
      ).slice(0, 1);
      const clostestWarehouse = sortedWarehousesCoordinates[0];
      const bounds = L.latLngBounds([
        L.latLng(supplierLat, supplierLong),
        L.latLng(clostestWarehouse.lat, clostestWarehouse.long),
      ]);
      const mapBoxAPI =
        "pk.eyJ1IjoiYW5keWh1IiwiYSI6ImNtM25ram0zMzAwYncya3B0MG0zMXhxdjEifQ.limmE59MZNcttKgW6MHphQ";

      const control = L.Routing.control({
        lineOptions: {
          styles: [{ color: "#1A73E8", opacity: 1, weight: 5 }],
          extendToWaypoints: true, // Optional, but required by the type
          missingRouteTolerance: 0.1,
        },
        waypoints: [
          L.latLng(supplierLat, supplierLong), // Start point
          L.latLng(clostestWarehouse.lat, clostestWarehouse.long), // Destination point
        ],
        router: L.Routing.mapbox(mapBoxAPI!, {
          profile: "mapbox/driving", // or 'walking', 'cycling'
        }),
      }).addTo(map);
      map.fitBounds(bounds, {
        padding: [10, 10], // Adds padding around the bounds to avoid markers being on the edge
      });
      removeMarkers(map, control);
      setSelectedWarehouse(clostestWarehouse);
    }
  }, [shipmentPlan, warehouseOwners, map]);

  return (
    <>
      <div className="content">
        {shouldDisplayLoader ? (
          <FullScreenLoading />
        ) : (
          <div className="container-fluid">
            <div className="sidebar">
              <SidebarMenu sidebarOnly={true} />
            </div>
            <PageTitle pageTitle="Warehouses Map" />

            <div className="wm-container d-flex flex-column">
              {shouldDisplayMap && (
                <div className="wm-map-container d-flex">
                  <MapContainer
                    className="wm-leaflet-map"
                    style={{ height: "100%", width: "100%" }}
                    center={[
                      shipmentPlan[0].supplier.supplierAddress.lat,
                      shipmentPlan[0].supplier.supplierAddress
                        .long,
                    ]}
                    zoom={10}
                    scrollWheelZoom
                    ref={setMap}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {!isEmpty(shipmentPlan[0].supplier) && (
                      <Marker
                        key={
                          shipmentPlan[0].supplier.supplierAddress
                            .lat
                        }
                        position={[
                          shipmentPlan[0].supplier.supplierAddress
                            .lat,
                          shipmentPlan[0].supplier.supplierAddress
                            .long,
                        ]}
                        icon={supplierIcon}
                      >
                        <Popup className="wm-popup" ref={popUpRef}>
                          <div className="row">
                            <div className="col-sm">
                              <div className="table-responsive">
                                <table
                                  className="table table-sm"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <thead className="sticky-header">
                                    <tr className="no-interaction">
                                      {warehousesMapHeaders.map((header) => (
                                        <th
                                          className="text-center"
                                          key={header.name}
                                        >
                                          {header.name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shipmentPlan[0].products.map((product) => (
                                      <tr
                                        key={`${product.asin}-products`}
                                        className="product-details"
                                      >
                                        <td className="align-middle text-center white-space-nowrap">
                                          <a
                                            href={`https://www.amazon.com/dp/${product.asin}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {product.asin}
                                          </a>
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          <img
                                            src={product.imageUrl}
                                            alt="product-thumb"
                                          />
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {ellipsis(product.title, 50)}
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {isEmpty(product.unitsPerBox)
                                            ? "-"
                                            : product.unitsPerBox}
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {product.boxWidth}x{product.boxHeight}
                                          x{product.boxLength}
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {product.amazonPrice}$
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {
                                            shipmentPlan[0].supplier
                                              .supplierName
                                          }
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    )}

                    {!isEmpty(selectedWarehouse) && (
                      <Marker
                        key={selectedWarehouse.lat}
                        position={[
                          selectedWarehouse.lat,
                          selectedWarehouse.long,
                        ]}
                        icon={
                          new Icon({
                            iconUrl: warehouseImg,
                            iconSize: [49, 49],
                            iconAnchor: [12, 41],
                          })
                        }
                      />
                    )}
                  </MapContainer>
                </div>
              )}

              <div className="wm-addresses-container d-flex flex-wrap justify-content-center">
                {!isEmpty(shipmentPlan) && shipmentPlan[0] && (
                  <SupplierDetails
                    supplier={shipmentPlan[0].supplier}
                  />
                )}

                <div>
                  <div className="wm-pointing-arrow">
                    {!isEmpty(selectedWarehouse) && (
                      <div className="distance">
                        {selectedWarehouse.distance} Miles
                      </div>
                    )}
                    <i className="wm-fa fa fa-arrow-right"></i>
                  </div>

                  <div className="wm-submit d-flex justify-content-center align-items-center">
                    {/*  */}
                    <ShipmentPlanDateSelectionContext.Provider
                      value={{
                        shipmentPlanDateSelectionFlag,
                        setShipmentPlanDateSelectionFlag,
                      }}
                    >
                      <button
                        className="redirect-button"
                        onClick={acceptWarehouseOwner}
                        // style={{ width: '200px' }}
                      >
                        Approve
                      </button>
                    </ShipmentPlanDateSelectionContext.Provider>
                  </div>
                </div>

                <div className="wm-pointing-arrow-mobile">
                  <i className="wm-fa fa fa-arrow-down"></i>
                </div>

                {!isEmpty(selectedWarehouse) && (
                  <WarehouseDetails selectedWarehouse={selectedWarehouse} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default WarehousesMap;
