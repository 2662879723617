import dayjs from "dayjs";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  QUERY_KEYS,
  UserContext,
  ellipsis,
  profitableProductsTableHeaders,
} from "utils";
import { IItemInFolder, IProfitableProductsList } from "./interfaces";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getSuppliersRequest } from "pages/suppliers/api";
import Loading from "components/Loading";
import AutocompleteDropdown from "components/AutocompleteDropdown";
import { ISupplier } from "pages/suppliers/interfaces";
import { IContextUser } from "common/interfaces";
import "./styles.scss";

const ProfitableProductsList = ({
  itemsInFolder,
  onDeleteItemClickCb,
  onSelectProductsForShipmentPlanClickCb,
  onSupplierChange,
}: IProfitableProductsList) => {
  const navigate = useNavigate();
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;

  const [suppliers, setSuppliers] = useState<any>([{ value: "", label: "" }]);
  const [originalSuppliers, setOriginalSuppliers] = useState<ISupplier[]>([]);

  const { data, isLoading: isLoadingGetSuppliers } = useQuery(
    QUERY_KEYS.GET_SUPPLIERS,
    () => getSuppliersRequest(email),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data?.response?.suppliers?.length) {
      setOriginalSuppliers(data?.response?.suppliers);
      const transformedSuppliers = data.response.suppliers.map((supplier) => ({
        value: supplier._id,
        label: supplier.supplierName,
      }));
      setSuppliers(transformedSuppliers);
    }
  }, [data]);

  // State for sorting
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string>("");

  const sortedItems = itemsInFolder?.sort((a, b) => {
    switch (sortField) {
      case "added":
        return sortOrder === "asc"
          ? dayjs(a.date).isBefore(dayjs(b.date))
            ? -1
            : 1
          : dayjs(b.date).isBefore(dayjs(a.date))
          ? -1
          : 1;

      case "asin":
        return sortOrder === "asc"
          ? a.asin.localeCompare(b.asin)
          : b.asin.localeCompare(a.asin);

      case "price":
        // Ensure price is treated as a number
        const priceA = Number(a.price);
        const priceB = Number(b.price);
        return sortOrder === "asc" ? priceA - priceB : priceB - priceA;

      case "hazmat":
        return sortOrder === "asc"
          ? (a.isHazmat ? 1 : 0) - (b.isHazmat ? 1 : 0)
          : (b.isHazmat ? 1 : 0) - (a.isHazmat ? 1 : 0);

      default:
        return 0;
    }
  });

  const goToDetailsPage = ({
    folderId,
    asin,
  }: {
    folderId: string;
    asin: string;
  }) =>
    navigate(`/profitable-products/details?folderId=${folderId}&asin=${asin}`);

  const onAutocompleteSelect = (
    option: any,
    productId: string,
    folderId: string,
    currentItem: IItemInFolder
  ) => {
    const selectedSupplier =
      option.value !== "no-supplier"
        ? originalSuppliers.find((supplier) => supplier._id === option.value)!
        : {
            supplierName: "",
            supplierLink: "",
            onlineSupplier: "",
            supplierAddress: {
              city: "",
              state: "",
              zipCode: "",
              street: "",
              lat: 0,
              long: 0,
            },
            contactPerson: {
              email: "",
              name: "",
              phoneNumber: "",
              extensionCode: "",
            },
            _id: "no-supplier",
          };

    onSupplierChange(selectedSupplier, folderId, productId, currentItem);
  };

  const renderBadge = (itemDate: string) => {
    const currentDate = dayjs();
    const itemDateObj = dayjs(itemDate);

    if (
      itemDateObj.isAfter(currentDate.subtract(3, "day"), "day") &&
      itemDateObj.isBefore(currentDate)
    ) {
      return <span className="badge badge-success"> &lt;3 days</span>;
    } else if (
      itemDateObj.isAfter(currentDate.subtract(7, "day"), "day") &&
      itemDateObj.isBefore(currentDate.subtract(3, "day"), "day")
    ) {
      return <span className="badge badge-warning">3 - 7 days</span>;
    } else {
      return <span className="badge badge-danger"> &gt; 7 days</span>;
    }
  };

  const renderProductRow = (item: IItemInFolder) => (
    <Fragment key={item._id}>
      <tr>
        <td
          className="align-middle text-center white-space-nowrap"
          style={{ width: "1%" }}
          rowSpan={2}
        >
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            onChange={() => onSelectProductsForShipmentPlanClickCb(item)}
            checked={item.isChecked || false}
          />
        </td>
      </tr>
      <tr>{renderProductColumns(item)}</tr>
    </Fragment>
  );

  const renderProductColumns = (item: IItemInFolder) => (
    <>
      <td
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
        className="align-middle text-center white-space-nowrap"
        style={{ width: "3%" }}
      >
        {item.date ? dayjs(item.date).format("MM/DD/YY") : ""}
      </td>

      <td
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
        className="align-middle text-center white-space-nowrap"
        style={{ width: "3%" }}
      >
        <p>{item.asin}</p>
      </td>

      <td
        className="align-middle text-center white-space-nowrap"
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
      >
        <img src={item.imageUrl} alt="product-thumb" />
      </td>

      <td
        className="align-middle"
        style={{ width: "20%" }}
        title={item.title}
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
      >
        {ellipsis(item.title, 100)}
      </td>

      <td
        className="align-middle text-center white-space-nowrap"
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
      >
        ${item.price}
      </td>

      <td
        className="align-middle text-center white-space-nowrap"
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
      >
        {item.isHazmat ? "YES" : "NO"}
      </td>

      <td
        className="align-middle text-center white-space-nowrap"
        onClick={() =>
          goToDetailsPage({ folderId: item.folderId, asin: item.asin })
        }
      >
        {renderBadge(item.date)}
      </td>
      <td className="align-middle text-center white-space-no-wrap">
        <a
          href={`https://www.amazon.com/dp/${item.asin}`}
          target="_blank"
          rel="noreferrer"
        >
          <span className="icon-wrapper me-3">
            <i className="fa fa-eye" aria-hidden="true"></i>
          </span>
        </a>

        <span
          className="icon-wrapper me-3"
          onClick={() =>
            onDeleteItemClickCb({
              folderId: item.folderId,
              folderItemId: item._id,
            })
          }
        >
          <i className="fa fa-trash-o text-danger" aria-hidden="true"></i>
        </span>
      </td>
      <td className="align-middle text-center white-space-no-wrap">
        {isLoadingGetSuppliers ? <Loading /> : renderSupplierDropdown(item)}
      </td>
    </>
  );

  const renderSupplierDropdown = (item: IItemInFolder) => (
    <div className="d-flex justify-content-center">
      <AutocompleteDropdown
        isDropdownDisabled={item.isChecked}
        options={suppliers}
        onSelectCb={(option: any) =>
          onAutocompleteSelect(option, item._id, item.folderId, item)
        }
        defaultOption={{
          label: item.supplier?.supplierName || "",
          value: item.supplier?._id || "",
        }}
      />
    </div>
  );

  return (
    <div className="table-responsive max-h-78vh profitable-products">
      <table className="table table-sm">
        <thead className="sticky-header">
          <tr>
            {profitableProductsTableHeaders.map((header) => (
              <th
                className="text-center"
                key={header.name}
                onClick={() => {
                  setSortField(header.name.toLowerCase());
                  setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
                }}
              >
                {header.name}
                {header.name === "TITLE" && (
                  <i
                    className={`fa ${
                      sortOrder === "asc" ? "fa-arrow-up" : "fa-arrow-down"
                    }`}
                    aria-hidden="true"
                  ></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedItems && sortedItems.length > 0
            ? sortedItems.map(renderProductRow)
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default ProfitableProductsList;
