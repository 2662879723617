import { IBrands } from "pages/adminBlacklist/interfaces";
import { useState } from "react";
import "./styles.scss";

const ViewBlacklist = ({
  brands,
  onDeleteBrandClick,
  saveEditedBrandValue,
}: any) => {
  const [clickedBrandName, setClickedBrandName] = useState({
    brandName: "",
    _id: "",
  });
  const [editedValue, setEditedValue] = useState("");

  const onEditClick = (brandName: string, _id: string) => {
    setClickedBrandName({ brandName, _id });
    setEditedValue(brandName);
  };

  const onSaveClick = (_id: string) => {
    saveEditedBrandValue({ _id, newValue: editedValue });
    setClickedBrandName({ brandName: "", _id: "" });
    setEditedValue("");
  };

  return (
    <div className="row">
      <div className="col-sm">
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Brand Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {brands.map((brand: IBrands, i: number) => (
                <tr key={brand.name}>
                  <td>{i + 1}</td>
                  <td>
                    <span
                      className={`${
                        clickedBrandName._id === brand._id ? "hide" : "show"
                      }`}
                    >
                      {brand.name}
                    </span>
                    <input
                      type="text"
                      className={`${
                        clickedBrandName._id === brand._id ? "show" : "hide"
                      }`}
                      value={editedValue}
                      onChange={(e) => setEditedValue(e.target.value)}
                    />
                  </td>

                  <td className="">
                    {clickedBrandName._id !== brand._id ? (
                      <button
                        className="btn btn-success width-100 me-3"
                        onClick={() => onEditClick(brand.name, brand._id)}
                      >
                        Edit
                      </button>
                    ) : (
                      <button
                        className="btn btn-success width-100 me-3"
                        onClick={() => onSaveClick(brand._id)}
                      >
                        Save
                      </button>
                    )}
                    <button
                      className="btn btn-danger px-3"
                      onClick={() => onDeleteBrandClick(brand.name)}
                    >
                      -
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewBlacklist;
