import { useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import "./style.scss";
import {
  ICostPerBoxItem,
  INewCostPerBoxItem,
  IWarehouseNumericInputs,
  IWarehouseOwnerFormProps,
  IWarehouseOwnersDynamicSchema,
} from "../../pages/adminWarehouseOwners/interfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./constants";

const WarehouseOwnersForm = ({
  onSubmitWOwnerCb,
  cancelCb,
}: IWarehouseOwnerFormProps) => {
  const [costPerBoxCollapsible, setCostPerBoxCollapsible] = useState<
    ICostPerBoxItem[]
  >([
    {
      id: 1,
    },
  ]);
  const [toggleShrinkWrap, setToggleShrinkWrap] = useState(false);
  const [toggleHazmatWrap, setToggleHazmatWrap] = useState(false);
  const [toggleBubbleWrapping, setToggleBubbleWrapping] = useState(false);
  const [toggleOfferStorage, setToggleOfferStorage] = useState(false);
  const [dynamicSchema, setDynamicSchema] =
    useState<IWarehouseOwnersDynamicSchema>(validationSchema);
  const [warehouseNumericInputs, setWarehouseNumericInputs] =
    useState<IWarehouseNumericInputs>({});

  const validationScheme = Yup.object().shape(dynamicSchema);

  const formOptions = {
    resolver: yupResolver(validationScheme),
    defaultValues: {
      decideSmallPrice: "",
      decideMediumPrice: "",
      decideLargePrice: "",
      decideHazmatPrice: "",
      decideBubbleWrappingPrice: "",
      decidePricePerPaletPrice: "",
      decidePricePerCubicFeetPrice: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const handleNumberInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    const result = e.target.value.replace(/\D/g, "");
    const resultPhoneNumber = e.target.value.replace(/[^0-9-]/g, "");
    const priceResult = e.target.value.replace(/[^0-9.,]/g, "");
    let updatedResult: IWarehouseNumericInputs = {};
    updatedResult = { ...warehouseNumericInputs };

    switch (e.target.id) {
      case "wo-lob-id":
        updatedResult["wo-lob-id"] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wo-warehouse-id":
        updatedResult["wo-warehouse-id"] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wo-vendor-id":
        updatedResult["wo-vendor-id"] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wo-phone-number":
        updatedResult["wo-phone-number"] = resultPhoneNumber;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-phone-number":
        updatedResult["wob-phone-number"] = resultPhoneNumber;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-zip-code":
        updatedResult["wob-zip-code"] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-cost-per-item-labeling":
        updatedResult["wob-cost-per-item-labeling"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-cost-per-box-closing":
        updatedResult["wob-cost-per-box-closing"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case `wob-cost-box-width${id}`:
        updatedResult[`wob-cost-box-width${id}`] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case `wob-cost-box-height${id}`:
        updatedResult[`wob-cost-box-height${id}`] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case `wob-cost-box-length${id}`:
        updatedResult[`wob-cost-box-length${id}`] = result;
        setWarehouseNumericInputs(updatedResult);
        break;

      case `wob-cost-box-price${id}`:
        updatedResult[`wob-cost-box-price${id}`] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-shrink-small":
        updatedResult["wob-shrink-small"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-shrink-medium":
        updatedResult["wob-shrink-medium"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-shrink-large":
        updatedResult["wob-shrink-large"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-hazmat":
        updatedResult["wob-hazmat"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-buble-wrapping":
        updatedResult["wob-buble-wrapping"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-storage-palet":
        updatedResult["wob-storage-palet"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      case "wob-storage-cubic":
        updatedResult["wob-storage-cubic"] = priceResult;
        setWarehouseNumericInputs(updatedResult);
        break;

      default:
        break;
    }
  };

  const handleAddCostPerBoxCollapsible = () => {
    const newCostPerBox: INewCostPerBoxItem = {};
    const collapsibleBoxLength = costPerBoxCollapsible.length + 1;

    newCostPerBox[`type${collapsibleBoxLength}`] = Yup.string();
    newCostPerBox[`name${collapsibleBoxLength}`] = Yup.string();
    newCostPerBox[`width${collapsibleBoxLength}`] = Yup.string();
    newCostPerBox[`height${collapsibleBoxLength}`] = Yup.string();
    newCostPerBox[`length${collapsibleBoxLength}`] = Yup.string();
    newCostPerBox[`price${collapsibleBoxLength}`] = Yup.string();

    setDynamicSchema((prevState) => ({ ...prevState, ...newCostPerBox }));

    setCostPerBoxCollapsible((prevState) => [
      ...prevState,
      {
        id: prevState.length + 1,
      },
    ]);
  };

  const handleRemoveCostPerBox = (id: number) => {
    setCostPerBoxCollapsible(
      costPerBoxCollapsible.filter((box) => box.id !== id)
    );
  };

  const onSubmit = (data: any) => {
    const {
      warehouseId,
      lobId,
      vendorId,
      businessPhoneNumber,
      name,
      email,
      phoneNumber,
      llc,
      businessName,
      password,
      customerEmailService,
      costPerItemLabeling,
      costPerBoxClosing,
      address,
      state,
      city,
      zipCode,
      decideShrinkWrap,
      decideSmallPrice,
      decideMediumPrice,
      decideLargePrice,
      decideHazmat,
      decideHazmatPrice,
      decideBubbleWrapping,
      decideBubbleWrappingPrice,
      decideOfferStorage,
      decidePricePerPalet,
      decidePricePerCubicFeet,
    } = data;

    const costPerBox = costPerBoxCollapsible.map(({ id }) => {
      const type = (
        document.getElementById(`wob-cost-box-type${id}`) as HTMLInputElement
      ).value;
      const name = (
        document.getElementById(`wob-cost-box-name${id}`) as HTMLInputElement
      ).value;
      const width = (
        document.getElementById(`wob-cost-box-width${id}`) as HTMLInputElement
      ).value;
      const length = (
        document.getElementById(`wob-cost-box-length${id}`) as HTMLInputElement
      ).value;
      const height = (
        document.getElementById(`wob-cost-box-height${id}`) as HTMLInputElement
      ).value;
      const price = (
        document.getElementById(`wob-cost-box-price${id}`) as HTMLInputElement
      ).value;

      const size = {
        width,
        length,
        height,
      };

      return {
        type,
        name,
        size,
        price,
      };
    });

    if (Object.keys(errors).length === 0) {
      const formData = {
        warehouseId,
        lobId,
        vendorId,
        name,
        email,
        phoneNumber,
        llcName: llc,
        businessName,
        businessPhoneNumber,
        password,
        customerServiceEmailAddress: customerEmailService,
        costPerItemLabeling,
        costPerBoxClosing,
        businessAddress: {
          address,
          state,
          city,
          zipCode,
        },
        costPerBox,
        handleShrink: {
          answer: decideShrinkWrap.toLowerCase(),
          small: { price: decideSmallPrice },
          medium: { price: decideMediumPrice },
          large: { price: decideLargePrice },
        },
        handleHazmat: {
          answer: decideHazmat.toLowerCase(),
          pricePerItem: decideHazmatPrice,
        },
        bubbleWrapping: {
          answer: decideBubbleWrapping.toLowerCase(),
          pricePerItem: decideBubbleWrappingPrice,
        },
        offerStorage: {
          answer: decideOfferStorage.toLowerCase(),
          pricePerPalet: decidePricePerPalet,
          pricePerCubicFeet: decidePricePerCubicFeet,
        },
      };

      onSubmitWOwnerCb(formData);
    }
  };

  return (
    <div className="container">
      <h1 className="text-center font-weight-bold mb-5">
        Warehouse Owners Sign Up
      </h1>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="nope" noValidate>
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="px-5 py-2">
            <h2>Warehouse details</h2>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.lobId?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-lob-id">Lob Id</label>
              <input
                value={warehouseNumericInputs["wo-lob-id"] || ""}
                type="text"
                id="wo-lob-id"
                placeholder="Enter lobId"
                autoComplete="none"
                {...register("lobId")}
                onChange={(e) => {
                  register("lobId").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.lobId?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.warehouseId?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-warehouse-id">Warehouse Id</label>
              <input
                value={warehouseNumericInputs["wo-warehouse-id"] || ""}
                type="text"
                id="wo-warehouse-id"
                placeholder="Enter warehouse id"
                autoComplete="none"
                {...register("warehouseId")}
                onChange={(e) => {
                  register("warehouseId").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.warehouseId?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.vendorId?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-vendor-id">Vendor Id</label>
              <input
                value={warehouseNumericInputs["wo-vendor-id"] || ""}
                type="text"
                id="wo-vendor-id"
                placeholder="Enter vendor id"
                autoComplete="none"
                {...register("vendorId")}
                onChange={(e) => {
                  register("vendorId").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.vendorId?.message}</span>
            </div>
          </div>

          <div className="px-5 py-2">
            <h2>Personal details</h2>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.name?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-name">Name</label>
              <input
                type="text"
                id="wo-name"
                placeholder="Enter name"
                autoComplete="none"
                {...register("name")}
              />
              <span>{errors.name?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.email?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-email">Email</label>
              <input
                id="wo-email"
                type="email"
                placeholder="Enter email"
                autoComplete="none"
                aria-describedby="email"
                {...register("email")}
              />
              <span>{errors.email?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.phoneNumber?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-phone-number">Phone number</label>
              <input
                id="wo-phone-number"
                value={warehouseNumericInputs["wo-phone-number"] || ""}
                placeholder="Enter phone number"
                autoComplete="none"
                maxLength={12}
                {...register("phoneNumber")}
                onChange={(e) => {
                  register("phoneNumber").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.phoneNumber?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.llc?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-llc-name">LLC name</label>
              <input
                id="wob-llc-name"
                placeholder="Enter LLC name"
                autoComplete="none"
                {...register("llc")}
              />
              <span>{errors.llc?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.password?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-password">Password</label>
              <input
                id="wo-password"
                placeholder="Enter password"
                type="password"
                {...register("password")}
              />
              <span>{errors.password?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.confirmPassword?.message && "form-error"
              }`}
            >
              <label htmlFor="wo-confirm-password">Confirm password</label>
              <input
                id="wo-confirm-password"
                placeholder="Confirm password"
                type="password"
                autoComplete="none"
                {...register("confirmPassword")}
              />
              <span>{errors.confirmPassword?.message}</span>
            </div>
          </div>

          <div className="px-5 py-2">
            <h2>Business details</h2>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.businessName?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-name">Name</label>
              <input
                id="wob-name"
                placeholder="Enter business name"
                autoComplete="none"
                {...register("businessName")}
              />
              <span>{errors.businessName?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.address?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-address">Address</label>
              <input
                id="wob-address"
                placeholder="Enter business address"
                autoComplete="none"
                {...register("address")}
              />
              <span>{errors.address?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.state?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-state">State</label>
              <input
                id="wob-state"
                placeholder="Enter business state"
                autoComplete="none"
                {...register("state")}
              />
              <span>{errors.state?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.city?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-city">City</label>
              <input
                id="wob-city"
                placeholder="Enter business city"
                autoComplete="none"
                {...register("city")}
              />
              <span>{errors.city?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.zipCode?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-zip-code">Zip code</label>
              <input
                id="wob-zip-code"
                placeholder="Enter zip code"
                value={warehouseNumericInputs["wob-zip-code"] || ""}
                autoComplete="none"
                {...register("zipCode")}
                onChange={(e) => {
                  register("zipCode").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.zipCode?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.businessPhoneNumber?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-phone-number">Phone number</label>
              <input
                id="wob-phone-number"
                placeholder="Enter business phone number"
                value={warehouseNumericInputs["wob-phone-number"] || ""}
                autoComplete="none"
                {...register("businessPhoneNumber")}
                onChange={(e) => {
                  register("businessPhoneNumber").onChange(e);
                  handleNumberInputChange(e);
                }}
                maxLength={12}
              />
              <span>{errors.businessPhoneNumber?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.customerEmailService?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-customer-service-email">
                Customer service email
              </label>
              <input
                id="wob-customer-service-email"
                placeholder="Enter customer service email"
                autoComplete="none"
                {...register("customerEmailService")}
              />
              <span>{errors.customerEmailService?.message}</span>
            </div>
          </div>

          <div className="px-5 py-2">
            <h2>Cost details</h2>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.costPerItemLabeling?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-cost-per-item-labeling">
                Cost per item labeling
              </label>
              <input
                id="wob-cost-per-item-labeling"
                placeholder="Enter cost per item labeling"
                value={
                  warehouseNumericInputs["wob-cost-per-item-labeling"] || ""
                }
                autoComplete="none"
                {...register("costPerItemLabeling")}
                onChange={(e) => {
                  register("costPerItemLabeling").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.costPerItemLabeling?.message}</span>
            </div>

            <div
              className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                errors.costPerBoxClosing?.message && "form-error"
              }`}
            >
              <label htmlFor="wob-cost-per-item-labeling">
                Cost per box closing
              </label>
              <input
                id="wob-cost-per-box-closing"
                placeholder="Enter cost per blox closing"
                value={warehouseNumericInputs["wob-cost-per-box-closing"] || ""}
                autoComplete="none"
                {...register("costPerBoxClosing")}
                onChange={(e) => {
                  register("costPerBoxClosing").onChange(e);
                  handleNumberInputChange(e);
                }}
              />
              <span>{errors.costPerBoxClosing?.message}</span>
            </div>

            <h5>Cost per box</h5>

            {!isEmpty(costPerBoxCollapsible) &&
              costPerBoxCollapsible.map((box: any) => {
                const { id } = box;

                return (
                  <div
                    id={`accordion-box-${id}`}
                    className="mt-2 mb-3"
                    key={`cost-per-box-${id}`}
                  >
                    <div className="card">
                      <div className="card-header" id={`heading-box-${id}`}>
                        <h5
                          className={`d-flex ${
                            id !== 1
                              ? "justify-content-between"
                              : "justify-content-flex-start"
                          } mb-0`}
                        >
                          <button
                            type="button"
                            className="btn btn-link"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-box-${id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-box-${id}`}
                          >
                            Box #{id}
                          </button>

                          {id !== 1 && (
                            <button
                              className="btn btn-dark-blue btn-danger"
                              type="button"
                              onClick={() => {
                                handleRemoveCostPerBox(id);
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </h5>
                      </div>

                      <div
                        id={`collapse-box-${id}`}
                        className={`collapse ${
                          id === costPerBoxCollapsible.length && "show"
                        }`}
                        aria-labelledby={`heading-box-${id}`}
                        data-parent={`accordion-box-${id}`}
                      >
                        <div className="card card-body">
                          <div
                            className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                              errors[`type${id}`]?.message && "form-error"
                            }`}
                          >
                            <label htmlFor="wob-cost-box-type">Type</label>
                            <input
                              id={`wob-cost-box-type${id}`}
                              placeholder="Enter type"
                              autoComplete="none"
                              {...register(`type${id}`)}
                            />
                          </div>

                          <div
                            className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                              errors[`name${id}`]?.message && "form-error"
                            }`}
                          >
                            <label htmlFor="wob-cost-box-name">Name</label>
                            <input
                              id={`wob-cost-box-name${id}`}
                              placeholder="Enter name"
                              autoComplete="none"
                              {...register(`name${id}`)}
                            />
                          </div>

                          <div
                            className={`wo-signup-container sizing-container  form-group dark-input  ${
                              errors[`width${id}`]?.message && "form-error"
                            }`}
                          >
                            <label htmlFor="wob-llc-name">Size</label>
                            <div className="wob-cost-per-box-sizing d-flex flex-row">
                              <div
                                className={`${
                                  errors[`width${id}`]?.message &&
                                  "d-flex flex-column form-error"
                                }`}
                              >
                                <input
                                  id={`wob-cost-box-width${id}`}
                                  value={
                                    warehouseNumericInputs[
                                      `wob-cost-box-width${id}`
                                    ] || ""
                                  }
                                  placeholder="W"
                                  autoComplete="none"
                                  {...register(`width${id}`)}
                                  onChange={(e) => {
                                    register(`width${id}`).onChange(e);
                                    handleNumberInputChange(e, id);
                                  }}
                                />
                              </div>

                              <div
                                className={`${
                                  errors[`length${id}`]?.message &&
                                  "d-flex flex-column form-error"
                                }`}
                              >
                                <input
                                  id={`wob-cost-box-length${id}`}
                                  placeholder="L"
                                  value={
                                    warehouseNumericInputs[
                                      `wob-cost-box-length${id}`
                                    ] || ""
                                  }
                                  autoComplete="none"
                                  {...register(`length${id}`)}
                                  onChange={(e) => {
                                    register(`length${id}`).onChange(e);
                                    handleNumberInputChange(e, id);
                                  }}
                                />
                              </div>

                              <div
                                className={`${
                                  errors[`height${id}`]?.message &&
                                  "d-flex flex-column form-error"
                                }`}
                              >
                                <input
                                  id={`wob-cost-box-height${id}`}
                                  placeholder="H"
                                  value={
                                    warehouseNumericInputs[
                                      `wob-cost-box-height${id}`
                                    ] || ""
                                  }
                                  autoComplete="none"
                                  {...register(`height${id}`)}
                                  onChange={(e) => {
                                    register(`height${id}`).onChange(e);
                                    handleNumberInputChange(e, id);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className={`wo-signup-container d-flex flex-column form-group dark-input  ${
                              errors[`price${id}`]?.message && "form-error"
                            }`}
                          >
                            <label htmlFor="wob-cost-box-price">Price</label>
                            <input
                              id={`wob-cost-box-price${id}`}
                              placeholder="Enter price"
                              autoComplete="none"
                              value={
                                warehouseNumericInputs[
                                  `wob-cost-box-price${id}`
                                ] || ""
                              }
                              {...register(`price${id}`)}
                              onChange={(e) => {
                                register(`price${id}`).onChange(e);
                                handleNumberInputChange(e, id);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            <button
              className="btn btn-link"
              type="button"
              onClick={handleAddCostPerBoxCollapsible}
            >
              + Add cost per box
            </button>

            <div className="mt-3">
              <h5>Do they handle shrink wrap?</h5>

              <div className="d-flex ">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="decideShrinkWrap"
                    value="Yes"
                    {...register("decideShrinkWrap")}
                    onClick={() => setToggleShrinkWrap(true)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="decideShrinkWrap"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check radio-container">
                  <input
                    defaultChecked
                    className="form-check-input"
                    type="radio"
                    id="decideShrinkWrap-no"
                    autoComplete="none"
                    value="No"
                    {...register("decideShrinkWrap")}
                    onClick={() => setToggleShrinkWrap(false)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="decideShrinkWrap"
                  >
                    No
                  </label>
                </div>
              </div>

              {toggleShrinkWrap && (
                <div className="wo-signup-container">
                  <div
                    className={`form-group d-flex flex-column form-group dark-input ${
                      errors.decideSmallPrice?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-shrink-small">Small</label>
                    <input
                      id="wob-shrink-small"
                      value={warehouseNumericInputs["wob-shrink-small"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decideSmallPrice")}
                      onChange={(e) => {
                        register("decideSmallPrice").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decideSmallPrice?.message}</span>
                  </div>

                  <div
                    className={`form-group mt-2 d-flex flex-column form-group dark-input ${
                      errors.decideMediumPrice?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-shrink-medium">Medium</label>
                    <input
                      id="wob-shrink-medium"
                      value={warehouseNumericInputs["wob-shrink-medium"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decideMediumPrice")}
                      onChange={(e) => {
                        register("decideMediumPrice").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decideMediumPrice?.message}</span>
                  </div>

                  <div
                    className={`form-group mt-2 d-flex flex-column form-group dark-input ${
                      errors.decideLargePrice?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-shrink-large">Large</label>
                    <input
                      id="wob-shrink-large"
                      value={warehouseNumericInputs["wob-shrink-large"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decideLargePrice")}
                      onChange={(e) => {
                        register("decideLargePrice").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decideLargePrice?.message}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              <h5>Do they handle hazmat products?</h5>

              <div className="d-flex ">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="Yes"
                    id="wob-yes-hazmat-wrap"
                    {...register("decideHazmat")}
                    onClick={() => setToggleHazmatWrap(true)}
                    autoComplete="none"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="wob-yes-hazmat-wrap"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check radio-container">
                  <input
                    defaultChecked
                    autoComplete="none"
                    className="form-check-input"
                    type="radio"
                    value="No"
                    {...register("decideHazmat")}
                    id="wob-no-hazmat-wrap"
                    onClick={() => setToggleHazmatWrap(false)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="wob-no-hazmat-wrap"
                  >
                    No
                  </label>
                </div>
              </div>

              {toggleHazmatWrap && (
                <div className="wo-signup-container">
                  <div
                    className={`form-group  d-flex flex-column form-group dark-input ${
                      errors.decideHazmatPrice?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-hazmat">Price</label>
                    <input
                      id="wob-hazmat"
                      value={warehouseNumericInputs["wob-hazmat"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decideHazmatPrice")}
                      onChange={(e) => {
                        register("decideHazmatPrice").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decideHazmatPrice?.message}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              <h5>Do they do bubble wrapping?</h5>

              <div className="d-flex ">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="wob-yes-bubble-wrap"
                    autoComplete="none"
                    value="Yes"
                    {...register("decideBubbleWrapping")}
                    onClick={() => setToggleBubbleWrapping(true)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="wob-yes-bubble-wrap"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check radio-container">
                  <input
                    defaultChecked
                    autoComplete="none"
                    className="form-check-input"
                    type="radio"
                    value="No"
                    {...register("decideBubbleWrapping")}
                    id="wob-no-bubble-wrap"
                    onClick={() => setToggleBubbleWrapping(false)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="wob-no-bubble-wrap"
                  >
                    No
                  </label>
                </div>
              </div>

              {toggleBubbleWrapping && (
                <div className="wo-signup-container">
                  <div
                    className={`form-group  d-flex flex-column form-group dark-input ${
                      errors.decideBubbleWrappingPrice?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-buble-wrapping">Price</label>
                    <input
                      id="wob-buble-wrapping"
                      value={warehouseNumericInputs["wob-buble-wrapping"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decideBubbleWrappingPrice")}
                      onChange={(e) => {
                        register("decideBubbleWrappingPrice").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decideBubbleWrappingPrice?.message}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-3">
              <h5>Do they offer storage?</h5>

              <div className="d-flex ">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="Yes"
                    id="wob-yes-storage"
                    autoComplete="none"
                    {...register("decideOfferStorage")}
                    onClick={() => setToggleOfferStorage(true)}
                  />
                  <label className="form-check-label" htmlFor="wob-yes-storage">
                    Yes
                  </label>
                </div>

                <div className="form-check radio-container">
                  <input
                    defaultChecked
                    className="form-check-input"
                    type="radio"
                    value="No"
                    id="wob-no-storage"
                    autoComplete="none"
                    {...register("decideOfferStorage")}
                    onClick={() => setToggleOfferStorage(false)}
                  />
                  <label className="form-check-label" htmlFor="wob-no-storage">
                    No
                  </label>
                </div>
              </div>

              {toggleOfferStorage && (
                <div className="wo-signup-container">
                  <div
                    className={`form-group  d-flex flex-column form-group dark-input ${
                      errors.decidePricePerPalet?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-storage-palet">Price per palet</label>
                    <input
                      id="wob-storage-palet"
                      value={warehouseNumericInputs["wob-storage-palet"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decidePricePerPalet")}
                      onChange={(e) => {
                        register("decidePricePerPalet").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decidePricePerPalet?.message}</span>
                  </div>

                  <div
                    className={`form-group mt-2  d-flex flex-column form-group dark-input ${
                      errors.decidePricePerCubicFeet?.message && "form-error"
                    }`}
                  >
                    <label htmlFor="wob-storage-cubic">
                      Price per cubic feet
                    </label>
                    <input
                      id="wob-storage-cubic"
                      value={warehouseNumericInputs["wob-storage-cubic"] || ""}
                      placeholder="Enter price"
                      autoComplete="none"
                      {...register("decidePricePerCubicFeet")}
                      onChange={(e) => {
                        register("decidePricePerCubicFeet").onChange(e);
                        handleNumberInputChange(e);
                      }}
                    />
                    <span>{errors.decidePricePerCubicFeet?.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center pb-3 wo-submit-buttons">
          <button type="submit" className="btn btn-success">
            Register
          </button>

          <button className="btn btn-danger" onClick={cancelCb}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default WarehouseOwnersForm;
