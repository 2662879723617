import axios from "axios";
import { WMS_URL } from "./routes";



const wmsClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL || WMS_URL,
  });


// export const wmsInterceptor = wmsClient.interceptors.request.use((config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   });
  

export default wmsClient