import { useState } from "react";

import "./styles.scss";
import { IShipmentPlanModalProps } from "./interface";
import { ellipsis } from "utils";

import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { uuidv4 } from "utils/globals";

const ShipmentPlanModalList: React.FC<IShipmentPlanModalProps> = ({
  shipmentPlanItems,
  handleDelete,
  shipmentPlanErrors,
  shipmentTitleRef,
  handleInputChange,
  orderNoRef,
  startDate,
  setStartDateCb,
}) => {
  const [orderNoBtn, setOrderNoBtn] = useState<string | null>(null);
  return (
    <div className="container-md">
      <div
        className={`d-flex flex-column  dark-input mb-3  ${
          shipmentPlanErrors.includes("shipment-title") && "form-error"
        }`}
      >
        <label htmlFor="csp-title">Shipment plan title</label>
        <input
          type="text"
          ref={shipmentTitleRef}
          id="csp-title"
          placeholder="Enter shipment title"
          autoComplete="none"
          onChange={() => handleInputChange(undefined, undefined, "")}
        />
        <span>
          {shipmentPlanErrors.includes("shipment-title") &&
            "Shipment plan title cannot be empty"}
        </span>
      </div>

      <div className="d-flex flex-column dark-input orderNo-container mb-3">
        <label htmlFor="orderNo">Order No</label>
        <input
          className="orderNo-input"
          type="text"
          placeholder="Order No"
          value={orderNoBtn || ""}
          ref={orderNoRef}
          onChange={(e) => setOrderNoBtn(e.target.value)}
        />
        <button
          className="orderNo-btn "
          type="button"
          onClick={() => setOrderNoBtn(uuidv4())}
        >
          Auto
        </button>
      </div>

      <div className="d-flex flex-column dark-input">
        <label htmlFor="order-date">Order date</label>
        <DatePicker
          selected={startDate}
          onChange={(date: Date | null) => setStartDateCb(date)}
          minDate={new Date()}
          placeholderText="Order date"
        />
      </div>

      <div className="mt-5">
        <h5>Selected products</h5>
        <div className="shipment-plan-table-container">
          <table className="table table-sm">
            <thead className="sticky-header">
              <tr>
                <th className="text-center">ASIN</th>
                <th className="text-center">IMG</th>
                <th className="text-center">TITLE</th>
                <th className="text-center">HAZMAT</th>
                <th className="text-center">SUPPLIER</th>
                <th className="text-center">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {shipmentPlanItems.length > 0 &&
                shipmentPlanItems.map((item, index) => (
                  <tr key={item.asin}>
                    <td className="align-middle text-center white-space-nowrap">
                      <a
                        className="text-details-asin"
                        href={`https://www.amazon.com/dp/${item.asin}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.asin}
                      </a>
                    </td>

                    <td className="align-middle text-center white-space-nowrap">
                      <img
                        src={item.imageUrl}
                        className="mb-1"
                        alt="product-thumb"
                      />
                    </td>
                    <td className="align-middle text-center white-space-nowrap">
                      {ellipsis(item.title, 40)}
                    </td>
                    <td className="align-middle text-center white-space-nowrap">
                      {item.isHazmat ? "YES" : "NO"}
                    </td>
                    <td className="align-middle text-center white-space-nowrap">
                      {item.supplier.supplierName}
                    </td>
                    <td className="align-middle text-center white-space-nowrap">
                      <span
                        className="icon-wrapper csp-delete-icon ms-1"
                        onClick={() => handleDelete(item._id)}
                      >
                        <i
                          className="fa fa-trash-o text-danger"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShipmentPlanModalList;
