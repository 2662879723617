import wmsClient from "../interceptor/interceptor";
import { wmsRoutes } from "../interceptor/routes";

export const getAsnByID = async (asnID: string) => {
    try {
        const url = `${wmsRoutes.asn}/${asnID}`;
        const response = await wmsClient.get(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching ASN by ID:", error);
        throw error;
    }
};

export const getAllAsns = async (limit: number) => {
    try {
        const url = `${wmsRoutes.asnFilter}?limit=${limit}`;
        const response = await wmsClient.get(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching ASNs:", error);
        throw error;
    }
};

export const createAsn = async (data: any) => {
    try {
        const url = `${wmsRoutes.asn}`;
        const response = await wmsClient.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error("Error creating ASN:", error);
        throw error;
    }
}

export const updateAsn = async (asnID: string, data: any) => {
    try {
        const url = `${wmsRoutes.asn}/${asnID}`;
        const response = await wmsClient.put(url, data);
        return response.data;
    }
    catch (error) {
        console.error("Error updating ASN:", error);
        throw error;
    }
}

export const deleteAsn = async (asnID: string) => {
    try {
        const url = `${wmsRoutes.asn}/${asnID}`;
        const response = await wmsClient.delete(url);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting ASN:", error);
        throw error;
    }
}