import { isEmpty } from "lodash";
import { IAdditionalShipmentInputs, IShipmentPlanFiles } from "./interfaces";

export const getAreFieldsInvalid = (
  additionalInputs: IAdditionalShipmentInputs,
  receiptNo: string,
  orderNoInput: string,
  fbaFiles: IShipmentPlanFiles[],
  titleInput: string,
  shouldDisplayReceiptNoError: boolean,
  shouldDisplayOrderNoError: boolean
) =>
  Object.entries(additionalInputs).some(([key, value]) => {
    if (key.startsWith("sp-comments-")) {
      return false;
    }
    if (typeof value === "object" && value!!.answer) {
      return isEmpty(value!!.amount);
    }
    return isEmpty(value);
  }) ||
  isEmpty(orderNoInput) ||
  isEmpty(receiptNo) ||
  isEmpty(fbaFiles) ||
  isEmpty(titleInput) ||
  shouldDisplayOrderNoError ||
  shouldDisplayReceiptNoError;
