// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-view-navbar-container {
  padding: 0 55px;
}
.admin-view-navbar-container .navbar-container {
  position: absolute;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/adminUsers/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,kBAAA;EACA,MAAA;AAEJ","sourcesContent":[".admin-view-navbar-container {\n  padding: 0 55px;\n  .navbar-container {\n    position: absolute;\n    top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
