import { useEffect, useState } from "react";
import { ISearchBar } from "./interfaces";
import { useDebounce } from "utils";

import "./styles.scss";

const SearchBar = ({ onSearchChangeCb }: ISearchBar) => {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onSearchChangeCb(debouncedSearchTerm);
  }, [onSearchChangeCb, debouncedSearchTerm]);

  return (
    <div className="searchbar search-input-group input-group dark-input">
      <input
        className="form-control"
        type="search"
        placeholder="Search..."
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        aria-label="Search"
        aria-describedby="search-input"
      />
      <div className="input-group-addon input-icon-wrapper">
        <i className="fa fa-sm fa-search"></i>
      </div>
    </div>
  );
};

export default SearchBar;
