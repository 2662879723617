import { useMutation, useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";

import {
  AddBlacklist,
  Footer,
  Loading,
  SidebarMenu,
  ViewBlacklist,
} from "components";
import { QUERY_KEYS, UserContext } from "utils";

import "./styles.scss";
import { IBlacklistAddFormType } from "components/AddBlacklist/interfaces";
import {
  deleteBlackListBrandRequest,
  getBlacklist,
  getSearchRequest,
  postBlacklistBrandsRequest,
  updateBlacklistBrandRequest,
} from "./api";
import {
  IBrands,
  IDeleteBlacklistBrandResponse,
  IEditBlacklist,
  IEditBlacklistBrandResponse,
  IPostBlacklistBrandsResponse,
} from "./interfaces";
import { toast } from "react-toastify";
import { users } from "utils/globals";
import Pagination from "react-responsive-pagination";
import { IContextUser } from "common/interfaces";

const AdminBlacklist = () => {
  //used 2 states for pages so I don't trigger twice the request to server
  //when the page changes because its tight to useeffect
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const itemsPerPage = 50;
  const [brands, setBrands] = useState<IBrands[] | any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hidePagination, setHidePagination] = useState(false);
  const {
    data,
    isLoading: isLoadingGetBlacklist,
    refetch: getBlacklistRetrigger,
  } = useQuery(
    [QUERY_KEYS.GET_BLACKLIST, page],
    () => getBlacklist({ page, itemsPerPage }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const {
    data: searchData,
    isLoading: isLoadingSearch,
    refetch: getSearchQuery,
  } = useQuery(
    [QUERY_KEYS.GET_SEARCH, searchTerm],
    () => getSearchRequest(searchTerm.replace(/\s+/g, " ").trim()),
    {
      refetchOnWindowFocus: false,
      enabled: false, // turned off by default, manual refetch is needed so I can call it on cick
      retry: false,
    }
  );

  let user: IContextUser = useContext(UserContext)!;

  useEffect(() => {
    if (user.email !== users.ADMIN_USER) {
      window.location.href = "/";
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      //@ts-ignore
      const currentPage = parseInt(data.page);
      setBrands(data.items);
      setMaxPages(data.maxPages);
      setPage(currentPage);
    }
  }, [data]);

  useEffect(() => {
    if (searchData) {
      setBrands(searchData.items);
    }
  }, [searchData]);

  useEffect(() => {
    const delayDebounceSearch = setTimeout(() => {
      if (searchTerm) {
        getSearchQuery();
        setHidePagination(true);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceSearch);
  }, [searchTerm, getSearchQuery]);

  const {
    mutate: postBlacklistBrands,
    isLoading: isLoadingPostBlacklistBrands,
  } = useMutation(postBlacklistBrandsRequest, {
    onSuccess: (data: IPostBlacklistBrandsResponse) => {
      if (data.status === "success") {
        setPage(1);
        toast.success("Successfully updated your brands list.");
      } else {
        toast.error("There was an error updating your blacklist brands.");
      }
      return;
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your blacklist brands."
      );
      return;
    },
  });

  const {
    mutate: deleteBlacklistBrand,
    isLoading: isLoadingDeleteBlacklistBrand,
  } = useMutation(deleteBlackListBrandRequest, {
    onSuccess: (data: IDeleteBlacklistBrandResponse) => {
      if (data.status === "success") {
        setPage(1);
        getBlacklistRetrigger();
        toast.success("Successfully deleted brand.");
      } else {
        toast.error("There was an error whilte trying to delete brand.");
      }
      return;
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error whilte trying to delete brand."
      );
      return;
    },
  });

  const {
    mutate: updateBlacklistBrand,
    isLoading: isLoadingUpdateBlacklistBrand,
  } = useMutation(updateBlacklistBrandRequest, {
    onSuccess: (data: IEditBlacklistBrandResponse) => {
      if (data.status === "success") {
        getBlacklistRetrigger();
        toast.success(`Successfully edited brand: ${data.editedBrand.name}`);
      } else {
        toast.error("There was an error whilte trying to update the brand.");
      }
      return;
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error whilte trying to update the brand."
      );
      return;
    },
  });

  const submitFromParent = (field: IBlacklistAddFormType) => {
    let brands = field.blacklistItem.split(",");
    brands = brands.map((el) => el.trim());
    postBlacklistBrands(brands);
  };

  const setCurrentPage = (currentPageFromChild: number) => {
    setPage(currentPageFromChild);
  };

  const onDeleteBrandClick = (brand: string) => {
    deleteBlacklistBrand(brand);
  };

  const saveEditedBrandValue = ({ _id, newValue }: IEditBlacklist) => {
    updateBlacklistBrand({ _id, newValue });
  };

  const onReloadClick = () => {
    setSearchTerm("");
    setBrands(data?.items);
    setHidePagination(false);
  };

  return (
    <>
      <div className="content">
        <div className="container admin-blacklist">
          <div className="sidebar">
            <SidebarMenu active="blacklist" sidebarOnly={true} />
          </div>
          <div className="navigation mb-md-5"></div>
          <div className="row mb-5">
            <AddBlacklist submitFromParent={submitFromParent} />
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <div className="search-input-group input-group mb-5 dark-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for a brand"
                  aria-label="Search for a brand"
                  aria-describedby="search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="input-group-addon input-icon-wrapper">
                  <i className="fa fa-sm fa-search"></i>
                </div>
                <div
                  className="input-group-addon input-icon-wrapper"
                  onClick={() => onReloadClick()}
                >
                  <i className="fa fa-sm fa-rotate-right"></i>
                </div>
              </div>
            </div>
          </div>
          {isLoadingGetBlacklist ||
          isLoadingPostBlacklistBrands ||
          isLoadingDeleteBlacklistBrand ||
          isLoadingUpdateBlacklistBrand ||
          isLoadingSearch ? (
            <Loading />
          ) : (
            <div className="pb-5">
              <ViewBlacklist
                brands={brands}
                onDeleteBrandClick={onDeleteBrandClick}
                saveEditedBrandValue={saveEditedBrandValue}
              />
              {!hidePagination && (
                <Pagination
                  current={page}
                  total={maxPages}
                  onPageChange={setCurrentPage}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminBlacklist;
