import {
  ICheckoutPost,
  ICheckoutResponse,
  IRegisterPost,
  IRegisterResponse,
} from "../interfaces";

import { apiRoutes } from "utils";
import axios from "axios";

export const postRegisterRequest = async (registerPostDetails: IRegisterPost): Promise<IRegisterResponse> => {
  try {
    const response = await axios.post<IRegisterResponse>(apiRoutes.REGISTER, registerPostDetails);
    return response.data;
  } catch (error) {
    console.error("Error during registration request:", error);
    throw error; // Rethrow the error for further handling by the caller
  }
};


export const postCheckoutRequest = async ({
  customerID,
  product,
}: ICheckoutPost) => {
  const { data } = await axios.post<ICheckoutResponse>(apiRoutes.CHECKOUT, {
    customerID,
    product,
  });
  return data;
};
