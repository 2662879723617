import axios from "axios";
import { apiRoutes } from "utils";
import {
  IGetProfile,
  IGetProfileResponse,
  IGoToBillingResponse,
  IUpdateProfilePost,
  IUpdateProfileResponse,
} from "../interfaces";

export const getProfileRequest = async ({ email, customerID }: IGetProfile) => {
  const { data } = await axios.get<IGetProfileResponse>(
    `${apiRoutes.PROFILE}?email=${encodeURIComponent(
      email
    )}&customerID=${customerID}`
  );
  return data;
};

export const postUpdateProfileRequest = async ({
  firstName,
  lastName,
  phoneNumber,
  email,
  password,
  notifications,
}: IUpdateProfilePost) => {
  const { data } = await axios.post<IUpdateProfileResponse>(
    apiRoutes.UPDATE_PROFILE,
    {
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      notifications,
    }
  );
  return data;
};

export const goToBilling = async (customerID: string) => {
  const { data } = await axios.post<IGoToBillingResponse>(apiRoutes.BILLING, {
    customer: customerID,
  });
  return data;
};
