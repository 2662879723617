// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thankyou-page-wrapper {
  min-height: 100vh;
  text-align: center;
  color: #8481be;
  background-color: #101010;
  padding-top: 50px;
}
.thankyou-page-wrapper h1 {
  font-size: 32px;
  font-weight: bold;
}
.thankyou-page-wrapper h2 {
  font-size: 28px;
}
.thankyou-page-wrapper .section .video-wrapper {
  min-height: 450px;
}

@media (max-width: 380px) {
  .thankyou-page-wrapper .container {
    width: 250px;
    height: 300px;
  }
}
@media (min-width: 381px) {
  .thankyou-page-wrapper .container {
    width: 350px;
    height: 300px;
  }
}
@media (min-width: 768px) {
  .thankyou-page-wrapper .container {
    width: 550px;
    height: 450px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ShipmentPlanThankYouPage/style.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,yBCES;EDDT,iBAAA;AADF;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;AAEE;EACE,eAAA;AAAJ;AAGI;EACE,iBAAA;AADN;;AAKA;EAEI;IACE,YAAA;IACA,aAAA;EAHJ;AACF;AAOA;EAEI;IACE,YAAA;IACA,aAAA;EANJ;AACF;AASA;EAEI;IACE,YAAA;IACA,aAAA;EARJ;AACF","sourcesContent":["@import \"../../assets/styles/colors.scss\";\n\n.thankyou-page-wrapper {\n  min-height: 100vh;\n  text-align: center;\n  color: $selectiveYellow;\n  background-color: $codBlack;\n  padding-top: 50px;\n  h1 {\n    font-size: 32px;\n    font-weight: bold;\n  }\n  h2 {\n    font-size: 28px;\n  }\n  .section {\n    .video-wrapper {\n      min-height: 450px;\n    }\n  }\n}\n@media (max-width: 380px) {\n  .thankyou-page-wrapper {\n    .container {\n      width: 250px;\n      height: 300px;\n    }\n  }\n}\n\n@media (min-width: 381px) {\n  .thankyou-page-wrapper {\n    .container {\n      width: 350px;\n      height: 300px;\n    }\n  }\n}\n@media (min-width: 768px) {\n  .thankyou-page-wrapper {\n    .container {\n      width: 550px;\n      height: 450px;\n    }\n  }\n}\n","$budhaGold: #8b76b2;\n$sorbusOrange: #8481be;\n$lighterSorbusOrange: #8b76b2;\n$codGray: #191919;\n$mineShaftGray: #252525;\n$selectiveYellow: #8481be;\n$inchWormGreen: \"#7fec12\";\n$errorRed: #f27761;\n$codBlack: #101010;\n$codBlackV2: #171717;\n$tundoraGray: #484848;\n$silverGray: #a6a6a6;\n$pictonBlue: #a44c92;\n$scoprionGray: #5b5b5b;\n$limeade: #3c9c00;\n$sanFelixGreen: #1c6c09;\n$phoenixLinkBlue: #85a9ff;\n$separatorGray: #313749;\n$vulcanBlue: #0f111a;\n$mirageBlue: #151824;\n$textGray: #999999;\n$victoriaBlue: #465298;\n$flamingoRed: #f33f45;\n$thunderbirdRed: #c51a16;\n$green: #02752c;\n$lightGreen: #d5fae0;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
