import { ISupplierForm } from "./interfaces";

export const defaultFormValues: ISupplierForm = {
  name: "",
  link: "",
  onlineSupplier: undefined,
  city: "",
  state: "",
  street: "",
  zipCode: "",
  contactName: "",
  contactEmail: "",
  contactPhoneNumber: "",
  contactExtensionCode: "",
};
