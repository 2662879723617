import axios from "axios";
import { apiRoutes } from "utils";
import {
  IPostSupplierResponse,
  IGetSuppliersResponse,
  IPostSupplierRequest,
  IDeleteSupplierResponse,
  IDeleteSupplierRequest,
} from "../interfaces";

export const getSuppliersRequest = async (email: string) => {
  const { data } = await axios.get<IGetSuppliersResponse>(
    `${apiRoutes.GET_SUPPLIERS}?email=${encodeURIComponent(email)}`
  );
  return data;
};

export const postRegisterSupplier = async ({
  email,
  name,
  onlineSupplier,
  link,
  city,
  street,
  state,
  zipCode,
  contactName,
  contactEmail,
  contactPhoneNumber,
  contactExtensionCode,
}: IPostSupplierRequest) => {
  const { data } = await axios.post<IPostSupplierResponse>(
    apiRoutes.POST_SUPPLIERS,
    {
      email,
      supplier: {
        supplierName: name,
        onlineSupplier,
        supplierAddress: {
          street,
          city,
          state,
          zipCode,
        },
        supplierLink: link,
        contactPerson: {
          name: contactName,
          email: contactEmail,
          phoneNumber: contactPhoneNumber,
          extensionCode: contactExtensionCode,
        },
      },
    }
  );
  return data;
};

export const deleteSuppliers = async ({
  email,
  _id,
}: IDeleteSupplierRequest) => {
  const { data } = await axios.delete<IDeleteSupplierResponse>(
    apiRoutes.DELETE_SUPPLIERS,
    {
      data: { email, _id },
    }
  );

  return data;
};
