import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import "./styles.scss";

const SingleValueLineChart = ({ singleValueChartData }: any) => {
  useLayoutEffect(() => {
    if (singleValueChartData.length) generateAndDrawLineChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleValueChartData]);

  const generateAndDrawLineChart = () => {
    let root = am5.Root.new("singleValueLineChart");
    root.setThemes([am5themes_Animated.new(root)]);

    let chart: any = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    chart.get("colors").set("step", 3);

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    function createAxisAndSeries(startValue: any, opposite: any) {
      let yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite,
      });
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer,
        })
      );

      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      }
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Sales Rank",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          legendValueText: "{valueY}",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}",
          }),
        })
      );
      //comment this if you want to remove the fillOpacity for line
      series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({ strokeWidth: 1 });

      yRenderer.grid.template.set("strokeOpacity", 0.05);
      yRenderer.labels.template.set("fill", series.get("fill"));
      yRenderer.setAll({
        stroke: series.get("fill"),
        strokeOpacity: 1,
        opacity: 1,
      });

      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      let data = singleValueChartData;
      series.data.setAll(data);
    }

    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // add scrollbar
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    //initiate series
    createAxisAndSeries(100, false);

    chart.appear(1000, 100);

    let legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {})
    );
    legend.labels.template.setAll({
      fontSize: 14,
      fontWeight: "300",
    });
    legend.valueLabels.template.setAll({
      fontSize: 14,
      fontWeight: "800",
    });
    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
    };
  };

  return (
    <div className="container-fluid charts text-center">
      <div className="row">
        <div className="col-12">
          <div
            id="singleValueLineChart"
            style={{ width: "100%", height: "250px" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SingleValueLineChart;
