import { SidebarMenu } from "components";

import React from "react";
import { Outlet } from "react-router-dom";

const InfoPlus: React.FC = () => {
  return (
    <div style={mainStyle as React.CSSProperties}>
      <SidebarMenu />
      <div className="d-flex flex-column  dark-input mb-3  ">
        <h1>InfoPlus</h1>
        <Outlet />
      </div>
    </div>
  );
};

export default InfoPlus;

const mainStyle = {
  backgroundColor: "black",

  minHeight: "100vh",

  width: "100vw",

  color: "white",

  display: "flex",

  flexDirection: "column",

  alignItems: "center",

  justifyContent: "center",
  padding: "60px",
};

// <div
//   style={{
//     backgroundColor: "black",
//     height: "100vh",
//     width: "100vw",
//     color: "white",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   }}
//   className="bg-black h-screen"
// >
